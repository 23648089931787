import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { DiagnosticContext } from '../helpers/functions';
import classNames from 'classnames';

const Tabs = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { state } = useContext(DiagnosticContext);

  const tabs = useMemo(() => {
    const disabled = location.pathname === '/choice' || location.pathname === '/information';
    const list = [
      { href: '/information', name: 'Funktionsweise' },
      { href: '/choice', name: 'Auswahl' },
    ];

    if (!location.pathname.includes('diagnostics')) {
      list.push({ href: '/promotion-test', name: 'Testauswahl', disabled });
    }

    if (!(!location.pathname.includes('diagnostics') && state.screenResultsEstimated)) {
      if (location.pathname.includes('diagnostics')) list.push({ href: '/diagnostics-results', name: 'Screeningergebnisse', disabled });
      else list.push({ href: '/test-results', name: 'Testergebnisse', disabled });
    }

    if (location.pathname.includes('diagnostics')) {
      list.push({ href: '/diagnostics-recomendations', name: 'Diagnostikempfehlungen', disabled });
    } else {
      list.push({ href: '/funding-recomendations', name: 'Förderempfehlung', disabled });
    }

    if (location.pathname.includes('diagnostics')) {
      list.push({ href: '/diagnostics-overview', name: 'Diagnostik Übersicht', disabled });
    } else {
      list.push({ href: '/funding-overview', name: 'Förderung Übersicht', disabled });
    }

    return list.map((item) => ({ ...item, current: location.pathname === item.href })).filter((item) => item.disabled !== true);
  }, [location.pathname, state.screenResultsEstimated]);

  const handleChange = (value) => {
    navigate(value);
  };

  return (
    <div className="bg-gray-100 px-4 py-6 sm:px-6 lg:px-8 overflow-y-auto whitespace-nowrap">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          value={tabs.find((tab) => tab.current).href}
          onChange={(evt) => handleChange(evt.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.href}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800',
                'rounded-md px-3 py-2 text-sm font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;

const HeaderLogo = () => {
  const SHOW_HEADER = !window.location.search.includes('hide_header') || process.env.REACT_APP_SHOW_HEADER === '1';

  if (!SHOW_HEADER) return null;
  return (
    <div className="flex w-full items-center p-5">
      <a href="https://londi.de/" target="_blank" rel="noreferrer">
        <img className="w-36 h-24 mx-2 lg:mx-5" alt="graphic" src="/images/LogoMakeOverFinal.png" />
      </a>
    </div>
  );
};
export default HeaderLogo;

import { useMemo, useState } from 'react';

const MAX_ITEMS_AFTER_LIST_COLLAPSE = Number(process.env.REACT_APP_MAX_ITEMS_AFTER_LIST_COLLAPSE) || 3;

function CollapsibleList({ tests, renderItem }) {
  const [showAll, setShowAll] = useState(false);
  const isCollapsable = tests.length > MAX_ITEMS_AFTER_LIST_COLLAPSE;

  const collapsedCount = useMemo(() => {
    return tests.length - MAX_ITEMS_AFTER_LIST_COLLAPSE;
  }, [tests.length]);

  const handleToggleVisibleCount = () => setShowAll((p) => !p);

  const visibleRows = useMemo(() => {
    const clone = tests.slice();
    if (!showAll) clone.splice(MAX_ITEMS_AFTER_LIST_COLLAPSE);
    return clone;
  }, [tests, showAll]);

  return (
    <>
      {visibleRows.map(renderItem)}
      {isCollapsable && (
        <div className="flex items-center justify-center py-8">
          <button className="inline-flex items-center text-white bg-mainBlue py-5 px-10 text-xl capitalize" onClick={handleToggleVisibleCount}>
            {showAll ? 'weniger' : `mehr (${collapsedCount})`}
          </button>
        </div>
      )}
    </>
  );
}

export default CollapsibleList;

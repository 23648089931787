import { useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { feedbackGrades, questionnaireOptions } from '../helpers/constants';
import { questionnaireState } from '../helpers/state';
import { questionnaireReducer } from '../reducers/questionnaire';

const endpoint = process.env.REACT_APP_LONDI_BACKEND_URL || 'https://backend.hilfssystem.londi.de/londi';
//https://sf2ct2zgka.execute-api.eu-central-1.amazonaws.com/prod';

const Questionnaire = () => {
  const [state, dispatch] = useReducer(questionnaireReducer, questionnaireState);
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const saveData = () => {
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'londi-api-key': 'aFk5BTKHBXSkdIGPNlxzmVVcliLtgmg00xLCUZzHkAQwScBKz3',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        feedbacks: state,
      }),
    })
      .then((res) => res.json())
      .then((result) => console.log(result))
      .catch((err) => console.log(err));
  };
  const onSubmit = () => {
    saveData();
    navigate(location.pathname === '/diagnostics-recomendations' ? '/diagnostics-overview' : '/funding-overview');
  };
  useEffect(() => {
    const data = sessionStorage.getItem('questionnaire');
    if (data) {
      dispatch(JSON.parse(data));
    }
  }, [dispatch]);

  // The options for the new question
  const occupationOptions = ['Lehrer*in', 'Schulpsycholog*in', 'Lerntherapeut*in', 'Kein Beruf / Arbeitslos', 'Anderer'];

  useEffect(() => {
    sessionStorage.setItem('questionnaire', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'auto');
  }, []);

  return (
    <div className="fixed inset-0 m-auto lg:py-20 flex z-50 overflow-auto w-full h-full">
      <div
        className={`border-2 ${Object.keys(errors).length !== 0 && 'h-90'} m-auto h-full lg:h-90 w-full md:w-11/12 lg:w-8/12 outline-none 
            overflow-x-hidden overflow-y-auto bg-white font-montserrat border-mainBlue p-2`}
      >
        <div className="flex justify-end">
          <a href={`${location.pathname === '/diagnostics-recomendations' ? '/diagnostics-overview' : '/funding-overview'}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-7 stroke-2 h-8 text-mainBlue"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </a>
        </div>
        <div className="">
          <p className="text-sm md:text-base font-semibold mx-2  md:mx-2">
            Bevor Ihnen die Übersicht angezeigt wird, wäre es großartig, wenn Sie sich zwei Minuten Zeit nehmen könnten, um ein paar Fragen zu
            beantworten. Sie tragen so zur Optimierung dieses LONDI-Hilfssystems bei. Wenn sie den Fragebogen nicht ausfüllen möchten, können Sie
            dieses Fenster rechts oben schließen.
          </p>
        </div>
        <form className="flex mt-2 flex-col items-center" onSubmit={handleSubmit(onSubmit)}>
          <p className=" text-start w-full">Ich finde das LONDI-Hilfssystem:</p>
          <div className="flex flex-col w-full items-center">
            <div className="radio-gray-container">
              <p className="text-base sticky left-8 mb-5 sm:mb-0 sm:static w-32">behindernd</p>
              <div className="radio-container">
                {feedbackGrades.map((grade, index) => {
                  return (
                    <input
                      {...register('helping', { required: true })}
                      key={index}
                      onClick={() => dispatch({ type: 'helping', payload: grade })}
                      name="default1-radio"
                      type="radio"
                      className="radio-gray"
                    />
                  );
                })}
              </div>
              <p className="text-base sticky right-8 mb-5 sm:mb-0 sm:static pl-4 w-32">unterstützend</p>
            </div>
            {errors.helping && <p className="text-red-500">Feld erforderlich</p>}
            <div className="radio-white-container">
              <p className="text-base sticky left-8 mb-5 sm:mb-0 sm:static w-32">kompliziert</p>
              <div className="radio-container">
                {feedbackGrades.map((grade, index) => {
                  return (
                    <input
                      {...register('complication', { required: true })}
                      key={index}
                      onClick={() => dispatch({ type: 'complication', payload: grade })}
                      type="radio"
                      id="default-radio-1"
                      value=""
                      name="default2-radio"
                      className="radio-white"
                    />
                  );
                })}
              </div>
              <p className="text-base sticky right-8 mb-5 sm:mb-0 sm:static pl-4 w-32">einfach</p>
            </div>
            {errors.complication && <p className="text-red-500">Feld erforderlich</p>}
            <div className="radio-gray-container">
              <p className="text-base sticky left-8 mb-5 sm:mb-0 sm:static w-32">ineffizient</p>
              <div className="radio-container">
                {feedbackGrades.map((grade, index) => {
                  return (
                    <input
                      {...register('efficiency', { required: true })}
                      key={index}
                      onClick={() => dispatch({ type: 'efficiency', payload: grade })}
                      type="radio"
                      id="default-radio-8"
                      value=""
                      name="default3-radio"
                      className="radio-gray"
                    />
                  );
                })}
              </div>
              <p className="text-base sticky right-8 mb-5 sm:mb-0 sm:static pl-4 w-32">effizient</p>
            </div>
            {errors.efficiency && <p className="text-red-500">Feld erforderlich</p>}
            <div className="radio-white-container">
              <p className="text-base sticky left-8 mb-5 sm:mb-0 sm:static w-32">verwirrend</p>
              <div className="radio-container">
                {feedbackGrades.map((grade, index) => {
                  return (
                    <input
                      {...register('simplicity', { required: true })}
                      key={index}
                      onClick={() => dispatch({ type: 'simplicity', payload: grade })}
                      type="radio"
                      name="default4-radio"
                      className="radio-white"
                    />
                  );
                })}
              </div>
              <p className="text-base sticky right-8 mb-5 sm:mb-0 sm:static pl-4 w-32">übersichtlich</p>
            </div>
            {errors.simplicity && <p className="text-red-500">Feld erforderlich</p>}
            <div className="radio-gray-container">
              <p className="text-base sticky left-8 mb-5 sm:mb-0 sm:static w-32">langweilig</p>
              <div className="radio-container">
                {feedbackGrades.map((grade, index) => {
                  return (
                    <input
                      {...register('exciting', { required: true })}
                      key={index}
                      onClick={() => dispatch({ type: 'exciting', payload: grade })}
                      type="radio"
                      name="default5-radio"
                      className="radio-gray"
                    />
                  );
                })}
              </div>
              <p className="text-base sticky right-8 mb-5 sm:mb-0 sm:static pl-4 w-32">spannend</p>
            </div>
            {errors.exciting && <p className="text-red-500">Feld erforderlich</p>}

            <div className="radio-white-container">
              <p className="text-base sticky left-8 mb-5 sm:mb-0 sm:static w-32">uninteressant</p>
              <div className="radio-container">
                {feedbackGrades.map((grade, index) => {
                  return (
                    <input
                      {...register('interest', { required: true })}
                      key={index}
                      onClick={() => dispatch({ type: 'interest', payload: grade })}
                      type="radio"
                      name="default6-radio"
                      className="radio-white"
                    />
                  );
                })}
              </div>
              <p className="text-base sticky right-8 mb-5 sm:mb-0 sm:static pl-4 w-32">interessant</p>
            </div>
            {errors.interest && <p className="text-red-500">Feld erforderlich</p>}

            <div className="radio-gray-container">
              <p className="text-base sticky left-8 mb-5 sm:mb-0 sm:static w-32">konventionell</p>
              <div className="radio-container">
                {feedbackGrades.map((grade, index) => {
                  return (
                    <input
                      {...register('originality', { required: true })}
                      key={index}
                      onClick={() => dispatch({ type: 'originality', payload: grade })}
                      type="radio"
                      name="default7-radio"
                      className="radio-gray"
                    />
                  );
                })}
              </div>
              <p className="text-base sticky right-8 mb-5 sm:mb-0 sm:static pl-4 w-32">originell</p>
            </div>
            {errors.originality && <p className="text-red-500">Feld erforderlich</p>}

            <div className="radio-white-container">
              <p className="text-base sticky left-8 mb-5 sm:mb-0 sm:static w-32">herkömmlich</p>
              <div className="radio-container">
                {feedbackGrades.map((grade, index) => {
                  return (
                    <input
                      {...register('novelty', { required: true })}
                      key={index}
                      onClick={() => dispatch({ type: 'novelty', payload: grade })}
                      type="radio"
                      name="default8-radio"
                      className="radio-white"
                    />
                  );
                })}
              </div>
              <p className="text-base pl-4 sticky right-8 mb-5 sm:mb-0 sm:static w-32">neuartig</p>
            </div>
            {errors.novelty && <p className="text-red-500">Feld erforderlich</p>}

            <div className="flex flex-col mt-3 space-y-1 w-11/12 md:w-1/2">
              <p className="text-lg w-11/12 md:w-1/2 mt-4">Welchen Beruf üben Sie aus?</p>
              {occupationOptions.map((option, index) => {
                return (
                  <div key={index} className="flex items-center">
                    <input
                      {...register('occupation', { required: true })}
                      onClick={() => dispatch({ type: 'occupation', payload: option })}
                      id={`occupation-radio-${index}`}
                      value=""
                      name="occupation-radio"
                      type="radio"
                      className="radio-white"
                    />
                    <label htmlFor={`occupation-radio-${index}`} className="rate-options">
                      {option}
                    </label>
                  </div>
                );
              })}
            </div>
            {errors.occupation && <p className="text-red-500">Feld erforderlich</p>}
          </div>
          <p className="text-lg w-11/12 md:w-1/2 mt-4">Ich habe vor, LONDI weiterhin zu nutzen:</p>
          <div className="flex flex-col mt-3 space-y-1 w-11/12 md:w-1/2">
            {questionnaireOptions.map((option, index) => {
              return (
                <div key={index} className="flex items-center">
                  <input
                    {...register('usability', { required: true })}
                    onClick={() => dispatch({ type: 'usability', payload: index + 1 })}
                    id="default-radio-1"
                    value=""
                    name="default-radio"
                    type="radio"
                    className="radio-white"
                  />
                  <label htmlFor="default-radio-1" className="rate-options">
                    {option}
                  </label>
                </div>
              );
            })}
          </div>
          {errors.usability && <p className="text-red-500">Feld erforderlich</p>}
          <button className="text-white text-center font-semibold bg-mainBlue w-32 h-12  text-base  mt-2">Absenden</button>
        </form>
      </div>
    </div>
  );
};

export default Questionnaire;

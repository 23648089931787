import Modal from './Modal';
import DescriptionListItem from './DescriptionListItem';
import { Badge } from './Badge';
import { CheckList, CheckListItem } from './CheckList';
import PieChart from './PieChart';
import { useMemo } from 'react';
import { value } from '../helpers/constants';

function toNumberValue(str) {
  return Number(str.replace(',', '.'));
}

function FundingRecommendationDetailView({ item, open, setOpen }) {
  const chartData = useMemo(() => {
    let data, colors;

    if (item.testItem['BZ'] === 'ja' && item.testItem['CA'] === 'ja') {
      data = [
        { name: 'Basiskompetenzen Lesen/Schreiben', value: item.testItem['DK'] },
        { name: 'Erstes Lesen/Schreiben', value: item.testItem['DL'] },
        { name: 'Lesen', value: item.testItem['DM'] },
        { name: 'Schreiben', value: item.testItem['DN'] },
        { name: 'Angewandtes und flexibles Lesen', value: item.testItem['DO'] },
        { name: 'Angewandtes und flexibles Schreiben', value: item.testItem['DP'] },
        { name: 'Sonstige', value: item.testItem['DU'] },
      ];

      colors = ['#ef4444', '#22c55e', '#facc15', '#f97316', '#0ea5e9', '#1d4ed8', '#9ca3af'];
    } else if (item.testItem['BZ'] === 'ja') {
      data = [
        { name: 'Basiskompetenzen Lesen', value: item.testItem['DK'] },
        { name: 'Erstes Lesen', value: item.testItem['DL'] },
        { name: 'Lesen', value: item.testItem['DM'] },
        { name: 'Angewandtes und flexibles Lesen', value: item.testItem['DO'] },
        { name: 'Sonstige', value: item.testItem['DU'] },
      ];
      colors = ['#ef4444', '#22c55e', '#facc15', '#0ea5e9', '#9ca3af'];
    } else if (item.testItem['CA'] === 'ja') {
      data = [
        { name: 'Basiskompetenzen Schreiben', value: item.testItem['DK'] },
        { name: 'Erstes Schreiben', value: item.testItem['DL'] },
        { name: 'Schreiben', value: item.testItem['DN'] },
        { name: 'Angewandtes und flexibles Schreiben', value: item.testItem['DP'] },
        { name: 'Sonstige', value: item.testItem['DU'] },
      ];
      colors = ['#ef4444', '#22c55e', '#f97316', '#1d4ed8', '#9ca3af'];
    } else if (item.testItem['CB'] === 'ja') {
      data = [
        { name: 'Basiskompetenzen Rechnen', value: item.testItem['DQ'] },
        { name: 'Erstes Rechnen', value: item.testItem['DR'] },
        { name: 'Rechnen', value: item.testItem['DS'] },
        { name: 'Angewandtes und flexibles Rechnen', value: item.testItem['DT'] },
        { name: 'Sonstige', value: item.testItem['DU'] },
      ];
      colors = ['#ef4444', '#22c55e', '#f97316', '#1d4ed8', '#9ca3af'];
    }
    data = data.map((item) => ({ ...item, value: toNumberValue(item.value) }));

    data.forEach((item, index) => {
      if (!(item.value > 0)) {
        data.splice(index, 1, null);
        colors.splice(index, 1, null);
      }
    });

    return { data: data.filter(Boolean), colors: colors.filter(Boolean) };
  }, [item.testItem]);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="px-4 py-6 sm:px-6">
        <h3 className="text-base font-semibold leading-7 text-gray-900">{item.title}</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{item.subtitle}</p>
      </div>
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <DescriptionListItem term="Förderprofil - Kompetenzstufen" definition={<PieChart data={chartData.data} colors={chartData.colors} />} />
          <DescriptionListItem
            term="Kann durchgeführt werden durch"
            definition={
              <CheckList>
                <CheckListItem name="BB" label="Fachpersonal" checked={item.testItem['BB'] === 'ja'} />
                <CheckListItem name="BA" label="Eltern" checked={item.testItem['BA'] === 'ja'} />
                <CheckListItem name="AZ" label="Braucht keine Betreuung" checked={item.testItem['AZ'] === 'ja'} />
              </CheckList>
            }
          />
          <DescriptionListItem
            term="Förderschwerpunkt"
            definition={
              <CheckList>
                <CheckListItem name="BZ" label="Lesen" checked={item.testItem['BZ'] === 'ja'} />
                <CheckListItem name="CA" label="Schreiben" checked={item.testItem['CA'] === 'ja'} />
                <CheckListItem name="CB" label="Rechnen" checked={item.testItem['CB'] === 'ja'} />
              </CheckList>
            }
          />
          {item.testItem['AE'] !== 'nein' && (
            <DescriptionListItem
              term="Digitales Medium"
              definition={
                <CheckList>
                  <CheckListItem name="AI" label="Smartphone" checked={item.testItem['AI'] === 'ja'} />
                  <CheckListItem name="AJ" label="Tablet" checked={item.testItem['AJ'] === 'ja'} />
                  <CheckListItem name="AG" label="PC" checked={item.testItem['AG'] === 'ja'} />
                  <CheckListItem name="AH" label="Browserbasiert" checked={item.testItem['AH'] === 'ja'} />
                </CheckList>
              }
            />
          )}
          <DescriptionListItem
            term={`${item.testItem['G']} beinhaltet`}
            definition={
              <CheckList>
                <CheckListItem name="AE" label="Digitale Elemente" checked={item.testItem['AE'] === 'ja'} />
                <CheckListItem name="BI" label="Einstufungstest" checked={item.testItem['BI'] === 'ja'} />
                <CheckListItem name="BJ" label="Lernverlaufsdiagnostik" checked={item.testItem['BJ'] === 'ja'} />
                <CheckListItem name="BQ" label="Token/ Belohnungssystem" checked={item.testItem['BQ'] === 'ja'} />
              </CheckList>
            }
          />
          <DescriptionListItem
            term="Laut Herausgeber für folgenden Schulbereich geeignet"
            definition={
              <CheckList>
                <CheckListItem name="CN" label="Vorschule" checked={item.testItem['CN'] === 'ja'} />
                <CheckListItem name="CO" label="Grundschule" checked={item.testItem['CO'] === 'ja'} />
                <CheckListItem name="CP" label="Weiterführende Schule" checked={item.testItem['CP'] === 'ja'} />
              </CheckList>
            }
          />
          <DescriptionListItem
            term="Setting"
            definition={
              <CheckList>
                <CheckListItem name="BE" label="Einzelsetting" checked={item.testItem['BE'] === 'ja'} />
                <CheckListItem name="BD | BF" label="Gruppensetting" checked={item.testItem['BD'] === 'ja' || item.testItem['BF'] === 'ja'} />
              </CheckList>
            }
          />

          <DescriptionListItem
            term="Schwierigkeit"
            definition={
              <div className="flex gap-x-3 gap-y-2">
                {item.testItem['BN'] === 'ja' && <Badge name="BN" label="Vorgegeben" />}
                {item.testItem['BO'] === 'ja' && <Badge name="BO" label="Auswählbar" />}
                {item.testItem['BP'] === 'ja' && <Badge name="BP" label="Adaptiv" />}
              </div>
            }
          />
          <DescriptionListItem
            term="Dauer einer Fördereinheit in Minuten (laut Herausgeber)"
            definition={item.testItem['BT'] === 'NA' ? 'nicht angegeben' : item.testItem['BT'] || 'nicht angegeben'}
          />
          <DescriptionListItem
            term="Empfohlene Anzahl von Fördereinheiten pro Woche (laut Herausgeber)"
            definition={item.testItem['BU'] === 'NA' ? 'nicht angegeben' : item.testItem['BU'] || 'nicht angegeben'}
          />

          <DescriptionListItem
            term="Link"
            definition={
              <a href={item.link} target="_blank" rel="noreferrer" className="text-blue-600 hover:text-blue-500 visited:text-blue-700">
                {item.link}
              </a>
            }
          />
          <DescriptionListItem
            term="Autor:innen"
            definition={
              <ul>
                {item.authors.map((author) => (
                  <li key={author}>{author}</li>
                ))}
              </ul>
            }
          />
          <DescriptionListItem term="Publikationsjahr/ Verlag" definition={`${item.year} / ${item.publisher}`} />
        </dl>
      </div>
    </Modal>
  );
}

export default FundingRecommendationDetailView;

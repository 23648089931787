import { questionnaireState } from '../helpers/state';

export const questionnaireReducer = (state = questionnaireState, action) => {
  switch (action.type) {
    case 'helping':
      return { ...state, helping: action.payload };
    case 'complication':
      return { ...state, complication: action.payload };
    case 'efficiency':
      return { ...state, efficiency: action.payload };
    case 'simplicity':
      return { ...state, simplicity: action.payload };
    case 'exciting':
      return { ...state, exciting: action.payload };
    case 'interest':
      return { ...state, interest: action.payload };
    case 'originality':
      return { ...state, originality: action.payload };
    case 'novelty':
      return { ...state, novelty: action.payload };
    case 'usability':
      return { ...state, usability: action.payload };
    case 'occupation':
      return { ...state, occupation: action.payload };
    default:
      return state;
  }
};

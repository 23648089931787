import { initializer } from '../helpers/functions';
import { categories } from '../services/londi-constants';

export const screeningResultsReducer = (state = initializer, action) => {
  switch (action.type) {
    case 'classOptions':
      return { ...state, options: 'classOptions' };
    case 'schoolGrade':
      return {
        ...state,
        schoolGrade: action.payload,
        options: '',
      };
    case 'testTimeOptions':
      return { ...state, options: 'testTimeOptions' };
    case 'testTime':
      return {
        ...state,
        testTime: action.payload,
        options: '',
      };
    case 'options':
      return {
        ...state,
        options: '',
      };
    case 'clearReading':
      return {
        ...state,
        screeningResultReading: undefined,
        readingResults: undefined,
        readingTestId: undefined,
        readingSecondTestResults: undefined,
        readingSecondTestId: undefined,
        readingThirdTestResults: undefined,
        readingThirdTestId: undefined,
        readingK1Results: undefined,
        readingK2Results: undefined,
        readingK3Results: undefined,
        readingK4Results: undefined,
      };
    case 'clearWriting':
      return {
        ...state,
        screeningResultWriting: undefined,
        writingResults: undefined,
        writingTestId: undefined,
        writingSecondTestResults: undefined,
        writingSecondTestId: undefined,
        writingThirdTestResults: undefined,
        writingThirdTestId: undefined,
        writingK1Results: undefined,
        writingK2Results: undefined,
        writingK3Results: undefined,
        writingK4Results: undefined,
      };
    case 'clearCounting':
      return {
        ...state,
        screeningResultCounting: undefined,
        countingResults: undefined,
        countingTestId: undefined,
        countingSecondTestResults: undefined,
        countingSecondTestId: undefined,
        countingThirdTestResults: undefined,
        countingThirdTestId: undefined,
        countingK1Results: undefined,
        countingK2Results: undefined,
        countingK3Results: undefined,
        countingK4Results: undefined,
      };
    case 'germanNativeOptions':
      return { ...state, options: 'germanNativeOptions' };
    case 'germanSecondaryLanguage':
      return {
        ...state,
        germanSecondaryLanguage: action.payload,
        options: '',
      };
    case 'screenResultsEstimated':
      return {
        ...state,
        screenResultsEstimated: action.payload,
        options: '',
      };
    case 'educationOptions':
      return { ...state, options: 'educationOptions' };
    case 'education':
      return {
        ...state,
        education: action.payload,
        options: '',
      };
    case 'readingOptions':
      return { ...state, options: 'readingOptions' };
    case 'readingSecondTestOptions':
      return { ...state, options: 'readingSecondTestOptions' };
    case 'writingSecondTestOptions':
      return { ...state, options: 'writingSecondTestOptions' };
    case 'countingSecondTestOptions':
      return { ...state, options: 'countingSecondTestOptions' };
    case 'readingThirdTestOptions':
      return { ...state, options: 'readingThirdTestOptions' };
    case 'writingThirdTestOptions':
      return { ...state, options: 'writingThirdTestOptions' };
    case 'countingThirdTestOptions':
      return { ...state, options: 'countingThirdTestOptions' };
    case 'reading':
      return {
        ...state,
        screeningResultReading: {
          category: categories.Reading,
          result: action.payload,
        },
        options: '',
      };
    case 'writingOptions':
      return { ...state, options: 'writingOptions' };
    case 'writing':
      return {
        ...state,
        screeningResultWriting: {
          category: categories.Writing,
          result: action.payload,
        },
        options: '',
      };
    case 'countingOptions':
      return { ...state, options: 'countingOptions' };
    case 'counting':
      return {
        ...state,
        screeningResultCounting: {
          category: categories.Math,
          result: action.payload,
        },
        options: '',
      };
    case 'ageOptions':
      return { ...state, options: 'ageOptions' };
    case 'age':
      return {
        ...state,
        age: action.payload,
        options: '',
      };
    case 'resultsReadingOptions':
      return {
        ...state,
        options: 'resultsReadingOptions',
        selectedReadingValue: action.payload.id,
      };
    case 'resultsReading':
      const { keyReading, valueReading } = action.payload;
      return {
        ...state,
        resultsReading: { ...state.resultsReading, [keyReading]: valueReading },
        options: '',
      };
    case 'resultsSecondReading':
      const { keySecondReading, valueSecondReading } = action.payload;
      return {
        ...state,
        resultsSecondReading: {
          ...state.resultsSecondReading,
          [keySecondReading]: valueSecondReading,
        },
        options: '',
      };
    case 'resultsThirdReading':
      const { keyThirdReading, valueThirdReading } = action.payload;
      return {
        ...state,
        resultsThirdReading: {
          ...state.resultsThirdReading,
          [keyThirdReading]: valueThirdReading,
        },
        options: '',
      };

    case 'resultsWritingOptions':
      return {
        ...state,
        options: 'resultsWritingOptions',
        selectedWritingValue: action.payload.id,
      };
    case 'resultsWriting':
      const { keyWriting, valueWriting } = action.payload;
      return {
        ...state,
        resultsWriting: { ...state.resultsWriting, [keyWriting]: valueWriting },
        options: '',
      };
    case 'resultsSecondWriting':
      const { keySecondWriting, valueSecondWriting } = action.payload;
      return {
        ...state,
        resultsSecondWriting: {
          ...state.resultsSecondWriting,
          [keySecondWriting]: valueSecondWriting,
        },
        options: '',
      };
    case 'resultsThirdWriting':
      const { keyThirdWriting, valueThirdWriting } = action.payload;
      return {
        ...state,
        resultsThirdWriting: {
          ...state.resultsThirdWriting,
          [keyThirdWriting]: valueThirdWriting,
        },
        options: '',
      };
    case 'resultsCountingOptions':
      return {
        ...state,
        options: 'resultsCountingOptions',
        selectedCountingValue: action.payload.id,
      };
    case 'resultsCounting':
      const { keyCounting, valueCounting } = action.payload;
      return {
        ...state,
        resultsCounting: {
          ...state.resultsCounting,
          [keyCounting]: valueCounting,
        },
        options: '',
      };
    case 'resultsSecondCounting':
      const { keySecondCounting, valueSecondCounting } = action.payload;
      return {
        ...state,
        resultsSecondCounting: {
          ...state.resultsSecondCounting,
          [keySecondCounting]: valueSecondCounting,
        },
        options: '',
      };
    case 'resultsThirdCounting':
      const { keyThirdCounting, valueThirdCounting } = action.payload;
      return {
        ...state,
        resultsThirdCounting: {
          ...state.resultsThirdCounting,
          [keyThirdCounting]: valueThirdCounting,
        },
        options: '',
      };
    case 'readingTest':
      return {
        ...state,
        readingResults: action.payload,
        options: '',
      };
    case 'readingTestId':
      return {
        ...state,
        readingTestId: action.payload,
        options: '',
      };
    case 'readingSecondTest':
      return {
        ...state,
        readingSecondTestResults: action.payload,
        options: '',
      };
    case 'readingSecondTestId':
      return {
        ...state,
        readingSecondTestId: action.payload,
        options: '',
      };
    case 'readingThirdTest':
      return {
        ...state,
        readingThirdTestResults: action.payload,
        options: '',
      };
    case 'readingThirdTestId':
      return {
        ...state,
        readingThirdTestId: action.payload,
        options: '',
      };
    case 'writingTest':
      return {
        ...state,
        writingResults: action.payload,
        options: '',
      };
    case 'writingTestId':
      return {
        ...state,
        writingTestId: action.payload,
        options: '',
      };
    case 'writingSecondTest':
      return {
        ...state,
        writingSecondTestResults: action.payload,
        options: '',
      };
    case 'writingSecondTestId':
      return {
        ...state,
        writingSecondTestId: action.payload,
        options: '',
      };

    case 'writingThirdTest':
      return {
        ...state,
        writingThirdTestResults: action.payload,
        options: '',
      };
    case 'writingThirdTestId':
      return {
        ...state,
        writingThirdTestId: action.payload,
        options: '',
      };
    case 'countingTest':
      return {
        ...state,
        countingResults: action.payload,
        options: '',
      };
    case 'countingTestId':
      return {
        ...state,
        countingTestId: action.payload,
        options: '',
      };
    case 'countingSecondTest':
      return {
        ...state,
        countingSecondTestResults: action.payload,
        options: '',
      };
    case 'countingSecondTestId':
      return {
        ...state,
        countingSecondTestId: action.payload,
        options: '',
      };
    case 'countingThirdTest':
      return {
        ...state,
        countingThirdTestResults: action.payload,
        options: '',
      };
    case 'countingThirdTestId':
      return {
        ...state,
        countingThirdTestId: action.payload,
        options: '',
      };
    default:
      return state;
  }
};

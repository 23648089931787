import { useContext, useRef } from 'react';
import { DiagnosticContext, useOutsideClick } from '../../helpers/functions';
import Tabs from '../Tabs';
import { getTestFields } from '../../services/londi-suggestions';
import { categories } from '../../services/londi-constants';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../elements/container';
import PageHeading from '../../elements/page-heading';
import Button from '../../elements/button';

const CompetenceLevelsTest = ({ el, level, index, inputChange }) => {
  const InvalidMsg = (num) => {
    if (num.validity.valueMissing) {
      num?.setCustomValidity('Bitte wählen Sie eine Zahl zwischen 0 und 100');
    } else if (num?.value > 100 || num?.value < 0) {
      num?.setCustomValidity('Bitte wählen Sie eine Zahl zwischen 0 und 100');
    } else {
      num?.setCustomValidity('');
    }
    return true;
  };
  return (
    el.competenceLevel === level && (
      <div key={index} className="flex flex-col ">
        <div className="mt-4">
          <p className="w-60">{el.title}</p>
          <div className="flex flex-wrap">
            <input
              onChange={inputChange}
              className="btn-options w-24 h-8"
              type="number"
              onInvalid={(e) => InvalidMsg(e.target)}
              onInput={(e) => InvalidMsg(e.target)}
              required={el.competenceLevel === 3 || el.competenceLevel === 1}
              placeholder="0-100"
            />
            <label className="ml-2 text-lg flex items-center" htmlFor="test">
              Prozentrang
            </label>
          </div>
        </div>
      </div>
    )
  );
};

const TestResults = () => {
  const { state, dispatch } = useContext(DiagnosticContext);
  const wrapperRef = useRef(null);
  const testFieldsReading = useMemo(() => getTestFields(state.readingTestId, categories.Reading), [state.readingTestId]);
  const testFieldsReadingSecond = useMemo(() => getTestFields(state.readingSecondTestId, categories.Reading), [state.readingSecondTestId]);
  const testFieldsReadingThird = useMemo(() => getTestFields(state.readingThirdTestId, categories.Reading), [state.readingThirdTestId]);
  const testFieldsWriting = useMemo(() => getTestFields(state.writingTestId, categories.Writing), [state.writingTestId]);
  const testFieldsWritingSecond = useMemo(() => getTestFields(state.writingSecondTestId, categories.Writing), [state.writingSecondTestId]);
  const testFieldsWritingThird = useMemo(() => getTestFields(state.writingThirdTestId, categories.Writing), [state.writingThirdTestId]);
  const testFieldsCounting = useMemo(() => getTestFields(state.countingTestId, categories.Math), [state.countingTestId]);
  const testFieldsCountingSecond = useMemo(() => getTestFields(state.countingSecondTestId, categories.Math), [state.countingSecondTestId]);
  const testFieldsCountingThird = useMemo(() => getTestFields(state.countingThirdTestId, categories.Math), [state.countingThirdTestId]);

  useOutsideClick(wrapperRef);
  const navigate = useNavigate();
  const onSubmit = () => navigate('/funding-recomendations');

  if (state?.screenResultsEstimated) return;

  return (
    <>
      <Tabs />
      <Container className="flex flex-col items-center">
        <PageHeading stretch title="Testergebnisse" />
        <div className="max-w-6xl py-6 space-y-4 -mt-6">
          Bitte geben Sie die Ergebnisse der Tests ein. Es sind mindestens ein Wert für die{' '}
          <a
            href="https://londi.de/ueber-londi/informationen-hilfssystem/"
            target="_blank"
            className="underline hover:text-mainBlue"
            rel="noreferrer"
          >
            Kompetenzstufe
          </a>{' '}
          1 und ein Wert für die{' '}
          <a
            href="https://londi.de/ueber-londi/informationen-hilfssystem/"
            target="_blank"
            className="underline hover:text-mainBlue"
            rel="noreferrer"
          >
            Kompetenzstufe
          </a>{' '}
          3 erforderlich.
        </div>
        <form onSubmit={onSubmit} className="flex flex-col items-center">
          <div className="flex flex-wrap justify-between">
            <div className={`${state.readingResults ? 'flex mt-7' : 'hidden'}`}>
              <div className="flex flex-col w-full">
                {state.readingResults && <p className="text-2xl font-semibold">Lesen</p>}
                <div className="flex flex-wrap">
                  <div className="flex flex-col ml-1 w-full">
                    <p className="text-2xl mt-4 font-semibold">{state.readingResults}</p>
                    <div className="flex flex-wrap">
                      <div className="flex flex-col">
                        {testFieldsReading.map((el) => el.competenceLevel === 1).includes(true) && (
                          <p className="text-lg mt-2 font-semibold">Kompetenzstufe 1</p>
                        )}
                        <div className={`${testFieldsReading.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                          {testFieldsReading.map((el, index) => {
                            return (
                              <CompetenceLevelsTest
                                inputChange={(e) => {
                                  dispatch({
                                    type: 'resultsReading',
                                    payload: { keyReading: index, valueReading: e.target.value },
                                  });
                                }}
                                el={el}
                                index={index}
                                level={1}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        {testFieldsReading.map((el) => el.competenceLevel === 2).includes(true) && (
                          <p className="text-lg mt-2 font-semibold">Kompetenzstufe 2</p>
                        )}

                        <div className={`${testFieldsReading.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                          {testFieldsReading.map((el, index) => {
                            return (
                              <CompetenceLevelsTest
                                inputChange={(e) => {
                                  dispatch({
                                    type: 'resultsReading',
                                    payload: { keyReading: index, valueReading: e.target.value },
                                  });
                                }}
                                el={el}
                                index={index}
                                level={2}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        {testFieldsReading.map((el) => el.competenceLevel === 3).includes(true) && (
                          <p className="text-lg mt-2 font-semibold">Kompetenzstufe 3</p>
                        )}

                        <div className={`${testFieldsReading.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                          {testFieldsReading.map((el, index) => {
                            return (
                              <CompetenceLevelsTest
                                inputChange={(e) => {
                                  dispatch({
                                    type: 'resultsReading',
                                    payload: { keyReading: index, valueReading: e.target.value },
                                  });
                                }}
                                el={el}
                                index={index}
                                level={3}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        {testFieldsReading.map((el) => el.competenceLevel === 4).includes(true) && (
                          <p className="text-lg mt-2 font-semibold">Kompetenzstufe 4</p>
                        )}
                        <div className={`${testFieldsReading.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                          {testFieldsReading.map((el, index) => {
                            return (
                              <CompetenceLevelsTest
                                inputChange={(e) => {
                                  dispatch({
                                    type: 'resultsReading',
                                    payload: { keyReading: index, valueReading: e.target.value },
                                  });
                                }}
                                el={el}
                                index={index}
                                level={4}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {state.readingSecondTestResults && (
                    <div className="flex flex-col ml-1 w-full">
                      <p className="text-2xl mt-4 font-semibold">{state.readingSecondTestResults}</p>
                      <div className="flex flex-wrap">
                        <div className="flex flex-col">
                          {testFieldsReadingSecond.map((el) => el.competenceLevel === 1).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 1</p>
                          )}

                          <div className={`${testFieldsReadingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsReadingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondReading',
                                      payload: { keySecondReading: index, valueSecondReading: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={1}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsReadingSecond.map((el) => el.competenceLevel === 2).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 2</p>
                          )}

                          <div className={`${testFieldsReadingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsReadingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondReading',
                                      payload: { keySecondReading: index, valueSecondReading: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={2}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsReadingSecond.map((el) => el.competenceLevel === 3).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 3</p>
                          )}

                          <div className={`${testFieldsReadingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsReadingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondReading',
                                      payload: { keySecondReading: index, valueSecondReading: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={3}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsReadingSecond.map((el) => el.competenceLevel === 4).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 4</p>
                          )}
                          <div className={`${testFieldsReadingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsReadingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondReading',
                                      payload: { keySecondReading: index, valueSecondReading: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={4}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {state.readingThirdTestResults && (
                    <div className="flex flex-col ml-1 w-full">
                      <p className="text-2xl mt-4 font-semibold">{state.readingThirdTestResults}</p>
                      <div className="flex flex-wrap">
                        <div className="flex flex-col">
                          {testFieldsReadingThird.map((el) => el.competenceLevel === 1).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 1</p>
                          )}

                          <div className={`${testFieldsReadingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsReadingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdReading',
                                      payload: { keyThirdReading: index, valueThirdReading: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={1}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsReadingThird.map((el) => el.competenceLevel === 2).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 2</p>
                          )}

                          <div className={`${testFieldsReadingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsReadingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdReading',
                                      payload: { keyThirdReading: index, valueThirdReading: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={2}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsReadingThird.map((el) => el.competenceLevel === 3).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 3</p>
                          )}

                          <div className={`${testFieldsReadingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsReadingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdReading',
                                      payload: { keyThirdReading: index, valueThirdReading: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={3}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsReadingThird.map((el) => el.competenceLevel === 4).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 4</p>
                          )}
                          <div className={`${testFieldsReadingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsReadingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdReading',
                                      payload: { keyThirdReading: index, valueThirdReading: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={4}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`${state.writingResults ? 'flex mt-7' : 'hidden'}`}>
              <div className="flex flex-col w-full">
                {state.writingResults && <p className="text-2xl font-semibold">Schreiben</p>}
                <div className="flex flex-wrap">
                  <div className="flex flex-col ml-1 w-full">
                    <p className="text-2xl mt-4 font-semibold">{state.writingResults}</p>
                    <div className="flex flex-wrap">
                      <div className="flex flex-col">
                        {testFieldsWriting.map((el) => el.competenceLevel === 1).includes(true) && (
                          <p className="text-lg mt-2 font-semibold">Kompetenzstufe 1</p>
                        )}
                        <div className={`${testFieldsWriting.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                          {testFieldsWriting.map((el, index) => {
                            return (
                              <CompetenceLevelsTest
                                inputChange={(e) => {
                                  dispatch({
                                    type: 'resultsWriting',
                                    payload: { keyWriting: index, valueWriting: e.target.value },
                                  });
                                }}
                                el={el}
                                index={index}
                                level={1}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        {testFieldsWriting.map((el) => el.competenceLevel === 2).includes(true) && (
                          <p className="text-lg mt-2 font-semibold">Kompetenzstufe 2</p>
                        )}
                        <div className={`${testFieldsWriting.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                          {testFieldsWriting.map((el, index) => {
                            return (
                              <CompetenceLevelsTest
                                inputChange={(e) => {
                                  dispatch({
                                    type: 'resultsWriting',
                                    payload: { keyWriting: index, valueWriting: e.target.value },
                                  });
                                }}
                                el={el}
                                index={index}
                                level={2}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        {testFieldsWriting.map((el) => el.competenceLevel === 3).includes(true) && (
                          <p className="text-lg mt-2 font-semibold">Kompetenzstufe 3</p>
                        )}

                        <div className={`${testFieldsWriting.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                          {testFieldsWriting.map((el, index) => {
                            return (
                              <CompetenceLevelsTest
                                inputChange={(e) => {
                                  dispatch({
                                    type: 'resultsWriting',
                                    payload: { keyWriting: index, valueWriting: e.target.value },
                                  });
                                }}
                                el={el}
                                index={index}
                                level={3}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="flex flex-col">
                        {testFieldsWriting.map((el) => el.competenceLevel === 4).includes(true) && (
                          <p className="text-lg mt-2 font-semibold">Kompetenzstufe 4</p>
                        )}
                        <div className={`${testFieldsWriting.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                          {testFieldsWriting.map((el, index) => {
                            return (
                              <CompetenceLevelsTest
                                inputChange={(e) => {
                                  dispatch({
                                    type: 'resultsWriting',
                                    payload: { keyWriting: index, valueWriting: e.target.value },
                                  });
                                }}
                                el={el}
                                index={index}
                                level={4}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {state.writingSecondTestResults && (
                    <div className="flex flex-col ml-1 w-full">
                      <p className="text-2xl mt-4 font-semibold">{state.writingSecondTestResults}</p>
                      <div className="flex flex-wrap">
                        <div className="flex flex-col">
                          {testFieldsWritingSecond.map((el) => el.competenceLevel === 1).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 1</p>
                          )}

                          <div className={`${testFieldsWritingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsWritingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondWriting',
                                      payload: { keySecondWriting: index, valueSecondWriting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={1}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsWritingSecond.map((el) => el.competenceLevel === 2).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 2</p>
                          )}

                          <div className={`${testFieldsWritingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsWritingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondWriting',
                                      payload: { keySecondWriting: index, valueSecondWriting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={2}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsWritingSecond.map((el) => el.competenceLevel === 3).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 3</p>
                          )}

                          <div className={`${testFieldsWritingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsWritingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondWriting',
                                      payload: { keySecondWriting: index, valueSecondWriting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={3}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsWritingSecond.map((el) => el.competenceLevel === 4).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 4</p>
                          )}

                          <div className={`${testFieldsWritingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsWritingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondWriting',
                                      payload: { keySecondWriting: index, valueSecondWriting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={4}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {state.writingThirdTestResults && (
                    <div className="flex flex-col ml-1 w-full">
                      <p className="text-2xl mt-4 font-semibold">{state.writingThirdTestResults}</p>
                      <div className="flex flex-wrap">
                        <div className="flex flex-col">
                          {testFieldsWritingThird.map((el) => el.competenceLevel === 1).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 1</p>
                          )}

                          <div className={`${testFieldsWritingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsWritingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdWriting',
                                      payload: { keyThirdWriting: index, valueThirdWriting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={1}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsWritingThird.map((el) => el.competenceLevel === 2).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 2</p>
                          )}

                          <div className={`${testFieldsWritingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsWritingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdWriting',
                                      payload: { keyThirdWriting: index, valueThirdWriting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={2}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsWritingThird.map((el) => el.competenceLevel === 3).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 3</p>
                          )}

                          <div className={`${testFieldsWritingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsWritingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdWriting',
                                      payload: { keyThirdWriting: index, valueThirdWriting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={3}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsWritingThird.map((el) => el.competenceLevel === 4).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 4</p>
                          )}
                          <div className={`${testFieldsWritingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsWritingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdWriting',
                                      payload: { keyThirdWriting: index, valueThirdWriting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={4}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`${state.countingResults ? 'flex mt-7' : 'hidden'}`}>
              <div className="flex flex-col w-full">
                {state.countingResults && <p className="text-2xl font-semibold">Rechnen</p>}
                <div className="flex flex-wrap">
                  <div className="flex flex-col ml-1 w-full">
                    <p className="text-2xl mt-4 font-semibold">{state.countingResults}</p>
                    <div className={`${testFieldsCounting.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                      <div className="flex flex-wrap">
                        <div className="flex flex-col">
                          {testFieldsCounting.map((el) => el.competenceLevel === 1).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 1</p>
                          )}

                          <div className={`${testFieldsCounting.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCounting.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsCounting',
                                      payload: { keyCounting: index, valueCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={1}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsCounting.map((el) => el.competenceLevel === 2).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 2</p>
                          )}

                          <div className={`${testFieldsCounting.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCounting.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsCounting',
                                      payload: { keyCounting: index, valueCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={2}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsCounting.map((el) => el.competenceLevel === 3).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 3</p>
                          )}

                          <div className={`${testFieldsCounting.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCounting.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsCounting',
                                      payload: { keyCounting: index, valueCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={3}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsCounting.map((el) => el.competenceLevel === 4).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 4</p>
                          )}
                          <div className={`${testFieldsCounting.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCounting.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsCounting',
                                      payload: { keyCounting: index, valueCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={4}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {state.countingSecondTestResults && (
                    <div className="flex flex-col ml-1 w-full">
                      <p className="text-2xl mt-4 font-semibold">{state.countingSecondTestResults}</p>
                      <div className="flex flex-wrap">
                        <div className="flex flex-col">
                          {testFieldsCountingSecond.map((el) => el.competenceLevel === 1).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 1</p>
                          )}

                          <div className={`${testFieldsCountingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCountingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondCounting',
                                      payload: { keySecondCounting: index, valueSecondCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={1}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsCountingSecond.map((el) => el.competenceLevel === 2).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 2</p>
                          )}

                          <div className={`${testFieldsCountingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCountingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondCounting',
                                      payload: { keySecondCounting: index, valueSecondCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={2}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsCountingSecond.map((el) => el.competenceLevel === 3).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 3</p>
                          )}

                          <div className={`${testFieldsCountingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCountingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondCounting',
                                      payload: { keySecondCounting: index, valueSecondCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={3}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsCountingSecond.map((el) => el.competenceLevel === 4).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 4</p>
                          )}
                          <div className={`${testFieldsCountingSecond.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCountingSecond.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsSecondCounting',
                                      payload: { keySecondCounting: index, valueSecondCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={4}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {state.countingThirdTestResults && (
                    <div className="flex flex-col ml-1 w-full">
                      <p className="text-2xl mt-4 font-semibold">{state.countingThirdTestResults}</p>
                      <div className="flex flex-wrap">
                        <div className="flex flex-col">
                          {testFieldsCountingThird.map((el) => el.competenceLevel === 1).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 1</p>
                          )}

                          <div className={`${testFieldsCountingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCountingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdCounting',
                                      payload: { keyThirdCounting: index, valueThirdCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={1}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsCountingThird.map((el) => el.competenceLevel === 2).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 2</p>
                          )}

                          <div className={`${testFieldsCountingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCountingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdCounting',
                                      payload: { keyThirdCounting: index, valueThirdCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={2}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsCountingThird.map((el) => el.competenceLevel === 3).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 3</p>
                          )}

                          <div className={`${testFieldsCountingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCountingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdCounting',
                                      payload: { keyThirdCounting: index, valueThirdCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={3}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="flex flex-col">
                          {testFieldsCountingThird.map((el) => el.competenceLevel === 4).includes(true) && (
                            <p className="text-lg mt-2 font-semibold">Kompetenzstufe 4</p>
                          )}
                          <div className={`${testFieldsCountingThird.length > 5 ? 'flex-wrap' : 'flex-col'} flex flex-row  flex-wrap`}>
                            {testFieldsCountingThird.map((el, index) => {
                              return (
                                <CompetenceLevelsTest
                                  inputChange={(e) => {
                                    dispatch({
                                      type: 'resultsThirdCounting',
                                      payload: { keyThirdCounting: index, valueThirdCounting: e.target.value },
                                    });
                                  }}
                                  el={el}
                                  index={index}
                                  level={4}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Button size="sm" type="submit" className="my-12">
            Weiter
          </Button>
        </form>
      </Container>
    </>
  );
};

export default TestResults;

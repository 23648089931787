import { createContext, useContext, useEffect, useState } from 'react';
import { categories } from '../services/londi-constants';
import { getFundingMatching } from '../services/londi-funding-matching';
import { getTestFields } from '../services/londi-suggestions';
import { screeningResultsState } from './state';
import { DEFAULT_RECOMMENDATIONS_FILTER } from '../components/promotion/FundingRecomendationsFilter';

export const initializer = (initialValue = screeningResultsState) => JSON.parse(sessionStorage.getItem('kidInfo')) || initialValue;

export const DiagnosticContext = createContext();

export const useOutsideClick = (ref) => {
  const { dispatch } = useContext(DiagnosticContext);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch({ type: 'options' });
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dispatch, ref]);
};

export const showFundingRecommendations = (state, filterQuery = DEFAULT_RECOMMENDATIONS_FILTER) => {
  const resultsReading = getTestFields(state.readingTestId, categories.Reading).map((el, i) => {
    return { ...el, result: state?.resultsReading?.[i] };
  });

  const secondResultsReading = getTestFields(state.readingSecondTestId, categories.Reading).map((el, i) => {
    return { ...el, result: state?.resultsSecondReading?.[i] };
  });

  const thirdResultsReading = getTestFields(state.readingThirdTestId, categories.Reading).map((el, i) => {
    return { ...el, result: state?.resultsThirdReading?.[i] };
  });

  const resultsWriting = getTestFields(state.writingTestId, categories.Writing).map((el, i) => {
    return { ...el, result: state?.resultsWriting?.[i] };
  });

  const secondResultsWriting = getTestFields(state.writingSecondTestId, categories.Writing).map((el, i) => {
    return { ...el, result: state?.resultsSecondWriting?.[i] };
  });

  const thirdResultsWriting = getTestFields(state.writingThirdTestId, categories.Writing).map((el, i) => {
    return { ...el, result: state?.resultsThirdWriting?.[i] };
  });

  const resultsCounting = getTestFields(state.countingTestId, categories.Math).map((el, i) => {
    return { ...el, result: state?.resultsCounting?.[i] };
  });

  const secondResultsCounting = getTestFields(state.countingSecondTestId, categories.Math).map((el, i) => {
    return { ...el, result: state?.resultsSecondCounting?.[i] };
  });

  const thirdResultsCounting = getTestFields(state.countingThirdTestId, categories.Math).map((el, i) => {
    return { ...el, result: state?.resultsThirdCounting?.[i] };
  });

  const selfAssessmentReadingResults = [];
  selfAssessmentReadingResults.push(
    { ...state?.readingK1Results, competenceLevel: 1 },
    { ...state?.readingK2Results, competenceLevel: 2 },
    { ...state?.readingK3Results, competenceLevel: 3 },
    { ...state?.readingK4Results, competenceLevel: 4 }
  );

  const selfAssessmentWritingResults = [];
  selfAssessmentWritingResults.push(
    { ...state?.writingK1Results, competenceLevel: 1 },
    { ...state?.writingK2Results, competenceLevel: 2 },
    { ...state?.writingK3Results, competenceLevel: 3 },
    { ...state?.writingK4Results, competenceLevel: 4 }
  );

  const selfAssessmentCountingResults = [];
  selfAssessmentCountingResults.push(
    { ...state?.countingK1Results, competenceLevel: 1 },
    { ...state?.countingK2Results, competenceLevel: 2 },
    { ...state?.countingK3Results, competenceLevel: 3 },
    { ...state?.countingK4Results, competenceLevel: 4 }
  );

  const fundingMatchingRecomendations = {
    education: state.education,
    screeningResults: [
      {
        category: categories.Reading,
        testId: state.readingTestId,
        results: state?.screenResultsEstimated ? selfAssessmentReadingResults : resultsReading,
      },
      {
        category: categories.Reading,
        testId: state.readingSecondTestId,
        results: secondResultsReading,
      },
      {
        category: categories.Reading,
        testId: state.readingThirdTestId,
        results: thirdResultsReading,
      },
      {
        category: categories.Writing,
        testId: state.writingTestId,
        results: state?.screenResultsEstimated ? selfAssessmentWritingResults : resultsWriting,
      },
      {
        category: categories.Writing,
        testId: state.writingSecondTestId,
        results: secondResultsWriting,
      },
      {
        category: categories.Writing,
        testId: state.writingThirdTestId,
        results: thirdResultsWriting,
      },
      {
        category: categories.Math,
        testId: state.countingTestId,
        results: state?.screenResultsEstimated ? selfAssessmentCountingResults : resultsCounting,
      },
      {
        category: categories.Math,
        testId: state.countingSecondTestId,
        results: secondResultsCounting,
      },
      {
        category: categories.Math,
        testId: state.countingThirdTestId,
        results: thirdResultsCounting,
      },
    ],
  };
  //here is all the data that needed for funding recomendations
  return getFundingMatching(fundingMatchingRecomendations, filterQuery);
};

//function combines multiple reducers
export const reduceReducers =
  (...reducers) =>
  (state, action) =>
    reducers.reduce((acc, nextReducer) => nextReducer(acc, action), state);

const getStorageValue = (key, defaultValue) => {
  // getting stored value
  const saved = sessionStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
};

export const useSessionStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

function Input({ label, name, id, ...rest }) {
  return (
    <div>
      {label && (
        <label title={label} htmlFor={id || name} className="inline-block max-w-[75%] truncate text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div>
        <input
          {...rest}
          id={id || name}
          name={name}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
}

export default Input;

import { useContext, useRef } from 'react';
import { DiagnosticContext, useOutsideClick, useSessionStorage } from '../../helpers/functions';
import { categories, classes, getClass } from '../../services/londi-constants';
import { getSuggestedTests } from '../../services/londi-suggestions';
import SecondTestOptions from './SecondTestOptions';
import ThirdTestOption from './ThirdTestOption';
import Checkbox from '../../elements/checkbox';
import Select from '../select';
import classNames from 'classnames';
import Alert from '../../elements/alert';

const newState = {
  screeningResultCounting: { category: 3, result: 1 },
  screeningResultReading: { category: 1, result: 1 },
  screeningResultWriting: { category: 2, result: 1 },
};

const TestProcedure = ({
  testProcedure,
  selectReadingGroup,
  setSelectReadingGroup,
  selectWritingGroup,
  setSelectWritingGroup,
  selectCountingGroup,
  setSelectCountingGroup,
  readingError,
  secondTestReadingError,
  secondTestWritingError,
  secondTestCountingError,
  writingError,
  countingError,
  checkedReading,
  setCheckedReading,
  disableReading,
  setDisableReading,
  checkedWriting,
  setCheckedWriting,
  disableWriting,
  setDisableWriting,
  checkedCounting,
  setCheckedCounting,
  disableCounting,
  setDisableCounting,
  MandatoryError,
}) => {
  const { state, dispatch } = useContext(DiagnosticContext);
  const [selectReadingCompetenceLevel, setSelectReadingCompetenceLevel] = useSessionStorage('readingCompetenceLevel', null);
  const [selectWritingCompetenceLevel, setSelectWritingCompetenceLevel] = useSessionStorage('writingCompetenceLevel', null);
  const [selectCountingCompetenceLevel, setSelectCountingCompetenceLevel] = useSessionStorage('countingCompetenceLevel', null);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);
  const suggestedOutput = getSuggestedTests(newState, false, false, true);
  const handleTestReadingClick = (test) => {
    dispatch({ type: 'readingTest', payload: test.shortName });
    dispatch({ type: 'readingTestId', payload: test.id });
    setCheckedReading(true);
    if (test.group === 'B') {
      setSelectReadingGroup(2);
      setSelectReadingCompetenceLevel(test.competenceLevels);
    } else if (test.group === 'A') {
      setSelectReadingGroup(1);
    } else {
      setSelectReadingGroup(null);
    }
    if (test.group !== 'B') {
      delete state.readingSecondTestResults;
      delete state.readingSecondTestId;
      sessionStorage.setItem('kidInfo', JSON.stringify(state));
    }
  };
  const handleTestWritingClick = (test) => {
    dispatch({ type: 'writingTest', payload: test.shortName });
    dispatch({ type: 'writingTestId', payload: test.id });
    setCheckedWriting(true);
    if (test.group === 'B') {
      setSelectWritingGroup(2);
      setSelectWritingCompetenceLevel(test.competenceLevels);
    } else if (test.group === 'A') {
      setSelectWritingGroup(1);
    } else {
      setSelectWritingGroup(null);
    }
    if (test.group !== 'B') {
      delete state.writingSecondTestResults;
      delete state.writingSecondTestId;
      sessionStorage.setItem('kidInfo', JSON.stringify(state));
    }
  };
  const handleTestCountingClick = (test) => {
    dispatch({ type: 'countingTest', payload: test.shortName });
    dispatch({ type: 'countingTestId', payload: test.id });
    setCheckedCounting(true);
    if (test.group === 'B') {
      setSelectCountingGroup(2);
      setSelectCountingCompetenceLevel(test.competenceLevels);
    } else if (test.group === 'A') {
      setSelectCountingGroup(1);
    } else {
      setSelectCountingGroup(null);
    }
    if (test.group !== 'B') {
      delete state.countingSecondTestResults;
      delete state.countingSecondTestId;
      sessionStorage.setItem('kidInfo', JSON.stringify(state));
    }
  };
  const readingCheckbox = (e) => {
    if (!e.target.checked) {
      setDisableReading(true);
      setCheckedReading(false);
      setSelectReadingGroup(null);
      dispatch({ type: 'clearReading' });
      sessionStorage.setItem('kidInfo', JSON.stringify(state));
    } else {
      setCheckedReading(true);
      setDisableReading(false);
    }
  };

  const writingCheckbox = (e) => {
    if (!e.target.checked) {
      setDisableWriting(true);
      setCheckedWriting(false);
      dispatch({ type: 'clearWriting' });
      setSelectWritingGroup(null);
      sessionStorage.setItem('kidInfo', JSON.stringify(state));
    } else {
      setCheckedWriting(true);
      setDisableWriting(false);
    }
  };
  const countingCheckbox = (e) => {
    if (!e.target.checked) {
      setDisableCounting(true);
      setCheckedCounting(false);
      setSelectCountingGroup(null);
      dispatch({ type: 'clearCounting' });
      sessionStorage.setItem('kidInfo', JSON.stringify(state));
    } else {
      setCheckedCounting(true);
      setDisableCounting(false);
    }
  };

  const hint2 = (
    <Alert type="info">
      <b>Hinweis: </b> Bitte wählen Sie zur optimalen Abdeckung der Kompetenzstufen ein weiteres Testverfahren aus, um fortzufahren
    </Alert>
  );

  const hint3 = (
    <Alert type="info">
      <b>Hinweis: </b>
      Sie können <b>optional</b> zur verbesserten Abdeckung der Kompetenzstufen einen weiteren Test auswählen.
      <br />
      (Angabe <b>nicht</b> erforderlich)
    </Alert>
  );

  if (!testProcedure) return;

  return (
    <div className="space-y-4">
      <div className="hidden lg:block space-y-4">
        {(selectReadingGroup === 2 || selectCountingGroup === 2 || selectWritingGroup === 2) && hint2}
        {(selectReadingGroup === 2 ||
          selectReadingGroup === 1 ||
          selectCountingGroup === 2 ||
          selectCountingGroup === 1 ||
          selectWritingGroup === 2 ||
          selectWritingGroup === 1) &&
          hint3}
      </div>
      <div className="flex flex-col gap-y-6">
        <div>
          <Checkbox size="sm" name="reading" checked={checkedReading} onChange={(e) => readingCheckbox(e)} label="Lesen" />
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-1 xl:gap-4">
            <div className={disableReading ? 'pointer-events-none opacity-25' : ''}>
              <Select
                onToggle={() => dispatch({ type: 'readingOptions' })}
                selected={makeOption()(state?.readingResults)}
                setSelected={(o) => handleTestReadingClick(o.res)}
                options={suggestedOutput
                  ?.filter((o) => o.category === categories.Reading)
                  .flatMap((o) => o.allTests.filter((x) => x.group === 'A' || x.group === 'B'))
                  .map((o) => ({ id: o.id, name: o.shortName, res: o }))}
              />
              {checkedReading && readingError && <p className="mt-2 text-sm text-red-600">Angabe in einem Bereich erforderlich</p>}
            </div>
            <div className={classNames({ 'pointer-events-none opacity-25': disableReading || selectReadingGroup !== 2 })}>
              {selectReadingGroup === 2 && <div className="lg:hidden py-2">{hint2}</div>}
              <SecondTestOptions
                selectCompetenceLevel={selectReadingCompetenceLevel}
                setChecked={setCheckedReading}
                secondTestOptions={'readingSecondTestOptions'}
                secondTestType={'readingSecondTest'}
                secondTestId={'readingSecondTestId'}
                showCategory={categories.Reading}
                showState={state?.readingSecondTestResults}
              />
              {checkedReading && secondTestReadingError && <p className="mt-2 text-sm text-red-600">Angabe in einem Bereich erforderlich</p>}
            </div>
            <div
              className={classNames({
                'pointer-events-none opacity-25': disableReading || !(selectReadingGroup === 2 || selectReadingGroup === 1),
              })}
            >
              {(selectReadingGroup === 2 || selectReadingGroup === 1) && <div className="lg:hidden py-2">{hint3}</div>}
              <ThirdTestOption
                setChecked={setCheckedReading}
                thirdTestType={'readingThirdTest'}
                thirdTestId={'readingThirdTestId'}
                showCategory={categories.Reading}
                thirdTestOptions="readingThirdTestOptions"
                showState={state?.readingThirdTestResults}
              />
            </div>
          </div>
        </div>
        <div>
          <Checkbox size="sm" name="writing" checked={checkedWriting} onChange={(e) => writingCheckbox(e)} label="Schreiben" />
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-1 xl:gap-4">
            <div className={disableWriting ? 'pointer-events-none opacity-25' : ''}>
              <Select
                onToggle={() => dispatch({ type: 'writingOptions' })}
                selected={makeOption()(state?.writingResults)}
                setSelected={(o) => handleTestWritingClick(o.res)}
                options={suggestedOutput
                  ?.filter((o) => o.category === categories.Writing)
                  .flatMap((o) => o.allTests.filter((x) => x.group === 'A' || x.group === 'B'))
                  .map((o) => ({ id: o.id, name: o.shortName, res: o }))}
              />
              {checkedWriting && writingError && <p className="mt-2 text-sm text-red-600">Angabe in einem Bereich erforderlich</p>}
            </div>
            <div className={classNames({ 'pointer-events-none opacity-25': disableWriting || selectWritingGroup !== 2 })}>
              {selectWritingGroup === 2 && <div className="lg:hidden py-2">{hint2}</div>}
              <SecondTestOptions
                selectCompetenceLevel={selectWritingCompetenceLevel}
                setChecked={setCheckedWriting}
                secondTestOptions={'writingSecondTestOptions'}
                secondTestType={'writingSecondTest'}
                secondTestId={'writingSecondTestId'}
                showCategory={categories.Writing}
                showState={state?.writingSecondTestResults}
              />
              {checkedWriting && secondTestWritingError && <p className="mt-2 text-sm text-red-600">Angabe in einem Bereich erforderlich</p>}
            </div>
            <div
              className={classNames({
                'pointer-events-none opacity-25': disableWriting || !(selectWritingGroup === 2 || selectWritingGroup === 1),
              })}
            >
              {(selectWritingGroup === 2 || selectWritingGroup === 1) && <div className="lg:hidden py-2">{hint3}</div>}
              <ThirdTestOption
                setChecked={setCheckedWriting}
                thirdTestType={'writingThirdTest'}
                thirdTestId={'writingThirdTestId'}
                showCategory={categories.Writing}
                thirdTestOptions="writingThirdTestOptions"
                showState={state?.writingThirdTestResults}
              />
            </div>
          </div>
        </div>
        <div>
          <Checkbox size="sm" name="counting" checked={checkedCounting} onChange={(e) => countingCheckbox(e)} label="Rechnen" />
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-1 xl:gap-4">
            <div className={disableCounting ? 'pointer-events-none opacity-25' : ''}>
              <Select
                onToggle={() => dispatch({ type: 'countingOptions' })}
                selected={makeOption()(state?.countingResults)}
                setSelected={(o) => handleTestCountingClick(o.res)}
                options={suggestedOutput
                  ?.filter((o) => o.category === categories.Math)
                  .flatMap((o) => o.allTests.filter((x) => x.group === 'A' || x.group === 'B'))
                  .map((o) => ({ id: o.id, name: o.shortName, res: o }))}
              />
              {checkedCounting && countingError && <p className="mt-2 text-sm text-red-600">Angabe in einem Bereich erforderlich</p>}
            </div>
            <div className={classNames({ 'pointer-events-none opacity-25': disableCounting || selectCountingGroup !== 2 })}>
              {selectCountingGroup === 2 && <div className="lg:hidden py-2">{hint2}</div>}
              <SecondTestOptions
                selectCompetenceLevel={selectCountingCompetenceLevel}
                setChecked={setCheckedCounting}
                secondTestOptions={'countingSecondTestOptions'}
                secondTestType={'countingSecondTest'}
                secondTestId={'countingSecondTestId'}
                showCategory={categories.Math}
                showState={state?.countingSecondTestResults}
              />
              {checkedCounting && secondTestCountingError && <p className="mt-2 text-sm text-red-600">Angabe in einem Bereich erforderlich</p>}
            </div>
            <div
              className={classNames({
                'pointer-events-none opacity-25': disableCounting || !(selectCountingGroup === 2 || selectCountingGroup === 1),
              })}
            >
              {(selectCountingGroup === 2 || selectCountingGroup === 1) && <div className="lg:hidden py-2">{hint3}</div>}
              <ThirdTestOption
                setChecked={setCheckedCounting}
                thirdTestType="countingThirdTest"
                thirdTestId={'countingThirdTestId'}
                showCategory={categories.Math}
                thirdTestOptions="countingThirdTestOptions"
                showState={state?.countingThirdTestResults}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function makeOption(handler = (v) => v) {
  return (v) => {
    if (v === undefined) return undefined;
    return { id: v, name: handler(v) };
  };
}

export default TestProcedure;

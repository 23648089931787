import { useMemo } from 'react';
import Select from '../select';

export const DEFAULT_RECOMMENDATIONS_FILTER = { AE: null, AZ: null, BA: null, BB: null };
function FundingRecomendationsFilter({ onChange, filter }) {
  const selectedMaterialType = useMemo(() => MaterialTypes.find((opt) => opt.field?.[1] === filter.AE) || MaterialTypes[0], [filter.AE]);

  const selectedManagerType = useMemo(() => {
    const selectedField = filter.AZ ? 'AZ' : filter.BA ? 'BA' : filter.BB ? 'BB' : null;
    return ImplementationManagerTypes.find((opt) => opt.field === selectedField);
  }, [filter]);

  const handleMaterialTypeChange = (option) => {
    const nextFilter = { ...filter, AE: null };
    if (option.field) {
      nextFilter['AE'] = option.field[1];
    }
    onChange(nextFilter);
  };

  const handleManagerTypeChange = (option) => {
    const nextFilter = { ...filter, AZ: null, BA: null, BB: null };
    if (option.field) {
      nextFilter[option.field] = 1;
    }
    onChange(nextFilter);
  };

  return (
    <div className="w-full pt-12 lg:pt-24 space-y-2">
      <h3 className="text-lg font-bold text-black">Filteroptionen</h3>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
        <div>
          <Select label="Materialtyp:" selected={selectedMaterialType} setSelected={handleMaterialTypeChange} options={MaterialTypes} />
        </div>
        <div>
          <Select
            label="Durchführungsanleitungen:"
            selected={selectedManagerType}
            setSelected={handleManagerTypeChange}
            options={ImplementationManagerTypes}
          />
        </div>
      </div>
    </div>
  );
}

const MaterialTypes = [
  { id: 1, field: null, name: 'Alle', description: 'Zeigt alle verfügbaren Elemente' },
  { id: 1, field: ['AE', 1], name: 'Digital', description: 'Ist digital oder enthält digitale Elemente' },
  { id: 2, field: ['AE', 0], name: 'Nicht digital', description: 'Enthält keine digitalen Elemente' },
];

const ImplementationManagerTypes = [
  { id: 1, field: null, name: 'Alle', description: 'Zeigt alle verfügbaren Empfehlungen' },
  { id: 2, field: 'AZ', name: 'Förderprogramm', description: 'Förderprogramm führt Übungen selbst durch' },
  { id: 3, field: 'BA', name: 'Eltern', description: 'Eltern führen Übungen durch' },
  { id: 4, field: 'BB', name: 'Fachkraft', description: 'Fachkraft führt Übungen durch' },
];

export default FundingRecomendationsFilter;

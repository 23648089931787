import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DiagnosticContext } from '../../helpers/functions';
import { CompetenceLevels } from './Recomendations';
import { AiFillStar } from 'react-icons/ai';
import { FaUser } from 'react-icons/fa';
import Tooltip from '../tooltip';
import { ExclamationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';
import Modal from '../Modal';

export const DiagnosticRecomendationDetails = ({ res, tooltip }) => {
  const [detailRecomendations, setDetailRecomendations] = useState(false);
  const { state } = useContext(DiagnosticContext);
  const location = useLocation();
  const isLg = useMediaQuery({ query: `(min-width: 1024px)` });

  const [openTipModel, setOpenTipModel] = useState(false);

  return (
    <>
      <div className="flex flex-col justify-between border-gray-400">
        <div className="flex flex-col">
          <div className={`${location.pathname === '/diagnostics-recomendations' ? 'flex items-center' : 'hidden'}`}>
            <div className="flex-1 flex">
              <button onClick={() => setDetailRecomendations(!detailRecomendations)}>
                {detailRecomendations ? (
                  <kbd className="arrow-icon ">
                    <svg className="w-4 h-4" aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                      <path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                    </svg>
                  </kbd>
                ) : (
                  <kbd className="arrow-icon">
                    <svg className="w-4 h-4" aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
                      <path d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z" />
                    </svg>
                  </kbd>
                )}
              </button>
              <p className="text-sm p-1 text-mainBlue font-bold">
                {res.name} ({res.shortName})
              </p>
            </div>
            {tooltip && isLg && (
              <Tooltip text={tooltip} contentClassName="sm:max-w-[50%]">
                <ExclamationCircleIcon className="shrink-0 w-8 h-8 m-1 text-red-600" />
              </Tooltip>
            )}
            {!isLg && tooltip && <ExclamationCircleIcon className="shrink-0 w-8 h-8 m-1 text-red-600" onClick={() => setOpenTipModel(true)} />}
          </div>
          {state.germanSecondaryLanguage && res.dazFairness === 1 && (
            <p className="text-sm text-red-500 italic ml-4 font-semibold">Testfairness für Kinder mit Deutsch als Zweitsprache ist gegeben.</p>
          )}
          {state.germanSecondaryLanguage && res.dazFairness === 0 && (
            <p className="text-sm text-red-500 italic ml-4 font-semibold">
              Testfairness für Kinder mit Deutsch als Zweitsprache ist nicht gegeben.
            </p>
          )}
          {state.germanSecondaryLanguage && res.dazFairness === 99 && (
            <p className="text-sm text-red-500 italic ml-4 font-semibold">
              Testfairness für Kinder mit Deutsch als Zweitsprache wurde nicht überprüft.
            </p>
          )}
          <p className="text-sm pb-2 pt-1 px-2">
            {res?.author}, {res?.year}, {res?.publisher}, {res?.type},
            {res?.processingTimeSingle && <> Bearbeitungszeit Einzeltestung: {res?.processingTimeSingle} Min,</>}
            {res?.processingTimeGroup && <> Bearbeitungszeit Gruppentestung: {res?.processingTimeGroup} Min,</>}
            {/*{Overview && `Normierungsjahr: ${res.standardizationYear}`},*/} Testdurchführung: {res.execution}
          </p>
          {detailRecomendations && (
            <div className="flex flex-col">
              <p className={`${location.pathname === '/diagnostics-recomendations' ? 'flex text-sm p-2' : 'hidden'}`}>{res.description}</p>
            </div>
          )}
        </div>
        {location.pathname === '/diagnostics-overview' && (
          <div className="grid grid-rows-6 grid-cols-2 md:grid-rows-3 md:grid-cols-4 text-xs text-center border-gray-400">
            <div className="space-y-1 row-start-1 col-start-1 border-t border-r border-inherit">
              <p className="py-2">Kompetenzstufen</p>
            </div>
            <div className="grid grid-cols-4 place-items-center row-start-2 col-start-1 border-r border-inherit">
              <div className="">1</div>
              <div className="">2</div>
              <div className="">3</div>
              <div className="">4</div>
            </div>
            <div className="grid grid-cols-4 place-items-center row-start-3 col-start-1 border-r border-inherit">
              {[...Array(4).keys()].map((el) => {
                return (
                  <div>
                    {res.competenceLevels.includes(el + 1) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 text-green-500"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 "
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="row-start-1 col-start-2 row-span-2 border-t md:border-r border-inherit">
              <div className="flex items-center justify-center gap-x-2">
                <p className="py-2">Stichprobengröße</p>
              </div>
            </div>
            <div className="flex items-center row-start-3 col-start-2 md:border-r border-inherit">
              <div className="inline-grid grid-cols-4 gap-3 mx-auto">
                <FaUser className={res.sampleSize >= 1 ? '' : 'opacity-25'} />
                <FaUser className={res.sampleSize >= 2 ? '' : 'opacity-25'} />
                <FaUser className={res.sampleSize >= 3 ? '' : 'opacity-25'} />
                <FaUser className={res.sampleSize === 4 ? '' : 'opacity-25'} />
              </div>
            </div>

            <div className="space-y-1 row-start-4 md:row-start-1 col-start-1 md:col-start-3 row-span-2 border-t border-r border-inherit">
              <p className="py-2">Normierungsjahr</p>
            </div>
            <div className="flex items-center justify-center row-start-6 md:row-start-3 col-start-1 md:col-start-3 border-r border-inherit">
              {res.standardizationYear}
            </div>

            <div className="space-y-1 row-start-4 md:row-start-1 col-start-2 md:col-start-4 row-span-2 border-t border-inherit">
              <div className="flex items-center justify-center gap-x-2">
                <p className="py-2">Güte</p>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-3 place-self-center row-start-6 md:row-start-3 col-start-2 md:col-start-4">
              <RatingStars quality={res.quality} />
            </div>
          </div>
        )}
      </div>
      {tooltip && (
        <Modal open={openTipModel} setOpen={setOpenTipModel} bodyClass="px-6 sm:!px-0 sm:!max-w-sm sm:!mx-auto">
          <div className="px-4 py-6 sm:px-6">
            <h3 className="text-base font-semibold leading-7 text-gray-900">Hinweis</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{tooltip}</p>
          </div>
        </Modal>
      )}
    </>
  );
};

function RatingStars({ quality }) {
  return (
    <>
      <AiFillStar color={quality >= 1 ? 'ffc107' : null} className={quality >= 1 ? null : 'opacity-25'} />
      <AiFillStar color={quality >= 2 ? 'ffc107' : null} className={quality >= 2 ? null : 'opacity-25'} />
      <AiFillStar color={quality === 3 ? 'ffc107' : null} className={quality === 3 ? null : 'opacity-25'} />
    </>
  );
}

import { useContext, useMemo, useState } from 'react';
import { DiagnosticContext } from '../../helpers/functions';
import { getSuggestedTests } from '../../services/londi-suggestions';
import { useLocation } from 'react-router-dom';

import { DiagnosticRecomendationDetails } from './DiagnosticRecomendationDetails';
import { FaUser } from 'react-icons/fa';
import { AiFillStar } from 'react-icons/ai';
import { isEqual } from 'lodash';
import Tooltip from '../tooltip';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import Modal from '../Modal';

export const CompetenceLevels = () => {
  const location = useLocation();
  const Overview = location.pathname.includes('diagnostics-overview');

  if (Overview) {
    return (
      <>
        <div className="flex w-full items-center flex-col mt-1.5">
          <div className="flex flex-col w-full">
            <div className="flex" style={{ width: '50%', flex: '0 0 50%' }}>
              &nbsp;
            </div>
            <div className="flex" style={{ width: '50%', flex: '0 0 50%' }}>
              <div className="flex flex-col competence-levels p-0 h-auto" style={{ width: '120px', flex: '0 0 120px' }}>
                <div className="flex">
                  <p className="text-xs w-full sm:w-3/5 justify-center">Kompetenzstufen</p>
                </div>
                <div className="flex w-full">
                  <div className="competence-levels border-l-0 w-3/12 p-0">1</div>
                  <div className="competence-levels w-3/12 p-0">2</div>
                  <div className="competence-levels w-3/12 p-0">3</div>
                  <div className="competence-levels w-3/12 p-0">4</div>
                </div>
              </div>
              <div className="flex flex-col competence-levels h-auto" style={{ width: '130px', flex: '0 0 130px' }}>
                <div className="flex">
                  <p className="text-xs w-full sm:w-3/5 justify-center">Stichprobengröße</p>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="flex flex-col competence-levels h-auto" style={{ width: '120px', flex: '0 0 120px' }}>
                <div className="flex">
                  <p className="text-xs w-full sm:w-3/5 justify-center text-yellow">Normierungsjahr</p>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="flex flex-col competence-levels h-auto w-full">
                <div className="flex">
                  <p className="text-xs w-full sm:w-3/5 justify-center">Güte</p>
                </div>
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="flex w-full items-center flex-col mt-1.5">
          <div className="flex flex-row w-full diagnostic-table">
            <div className="flex" style={{ width: '50%', flex: '0 0 50%' }}>
              &nbsp;
            </div>
            <div className="flex" style={{ width: '50%', flex: '0 0 50%' }}>
              <div className="flex flex-col competence-levels p-0 h-auto" style={{ width: '120px', flex: '0 0 120px' }}>
                <div className="flex">
                  <p className="text-xs w-full sm:w-3/5 justify-center">Kompetenzstufen</p>
                </div>
                <div className="flex w-full">
                  <div className="competence-levels border-l-0 w-3/12 p-0">1</div>
                  <div className="competence-levels w-3/12 p-0">2</div>
                  <div className="competence-levels w-3/12 p-0">3</div>
                  <div className="competence-levels w-3/12 p-0">4</div>
                </div>
              </div>
              <div className="flex flex-col competence-levels h-auto" style={{ width: '130px', flex: '0 0 130px' }}>
                <div className="flex">
                  <p className="text-xs w-full sm:w-3/5 justify-center">Stichprobengröße</p>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="flex flex-col competence-levels h-auto" style={{ width: '120px', flex: '0 0 120px' }}>
                <div className="flex">
                  <p className="text-xs w-full sm:w-3/5 justify-center">Normierungsjahr</p>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="flex flex-col competence-levels h-auto w-full" style={{ borderRight: '1px solid #9da4ae' }}>
                <div className="flex gap-2 items-center">
                  <p className="text-xs w-full sm:w-3/5 justify-center">Güte</p>
                </div>
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

const MAX_ITEMS_AFTER_COLLAPSE = Number(process.env.REACT_APP_MAX_ITEMS_AFTER_LIST_COLLAPSE) || 3;

function formatRecommendations(suggestedTests, selectedCategory) {
  return suggestedTests.reduce(
    (prev, { category, testsA, testsB, testsC, testsD, testsE }) => {
      if (category !== selectedCategory) return prev;

      let [p1, p2, p3] = prev.list.slice();
      let includesTestsD = prev.includesTestsD;

      if (testsA.length) {
        p1 = [...p1, ...testsA];
      }
      if (testsB.length) {
        p2 = [...p2, ...testsB];
      }

      if (testsA.length || testsB.length) {
        if (testsC.length) {
          p3 = [...p3, ...testsC];
        } else if (testsD.length) {
          includesTestsD = true;
          p3 = [...p3, ...testsD];
        } else if (testsE.length) {
          p3 = [...p3, ...testsE];
        }
      }

      return { list: [p1, p2, p3], includesTestsD };
    },
    { list: [[], [], []], includesTestsD: false }
  );
}
const Recomendations = ({ selectedCategory, filterQuery }) => {
  const isLg = useMediaQuery({ query: `(min-width: 1024px)` });
  const { state } = useContext(DiagnosticContext);

  const [showAll, setShowAll] = useState(false);

  const suggestedTests = useMemo(() => getSuggestedTests(state), [state]);

  const filteredTests = useMemo(() => {
    const applyFilterQuery = (items) =>
      items.filter((item) => {
        let result = false;
        if (filterQuery.AU) result = !!item.testItem['AU'];
        if (filterQuery.AV) result = !!item.testItem['AV'];
        if (!filterQuery.AU && !filterQuery.AV) result = true;

        if (typeof filterQuery.AY === 'number') result = result && Number(item.testItem['AY'] || 0) <= filterQuery.AY;
        if (typeof filterQuery.AZ === 'number') result = result && Number(item.testItem['AZ'] || 0) <= filterQuery.AZ;

        return result;
      });

    return suggestedTests.map(({ allTests, category, testsA, testsB, testsC, testsD, testsE }) => {
      return {
        category,
        allTests: applyFilterQuery(allTests),
        testsA: applyFilterQuery(testsA),
        testsB: applyFilterQuery(testsB),
        testsC: applyFilterQuery(testsC),
        testsD: applyFilterQuery(testsD),
        testsE: applyFilterQuery(testsE),
      };
    });
  }, [filterQuery, suggestedTests]);

  const { list } = useMemo(() => formatRecommendations(filteredTests, selectedCategory), [filteredTests, selectedCategory]);
  const collapsedList = useMemo(() => {
    const [l1, l2, l3] = list;
    // if (l1.length || (l1.length && l1.length + l2.length + l3.length >= MAX_ITEMS_AFTER_COLLAPSE)) {
    //   return [l1.slice(0, MAX_ITEMS_AFTER_COLLAPSE), [], []];
    // } else if (l2.length && l2.length + l3.length >= MAX_ITEMS_AFTER_COLLAPSE) {
    //   return [[], l2.slice(0, MAX_ITEMS_AFTER_COLLAPSE), []];
    // } else if (l3.length >= MAX_ITEMS_AFTER_COLLAPSE) {
    //   return [[], [], l3.slice(0, MAX_ITEMS_AFTER_COLLAPSE)];
    // }

    return [
      l1.slice(0, MAX_ITEMS_AFTER_COLLAPSE),
      l1.length < MAX_ITEMS_AFTER_COLLAPSE ? l2.slice(0, MAX_ITEMS_AFTER_COLLAPSE - l1.length) : [],
      l1.length + l2.length < MAX_ITEMS_AFTER_COLLAPSE ? l3.slice(0, MAX_ITEMS_AFTER_COLLAPSE - l1.length - l2.length) : [],
    ];
  }, [list]);

  const collapsedCount = useMemo(() => {
    const count = (arr) => arr.reduce((prev, curr) => prev + curr.length, 0);

    return count(list) - count(collapsedList);
  }, [collapsedList, list]);

  const isCollapsable = useMemo(() => !isEqual(list, collapsedList), [collapsedList, list]);

  const [tests1, tests2, tests3] = showAll ? list : collapsedList;

  return (
    <div className="w-full space-y-3">
      {isLg && <CompetenceLevels />}
      {tests1.map((test) => (
        <DiagnosticRecomendationRow key={test.id} res={test} />
      ))}
      {tests2.map((test) => (
        <DiagnosticRecomendationRow
          key={test.id}
          res={test}
          tooltip="Für eine aussagekräftige Diagnostik und optimale Nutzung des Hilfssystems empfehlen wir, dass Sie mit den durchgeführten Tests mindestens Informationen auf Ebene von Kompetenzstufe 1 und 3 erheben."
        />
      ))}
      {tests3.map((test) => (
        <DiagnosticRecomendationRow
          key={test.id}
          res={test}
          tooltip="Für eine aussagekräftige Diagnostik und optimale Nutzung des Hilfssystems empfehlen wir, dass Sie mit den durchgeführten Tests mindestens Informationen auf Ebene von Kompetenzstufe 1 und 3 erheben."
        />
      ))}
      {isCollapsable && (
        <div className="flex items-center justify-center py-8">
          <button className="inline-flex items-center text-white bg-mainBlue py-5 px-10 text-xl capitalize" onClick={() => setShowAll(!showAll)}>
            {showAll ? 'weniger' : `mehr (${collapsedCount})`}
          </button>
        </div>
      )}
    </div>
  );
};

function DiagnosticRecomendationRowSmall({ res, tooltip }) {
  const [openTip1Model, setOpenTip1Model] = useState(false);
  const [openTip2Model, setOpenTip2Model] = useState(false);

  return (
    <>
      <div className="border border-gray-400 rounded">
        <DiagnosticRecomendationDetails res={res} tooltip={tooltip} />

        <div className="grid grid-rows-6 grid-cols-2 md:grid-rows-3 md:grid-cols-4 text-xs text-center border-gray-400">
          <div className="space-y-1 row-start-1 col-start-1 border-t border-r border-inherit">
            <p className="py-2">Kompetenzstufen</p>
          </div>
          <div className="grid grid-cols-4 place-items-center row-start-2 col-start-1 border-r border-inherit">
            <div className="">1</div>
            <div className="">2</div>
            <div className="">3</div>
            <div className="">4</div>
          </div>
          <div className="grid grid-cols-4 place-items-center row-start-3 col-start-1 border-r border-inherit">
            {[...Array(4).keys()].map((el) => {
              return (
                <div>
                  {res.competenceLevels.includes(el + 1) ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 text-green-500"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 "
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  )}
                </div>
              );
            })}
          </div>

          <div className="row-start-1 col-start-2 row-span-2 border-t md:border-r border-inherit">
            <div className="flex items-center justify-center gap-x-2" onClick={() => setOpenTip1Model(true)}>
              <p className="py-2">Stichprobengröße</p>
              <QuestionMarkCircleIcon className="w-4 h-4 text-mainBlue" />
            </div>
          </div>
          <div className="flex items-center row-start-3 col-start-2 md:border-r border-inherit">
            <div className="inline-grid grid-cols-4 gap-3 mx-auto">
              <FaUser className={res.sampleSize >= 1 ? '' : 'opacity-25'} />
              <FaUser className={res.sampleSize >= 2 ? '' : 'opacity-25'} />
              <FaUser className={res.sampleSize >= 3 ? '' : 'opacity-25'} />
              <FaUser className={res.sampleSize === 4 ? '' : 'opacity-25'} />
            </div>
          </div>

          <div className="space-y-1 row-start-4 md:row-start-1 col-start-1 md:col-start-3 row-span-2 border-t border-r border-inherit">
            <p className="py-2">Normierungsjahr</p>
          </div>
          <div className="flex items-center justify-center row-start-6 md:row-start-3 col-start-1 md:col-start-3 border-r border-inherit">
            {res.standardizationYear}
          </div>

          <div className="space-y-1 row-start-4 md:row-start-1 col-start-2 md:col-start-4 row-span-2 border-t border-inherit">
            <div className="flex items-center justify-center gap-x-2" onClick={() => setOpenTip2Model(true)}>
              <p className="py-2">Güte</p>
              <QuestionMarkCircleIcon className="w-4 h-4 text-mainBlue" />
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3 place-self-center row-start-6 md:row-start-3 col-start-2 md:col-start-4">
            <RatingStars quality={res.quality} />
          </div>
        </div>
      </div>
      <Modal open={openTip1Model} setOpen={setOpenTip1Model} bodyClass="!w-full px-6 sm:!px-0 sm:!max-w-sm sm:!mx-auto">
        <div className="py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">Stichprobengröße</h3>
          <div className="mt-1 flex flex-col gap-2">
            <div
              className={classNames('border-y border-transparent grid grid-cols-2 py-3 pl-6 pr-14 -mx-6', {
                'bg-mainBlue bg-opacity-30 !border-mainBlue': res.testItem['CA'] === 1,
              })}
            >
              <p>{`< 100`}</p>
              <SampleSizeItem size={1} />
            </div>
            <div
              className={classNames('border-y border-transparent grid grid-cols-2 py-3 pl-6 pr-14 -mx-6', {
                'bg-mainBlue bg-opacity-30 !border-mainBlue': res.testItem['CA'] === 2,
              })}
            >
              <p>{`100 - 119`}</p>
              <SampleSizeItem size={2} />
            </div>
            <div
              className={classNames('border-y border-transparent grid grid-cols-2 py-3 pl-6 pr-14 -mx-6', {
                'bg-mainBlue bg-opacity-30 !border-mainBlue': res.testItem['CA'] === 3,
              })}
            >
              <p>{`120 - 249`}</p>
              <SampleSizeItem size={3} />
            </div>
            <div
              className={classNames('border-y border-transparent grid grid-cols-2 py-3 pl-6 pr-14 -mx-6', {
                'bg-mainBlue bg-opacity-30 !border-mainBlue': res.testItem['CA'] === 4,
              })}
            >
              <p>{`≥ 250`}</p>
              <SampleSizeItem size={4} />
            </div>
          </div>
        </div>
      </Modal>
      <Modal open={openTip2Model} setOpen={setOpenTip2Model} bodyClass="!w-full px-6 sm:!px-0 sm:!max-w-sm sm:!mx-auto">
        <div className="py-6 sm:px-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">Güte</h3>
          <div className="mt-1 flex flex-col gap-2">
            <div className={classNames('border-y border-transparent grid grid-cols-2 py-3 pl-6 pr-14 -mx-6', {})}>
              <p className="font-medium text-sm break-words">Theoretische Grundlage</p>
              <div className="flex items-center justify-end gap-3">
                <RatingStars quality={res.testItem['CC'] + 1} />
              </div>
            </div>
            <div className={classNames('border-y border-transparent grid grid-cols-2 py-3 pl-6 pr-14 -mx-6', {})}>
              <p className="font-medium text-sm  break-words">Objektivität</p>
              <div className="flex items-center justify-end gap-3">
                <RatingStars quality={res.testItem['CD'] + 1} />
              </div>
            </div>
            <div className={classNames('border-y border-transparent grid grid-cols-2 py-3 pl-6 pr-14 -mx-6', {})}>
              <p className="font-medium text-sm  break-words">Reliabilität</p>
              <div className="flex items-center justify-end gap-3">
                <RatingStars quality={res.testItem['CE'] + 1} />
              </div>
            </div>
            <div className={classNames('border-y border-transparent grid grid-cols-2 py-3 pl-6 pr-14 -mx-6', {})}>
              <p className="font-medium text-sm  break-words">Validität</p>
              <div className="flex items-center justify-end gap-3">
                <RatingStars quality={res.testItem['CF'] + 1} />
              </div>
            </div>
            <div className={classNames('border-y border-transparent grid grid-cols-2 py-3 pl-6 pr-14 -mx-6', {})}>
              <p className="font-medium text-sm  break-words">Interpretationseindeutigkeit</p>
              <div className="flex items-center justify-end gap-3">
                <RatingStars quality={res.testItem['CG'] + 1} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function SampleSizeItem({ size }) {
  return (
    <div className="grid grid-cols-4 gap-3 items-center justify-self-end">
      <FaUser className={size >= 1 ? '' : 'opacity-25'} />
      <FaUser className={size >= 2 ? '' : 'opacity-25'} />
      <FaUser className={size >= 3 ? '' : 'opacity-25'} />
      <FaUser className={size === 4 ? '' : 'opacity-25'} />
    </div>
  );
}

const DiagnosticRecomendationRow = ({ res, tooltip }) => {
  const isLg = useMediaQuery({ query: `(min-width: 1024px)` });
  const location = useLocation();
  const Overview = location.pathname.includes('diagnostics-overview');

  if (Overview) return;

  return isLg ? (
    <div className="flex w-full items-center flex-col">
      <div className="flex flex-col border border-gray-400 w-full diagnostic-table">
        <div className=" flex justify-between border-gray-400">
          <DiagnosticRecomendationDetails res={res} tooltip={tooltip} />
          <div className="flex" style={{ width: '50%', flex: '0 0 50%' }}>
            <div className="flex" style={{ width: '120px', flex: '0 0 120px' }}>
              {[...Array(4).keys()].map((el) => {
                return (
                  <div className="competence-levels-table w-3/12">
                    {res.competenceLevels.includes(el + 1) ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 text-green-500"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 "
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    )}
                  </div>
                );
              })}
            </div>
            <Tooltip text={<SampleSizeDetails res={res} />}>
              <div className="flex competence-levels-table justify-center w-full" style={{ width: '130px', flex: '0 0 130px' }}>
                <FaUser className={res.sampleSize >= 1 ? '' : 'opacity-25'} />
                <FaUser className={res.sampleSize >= 2 ? '' : 'opacity-25'} />
                <FaUser className={res.sampleSize >= 3 ? '' : 'opacity-25'} />
                <FaUser className={res.sampleSize === 4 ? '' : 'opacity-25'} />
              </div>
            </Tooltip>
            <div className="competence-levels-table justify-center" style={{ width: '120px', flex: '0 0 120px' }}>
              {res.standardizationYear}
            </div>
            <Tooltip text={<QualityDetails res={res} />} placement="top-end">
              <div className="flex competence-levels-table justify-center w-full">
                <RatingStars quality={res.quality} />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <DiagnosticRecomendationRowSmall tooltip={tooltip} res={res} />
  );
};

function RatingStars({ quality }) {
  return (
    <>
      <AiFillStar color={quality >= 1 ? 'ffc107' : null} className={quality >= 1 ? null : 'opacity-25'} />
      <AiFillStar color={quality >= 2 ? 'ffc107' : null} className={quality >= 2 ? null : 'opacity-25'} />
      <AiFillStar color={quality === 3 ? 'ffc107' : null} className={quality === 3 ? null : 'opacity-25'} />
    </>
  );
}

function QualityDetail({ label, value }) {
  return (
    <div className="flex flex-col items-center py-3 px-4">
      <h5 className="font-medium ">{label}</h5>
      <div className="flex items-center gap-1">
        <RatingStars quality={value} />
      </div>
    </div>
  );
}

function QualityDetails({ res }) {
  return (
    <div className="flex gap-4">
      <QualityDetail label="Theoretische Grundlage" value={res.testItem['CC'] + 1} />
      <QualityDetail label="Objektivität" value={res.testItem['CD'] + 1} />
      <QualityDetail label="Reliabilität" value={res.testItem['CE'] + 1} />
      <QualityDetail label="Validität" value={res.testItem['CF'] + 1} />
      <QualityDetail label="Interpretationseindeutigkeit" value={res.testItem['CG'] + 1} />
    </div>
  );
}

function SampleSizeDetails({ res }) {
  const text = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (res.testItem['CA']) {
      case 1:
        return '< 100';
      case 2:
        return '100 - 119';
      case 3:
        return '120 - 249';
      case 4:
        return '≥ 250';
    }
  }, [res.testItem]);

  return <span className="text-lg p-3">{text}</span>;
}

export default Recomendations;

export const categories = {
  Reading: 1,
  Writing: 2,
  Math: 3,
  ReadingOrWriting: 4,
};

export const testTimes = {
  SchoolYearBegin: 1,
  SchoolYearMiddle: 2,
  SchoolYearEnd: 3,
};

export const education = {
  Elementary: 1,
  Middle: 2,
  High: 3,
};

export const classes = {
  first: 1,
  second: 2,
  third: 3,
  fourth: 4,
  fifth: 5,
  sixth: 6,
};

export const diagnosticResults = {
  Blue: 1,
  Yellow: 2,
  Yellow_NoDiagnosticDesired: 3,
  Green: 4,
};

export const competenceLevels = {
  NotSpecified: -1,
  NoSupport: 35, //Kein Förderbedarf, >30
  LowSupport: 25, //Niedriger Förderbedarf, 21-30
  MediumSupport: 15, //Mittlerer Förderbedarf, 11-20
  HighSupport: 5, //Hoher Förderbedarf, 0-10
};

//use this method to translate the diagnostic result option to the text value
export const getDiagnosticResultText = (result) => {
  if (result === undefined) return null;
  if (typeof result === 'boolean') return '';
  switch (result) {
    case diagnosticResults.Blue:
      return 'Blau - unterdurchschnittliches Screeningergebnis';

    case diagnosticResults.Yellow:
      return 'Gelb - wahrscheinlich unterdurchschnittliches Screeningergebnis (Diagnostik erwünscht)';
    case diagnosticResults.Yellow_NoDiagnosticDesired:
      return 'Gelb - wahrscheinlich unterdurchschnittliches Screeningergebnis (Diagnostik unerwünscht)';

    case diagnosticResults.Green:
      return 'Grün - durchschnittliches oder gutes Screeningergebnis';

    default:
      throw new Error('Invalid diagnostic');
  }
};

export const getCompetenceLevels = (result) => {
  if (result === undefined) return null;
  if (typeof result === 'boolean') return '';
  switch (result) {
    case competenceLevels.NotSpecified:
      return 'Keine Angabe';

    case competenceLevels.NoSupport:
      return 'Kein Förderbedarf';
    case competenceLevels.LowSupport:
      return 'Niedriger Förderbedarf';

    case competenceLevels.MediumSupport:
      return 'Mittlerer Förderbedarf';
    case competenceLevels.HighSupport:
      return 'Hoher Förderbedarf';

    default:
      throw new Error('Invalid competence levels');
  }
};

export const getTestTime = (result) => {
  switch (result) {
    case testTimes.SchoolYearBegin:
      return 'Anfang (bis 6 Wochen vor Halbjahrsende)';
    case testTimes.SchoolYearMiddle:
      return 'Mitte (bis 6 Wochen vor Schuljahresende)';
    case testTimes.SchoolYearEnd:
      return 'Ende (bis 6 Wochen vor Schuljahresende inkl. Sommerpause)';

    default:
      throw new Error('Invalid test time');
  }
};
export const getEducation = (result) => {
  switch (result) {
    case education.Elementary:
      return 'Vorschule';
    case education.Middle:
      return 'Grundschule';
    case education.High:
      return 'Sekundarstufe';

    default:
      throw new Error('Invalid test time');
  }
};

export const getClass = (result) => {
  switch (result) {
    case classes.first:
      return '1.Klasse';

    case classes.second:
      return '2.Klasse';
    case classes.third:
      return '3.Klasse';

    case classes.fourth:
      return '4.Klasse';

    case classes.fifth:
      return '5.Klasse';

    case classes.sixth:
      return '6.Klasse';

    default:
      throw new Error('Invalid class');
  }
};

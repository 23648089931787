export const diagnosticBlue = 'Blau - unterdurchschnittliches Screeningergebnis';
export const diagnosticDesiredYellow = 'Gelb - wahrscheinlich unterdurchschnittliches Screeningergebnis (Diagnostik erwünscht)';
export const diagnosticUndesiredYellow = 'Gelb - wahrscheinlich unterdurchschnittliches Screeningergebnis (Diagnostik unerwünscht)';
export const diagnosticGreen = 'Grün - durchschnittliches oder gutes Screeningergebnis';
export const highSupport = 'Hoher Förderbedarf';
export const mediumSupport = 'Mittlerer Förderbedarf';
export const lowSupport = 'Niedriger Förderbedarf';
export const noSupport = 'Kein Förderbedarf';
export const age = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
export const feedbackGrades = [1, 2, 3, 4, 5, 6, 7];
export const value = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
  38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73,
  74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
];
export const testDiagnosticOptions = [diagnosticBlue, diagnosticDesiredYellow, diagnosticUndesiredYellow, diagnosticGreen];

export const competenceFundingOptions = [highSupport, mediumSupport, lowSupport, noSupport];

export const testFundingOptions = [
  'RZD 2-8 - Rechenfertigkeiten- und Zahlenverarbeitungs-Diagnostikum für die 2. bis 8. Klasse',
  'BUEGA - Basisdiagnostik Umschriebener Entwicklungsstörungen im Grundschulalter',
  'DIRG - Diagnostisches Inventar zu Rechenfertigkeiten im Grundschulalter',
  'ZLT II - Zürcher Lesetest II',
];
export const questionnaireOptions = [
  'Ja, ich stimme voll zu',
  'Ja, ich stimme zu',
  'Ich stimme eher',
  'Neutral',
  'Ich stimme eher nicht zu',
  'Nein, ich stimme nicht zu',
  'Nein, ich stimme gar nicht zu',
];
export const classNumber = ['1.Klasse', '2.Klasse', '3.Klasse', '4.Klasse'];
export const testTime = [
  'Anfang (bis 6 Wochen vor Halbjahrsende)',
  'Mitte (bis 6 Wochen vor Schuljahresende)',
  'Ende (bis 6 Wochen vor Schuljahresende inkl. Sommerpause)',
];

import { useContext, useState } from 'react';
import { DiagnosticContext, showFundingRecommendations } from '../../helpers/functions';
import { categories } from '../../services/londi-constants';
import Questionnaire from '../Questionnaire';
import Tabs from '../Tabs';
import Accordion from '../Accordion';
import FundingRecomendationsFilter, { DEFAULT_RECOMMENDATIONS_FILTER } from './FundingRecomendationsFilter';
import CollapsibleList from '../CollapsibleList';
import FundingRecommendationDetailView from '../FundingRecommendationDetailView';
import PageHeading from '../../elements/page-heading';
import Container from '../../elements/container';
import FundingRecommendationsDetails from './FundingRecommendationsDetails';

const FundingRecomendations = () => {
  const [readingRecomendations, setReadingRecomendations] = useState(false);
  const [writingRecomendations, setWritingRecomendations] = useState(false);
  const [countingRecomendations, setCountingRecomendations] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const { state } = useContext(DiagnosticContext);
  const [filterQuery, setFilterQuery] = useState(DEFAULT_RECOMMENDATIONS_FILTER);

  const proceedFeedback = () => {
    setReadingRecomendations(false);
    setWritingRecomendations(false);
    setCountingRecomendations(false);
    setFeedback(true);
  };

  return (
    <>
      <Tabs />
      <Container className="flex flex-col items-center">
        <PageHeading stretch title="Förderempfehlung" />
        <div className="py-6 space-y-4 -mt-6">
          <p className="text-lg leading-8 text-gray-600">
            Wir empfehlen Ihnen die folgenden Förderprogramme.
            <br />
            Die Reihenfolge der Förderverfahren richtet sich nach der wissenschaftlicher Güte des Verfahrens und der Passung von ermitteltem
            Förderprofil des Kindes und den Förderschwerpunkten des Verfahrens. Je weiter oben ein Verfahren angezeigt wird desto besser ist die
            wissenschaftliche Güte / Passung. Weitere Informationen hierzu finden Sie bei der detaillierten{' '}
            <a href="https://londi.de/ueber-londi/informationen-hilfssystem/" target="_blank" className="link" rel="noreferrer">
              Beschreibung der Funktionsweise
            </a>
            .
          </p>
          <p className="text-md font-medium">Stand der Daten: Ende 2020</p>
        </div>
        <FundingRecomendationsFilter filter={filterQuery} onChange={setFilterQuery} />
        <div className="overflow-x-hidden w-full text-lg md:text-xl space-y-2 mt-8">
          {(state.readingResults || state.readingK1Results) && (
            <Accordion
              open={readingRecomendations}
              toggle={() => setReadingRecomendations(!readingRecomendations)}
              label="Förderempfehlungen Lesen:"
            >
              <div className="space-y-2">
                <CollapsibleList
                  tests={showFundingRecommendations(state, filterQuery)
                    .filter((el) => el.category === categories.Reading)
                    .map((el) => el.results)
                    .flat()}
                  renderItem={(res, index) => <FundingRecommendationsDetails index={index + 1} key={res.id} res={res} />}
                />
              </div>
            </Accordion>
          )}
          {(state.writingResults || state.writingK1Results) && (
            <Accordion
              open={writingRecomendations}
              toggle={() => setWritingRecomendations(!writingRecomendations)}
              label="Förderempfehlungen Schreiben:"
            >
              <div className="space-y-2">
                <CollapsibleList
                  tests={showFundingRecommendations(state, filterQuery)
                    .filter((el) => el.category === categories.Writing)
                    .map((el) => el.results)
                    .flat()}
                  renderItem={(res, index) => <FundingRecommendationsDetails index={index + 1} key={res.id} res={res} />}
                />
              </div>
            </Accordion>
          )}
          {(state.countingResults || state.countingK1Results) && (
            <Accordion
              open={countingRecomendations}
              toggle={() => setCountingRecomendations(!countingRecomendations)}
              label="Förderempfehlungen Rechnen:"
            >
              <div className="space-y-2">
                <CollapsibleList
                  tests={showFundingRecommendations(state, filterQuery)
                    .filter((el) => el.category === categories.Math)
                    .map((el) => el.results)
                    .flat()}
                  renderItem={(res, index) => <FundingRecommendationsDetails index={index + 1} key={res.id} res={res} />}
                />
              </div>
            </Accordion>
          )}
        </div>
        <button
          onClick={() => proceedFeedback()}
          className="text-white font-semibold bg-mainBlue w-56 mb-8 h-10 md:w-72 text-lg md:text-2xl md:h-14 my-10"
        >
          Weiter zur Übersicht
        </button>
        {feedback && <Questionnaire />}
        <p className="text-lg md:text-xl mb-10 mt-3" style={{ marginBottom: feedback ? '300px' : '' }}>
          Auf der nächsten Seite können alle Ergebnisse als PDF ausgedruckt werden.
        </p>
      </Container>
    </>
  );
};

export default FundingRecomendations;

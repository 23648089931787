import { useContext, useRef } from 'react';
import { DiagnosticContext, useOutsideClick } from '../../helpers/functions';
import { getSuggestedTests } from '../../services/londi-suggestions';
import Select from '../select';

const ThirdTestOption = ({ thirdTestOptions, showState, showCategory, thirdTestId, thirdTestType, setChecked }) => {
  const { state, dispatch } = useContext(DiagnosticContext);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  const newState = {
    ...state,
    screeningResultCounting: { category: 3, result: 1 },
    screeningResultReading: { category: 1, result: 1 },
    screeningResultWriting: { category: 2, result: 1 },
  };
  const suggestedOutput = getSuggestedTests(newState, false, false, true);

  return (
    <Select
      onToggle={() => dispatch({ type: thirdTestOptions })}
      selected={makeOption()(showState)}
      setSelected={({ res }) => {
        dispatch({ type: thirdTestType, payload: res.shortName });
        dispatch({ type: thirdTestId, payload: res.id });
        setChecked(true);
      }}
      options={
        state?.options === thirdTestOptions
          ? suggestedOutput
              ?.filter((o) => o.category === showCategory)
              .flatMap((o) => o.allTests)
              .map((o) => ({ id: o.id, name: o.shortName, res: o }))
          : []
      }
    />
  );
};

function makeOption(handler = (v) => v) {
  return (v) => {
    if (v === undefined) return undefined;
    return { id: v, name: handler(v) };
  };
}

export default ThirdTestOption;

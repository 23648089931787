import { useMemo } from 'react';
import Select from '../select';
import Input from '../Input';
import classNames from 'classnames';

export const DEFAULT_FILTER = { AV: null, AU: null, AY: null, AZ: null };
function DiagnostikRecomendationsFilter({ onChange, filter }) {
  const selectedMaterialType = useMemo(() => {
    if (filter.AU) return MaterialTypes[1];
    else if (filter.AV) return MaterialTypes[2];
    return MaterialTypes[0];
  }, [filter.AU, filter.AV]);

  const handleMaterialTypeChange = (option) => {
    const nextFilter = { ...DEFAULT_FILTER, AU: 0, AV: 0 };
    if (option.field) {
      nextFilter[option.field] = 1;
    }
    onChange(nextFilter);
  };

  const handleMaxInputChange = (evt) => {
    const nextFilter = { ...filter };
    evt.preventDefault();
    evt.stopPropagation();

    nextFilter[evt.target.name] = evt.target.value === '' ? null : Number(evt.target.value);

    onChange(nextFilter);
  };

  return (
    <div className="w-full sm:pt-12 lg:pt-24 space-y-2">
      <h3 className="text-lg font-bold text-black">Filteroptionen</h3>
      <div className={classNames('grid grid-cols-1 gap-4 xl:gap-16', !filter.AV && !filter.AU ? 'xl:grid-cols-3' : 'xl:grid-cols-2')}>
        <div>
          <Select label="Testsetting" selected={selectedMaterialType} setSelected={handleMaterialTypeChange} options={MaterialTypes} />
        </div>
        {!!((!filter.AV && !filter.AU) || filter.AU) && (
          <div>
            <Input
              type="number"
              name="AY"
              label="Bearbeitungszeit Einzeltestung"
              onChange={handleMaxInputChange}
              placeholder="maximal Minuten"
              min={0}
            />
          </div>
        )}
        {!!((!filter.AV && !filter.AU) || filter.AV) && (
          <div>
            <Input
              type="number"
              name="AZ"
              label="Bearbeitungszeit Gruppentestung"
              onChange={handleMaxInputChange}
              placeholder="maximal Minuten"
              min={0}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const MaterialTypes = [
  { id: 0, field: null, name: 'Alle', description: 'Zeigt alle verfügbaren Elemente' },
  { id: 1, field: 'AU', name: 'Einzeltestung' },
  { id: 2, field: 'AV', name: 'Gruppentestung' },
];

export default DiagnostikRecomendationsFilter;

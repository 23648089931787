import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { MinusCircleIcon } from '@heroicons/react/24/outline';

export function CheckList({ legend, children }) {
  return (
    <fieldset>
      <legend className="sr-only">{legend}</legend>
      <div className="space-y-1">{children}</div>
    </fieldset>
  );
}

export function CheckListItem({ label, description, checked }) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        {checked ? (
          <CheckCircleIcon className="h-5 w-5 text-blue-500 focus:ring-green-500" />
        ) : (
          <MinusCircleIcon className="h-5 w-5 text-blue-500 focus:ring-red-500" />
        )}
      </div>
      <div className="ml-3 text-sm leading-6">
        <span className="text-gray-900">{label}</span>
        {description && <p className="text-gray-500">{description}</p>}
      </div>
    </div>
  );
}

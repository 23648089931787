import Tabs from './Tabs';
import Container from '../elements/container';
import PageHeading from '../elements/page-heading';
import Button from '../elements/button';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function Choice() {
  const navigate = useNavigate();

  useEffect(() => {
    return () => sessionStorage.clear();
  }, []);

  return (
    <>
      <Tabs />
      <Container className="flex flex-col items-center gap-y-7 mb-16">
        <PageHeading title="Bitte wählen Sie, an welchem Punkt Sie starten möchten" />
        <Button size="lg" onClick={() => navigate('/diagnostics-results')} className="w-full lg:w-3/5">
          Diagnostikempfehlung
        </Button>
        <Button size="lg" onClick={() => navigate('/promotion-test')} className="w-full lg:w-3/5">
          Förderempfehlung
        </Button>
      </Container>
    </>
  );
}
export default Choice;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../elements/container';
import PageHeading from '../elements/page-heading';
import Button from '../elements/button';
import Checkbox from '../elements/checkbox';

function OpeningPage() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [hasAgreed, setHasAgreed] = useState(false);

  const handleSubmit = () => {
    if (hasAgreed) {
      navigate('/information');
    } else {
      setErrorMessage('Stimmen Sie bitte unseren allgemeinen Geschäftsbedingungen zu, um fortzufahren.');
    }
  };
  const handleChange = (e) => {
    if (e.target.checked) {
      setHasAgreed(true);
      setErrorMessage('');
      sessionStorage.clear();
    } else {
      setHasAgreed(false);
    }
  };

  return (
    <Container className="flex flex-col items-center">
      <PageHeading title="LONDI-Hilfssystem" subtitle="Unterstützung bei Diagnostik und Förderung von Lernstörungen bei Kindern" />
      <Button size="lg" onClick={handleSubmit}>
        Start
      </Button>
      <div className="md:px-3 py-12 sm:py-16 lg:px-4 space-y-4">
        <Checkbox
          name="terms & conditions"
          size="lg"
          onChange={handleChange}
          checked={hasAgreed}
          error={errorMessage}
          label={
            <>
              Ich stimme den{' '}
              <a href="https://londi.de/agb/" target="_blank" className="underline hover:text-mainBlue" rel="noreferrer">
                allgemeinen Geschäftsbedingungen
              </a>{' '}
              zu
            </>
          }
        />
        <h6 className="text-base lg:text-lg">
          Bevor Sie das LONDI-Hilfssystem nutzen, lesen Sie sich bitte die{' '}
          <a href="https://londi.de/agb/" target="_blank" className="underline hover:text-mainBlue" rel="noreferrer">
            allgemeinen Geschäftsbedingungen
          </a>{' '}
          und{' '}
          <a
            href="https://londi.de/ueber-londi/informationen-hilfssystem/"
            target="_blank"
            className="underline hover:text-mainBlue"
            rel="noreferrer"
          >
            Bedienungsanleitung
          </a>{' '}
          durch. Zusätzlich beachten Sie bitte unbedingt die folgenden Hinweise:
        </h6>
        <ul className="space-y-2 text-sm lg:text-base list-disc mx-6 text-gray-800">
          <li>
            Das LONDI-Hilfssystem soll Lerntherapeut*innen, Lehrkräfte und Schulpsycholog*innen bei der Diagnostik und Förderung von
            Lernstörungen bei Kindern in den Bereichen Lesen, Rechtschreiben oder Rechnen unterstützen.
          </li>
          <li>
            Bevor Sie das LONDI-Hilfssystem nutzen, lesen Sie sich bitte die allgemeinen Geschäftsbestimmungen durch und beachten Sie unbedingt
            die folgenden Hinweise:
          </li>
          <li>
            Die Nutzung des LONDI-Hilfssystems ist bei einem Kind mit sonderpädagogischem Förderbedarf „Lernen“ oder „geistige Entwicklung“ nicht
            empfohlen.
          </li>
          <li>
            Bei Kindern mit Problemen im Hören, Sehen oder mit feinmotorischen Beeinträchtigungen sollten Sie vor der Nutzung des
            LONDI-Hilfssystems entsprechende Überprüfungen veranlassen.
          </li>
          <li>
            Das LONDI-Hilfssystem kann die professionelle Abklärung auftretender Lernstörungen durch diagnostisch qualifiziertes Personal nicht
            ersetzen.
          </li>
        </ul>
      </div>
    </Container>
  );
}
export default OpeningPage;

import classNames from 'classnames';

function Checkbox({ name, label, error, className, size = 'sm', ...props }) {
  const InputErrorProps = { 'aria-describedby': `${name}-description` };

  return (
    <div className="relative flex items-start">
      <div
        className={classNames('flex items-center', {
          'h-8': size === 'sm',
          'h-8 lg:h-10': size === 'lg',
        })}
      >
        <input
          {...props}
          id={name}
          name={name}
          type="checkbox"
          {...(error ? InputErrorProps : {})}
          className={classNames(className, 'border-gray-300 text-mainGreen focus:ring-mainGreen', {
            'h-6 w-6 rounded': size === 'sm',
            'h-6 lg:h-8 w-6 lg:w-8 rounded lg:rounded-lg': size === 'lg',
            '!border-red-300 focus:ring-red-500': error,
          })}
        />
      </div>
      <div
        className={classNames({
          'ml-3 text-sm leading-8': size === 'sm',
          'ml-3 lg:ml-4 text-sm lg:text-lg leading-8 lg:leading-10': size === 'lg',
        })}
      >
        <label htmlFor={name} className="font-medium text-gray-900">
          {label}
        </label>
        {error && (
          <p id={`${name}-description`} className="text-red-500 leading-normal">
            {error}
          </p>
        )}
      </div>
    </div>
  );
}
export default Checkbox;

import { useNavigate } from 'react-router-dom';
import { DiagnosticContext, useOutsideClick, useSessionStorage } from '../../helpers/functions';
import {
  classes,
  competenceLevels,
  diagnosticResults,
  education,
  getClass,
  getCompetenceLevels,
  getDiagnosticResultText,
  getEducation,
} from '../../services/londi-constants';
import Tabs from '../Tabs';
import SelfAssessment from './SelfAssessment';
import TestProcedure from './TestProcedure';
import { useContext, useRef, useState } from 'react';
import Container from '../../elements/container';
import PageHeading from '../../elements/page-heading';
import Checkbox from '../../elements/checkbox';
import Alert from '../../elements/alert';
import Select from '../select';
import Button from '../../elements/button';
import classNames from 'classnames';

function PromotionTest() {
  const { state, dispatch } = useContext(DiagnosticContext);
  const [selfAssessment, setSelfAssessment] = useSessionStorage('selfAssessment', false);
  const [checkMark, setCheckMark] = useSessionStorage('checkmark', false);
  const [testProcedure, setTestProcedure] = useState(true);
  const [checkedReading, setCheckedReading] = useSessionStorage('checkboxesReading', false);
  const [checkedWriting, setCheckedWriting] = useSessionStorage('checkboxesWriting', false);
  const [disableWriting, setDisableWriting] = useSessionStorage('checkboxesDisableWriting', false);
  const [checkedCounting, setCheckedCounting] = useSessionStorage('checkboxesCounting', false);
  const [disableCounting, setDisableCounting] = useSessionStorage('checkboxesDisableCounting', false);
  const [educationError, setEducationError] = useState(false);
  const [readingError, setReadingError] = useState(false);
  const [writingError, setWritingError] = useState(false);
  const [countingError, setCountingError] = useState(false);
  const [secondTestReadingError, setSecondTestReadingError] = useState(false);
  const [secondTestWritingError, setSecondTestWritingError] = useState(false);
  const [secondTestCountingError, setSecondTestCountingError] = useState(false);
  const [disableReading, setDisableReading] = useSessionStorage('checkboxesDisableReading', false);
  const [selectReadingGroup, setSelectReadingGroup] = useSessionStorage('readingGroup', null);
  const [selectWritingGroup, setSelectWritingGroup] = useSessionStorage('writingGroup', null);
  const [selectCountingGroup, setSelectCountingGroup] = useSessionStorage('countingGroup', null);
  const [MandatoryError, setMandatoryError] = useState(false);
  const { NotSpecified: _, ...specifiedCompetenceLevels } = competenceLevels;
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);
  const navigate = useNavigate();

  const handleOnChange = (e) => {
    if (e.target.checked) {
      dispatch({ type: 'screenResultsEstimated', payload: true });
      setSelfAssessment(true);
      setCheckMark(true);
      setTestProcedure(false);
    } else {
      dispatch({ type: 'screenResultsEstimated', payload: false });
      setSelfAssessment(false);
      setCheckMark(false);
      setTestProcedure(true);
    }
  };

  const onSubmit = () => {
    if (state.education === undefined) {
      setEducationError(true);
    }

    if (!checkedReading && !checkedWriting && !checkedCounting) {
      setMandatoryError(true);
      return;
    } else {
      setMandatoryError(false);
      setReadingError(false);
      setWritingError(false);
      setCountingError(false);
    }

    if (state.readingResults === undefined && !selfAssessment && checkedReading) {
      setReadingError(true);
    }
    if (state.writingResults === undefined && !selfAssessment && checkedWriting) {
      setWritingError(true);
    }
    if (state.countingResults === undefined && !selfAssessment && checkedCounting) {
      setCountingError(true);
    }
    if (selectReadingGroup === 2 && state?.readingSecondTestResults === undefined) {
      setSecondTestReadingError(true);
    }
    if (selectWritingGroup === 2 && state?.writingSecondTestResults === undefined) {
      setSecondTestWritingError(true);
    }
    if (selectCountingGroup === 2 && state?.countingSecondTestResults === undefined) {
      setSecondTestCountingError(true);
    }
    if (
      !state?.screenResultsEstimated &&
      state.education !== undefined &&
      (state.readingResults !== undefined || state.writingResults !== undefined || state.countingResults !== undefined)
    ) {
      let redirect = true;
      //if the second group is chosen the user must select second test
      if (selectReadingGroup === 2 && !state?.readingSecondTestResults) {
        redirect = false;
      }
      if (selectWritingGroup === 2 && !state?.writingSecondTestResults) {
        redirect = false;
      }
      if (selectCountingGroup === 2 && !state?.countingSecondTestResults) {
        redirect = false;
      }
      if (redirect) navigate('/test-results');
    }
    if (selfAssessment) {
      if (state.education === undefined) {
        setEducationError(true);
      }
      if (
        checkedReading &&
        (state.readingK1Results === undefined ||
          state.readingK2Results === undefined ||
          state.readingK3Results === undefined ||
          state.readingK4Results === undefined)
      ) {
        setReadingError(true);
      }
      if (
        checkedWriting &&
        (state.writingK1Results === undefined ||
          state.writingK2Results === undefined ||
          state.writingK3Results === undefined ||
          state.writingK4Results === undefined)
      ) {
        setWritingError(true);
      }
      if (
        checkedCounting &&
        (state.countingK1Results === undefined ||
          state.countingK2Results === undefined ||
          state.countingK3Results === undefined ||
          state.countingK4Results === undefined)
      ) {
        setCountingError(true);
      }
      if (
        state.education !== undefined &&
        (state.readingK1Results !== undefined || state.writingK1Results !== undefined || state.countingK1Results !== undefined)
      ) {
        let redirect = true;
        if (
          checkedReading &&
          (state?.readingK1Results === undefined ||
            state?.readingK2Results === undefined ||
            state?.readingK3Results === undefined ||
            state?.readingK4Results === undefined)
        ) {
          redirect = false;
        }
        if (
          checkedWriting &&
          (state.writingK1Results === undefined ||
            state.writingK2Results === undefined ||
            state.writingK3Results === undefined ||
            state.writingK4Results === undefined)
        ) {
          redirect = false;
        }
        if (
          state.screenResultsEstimated &&
          checkedCounting &&
          (state.countingK1Results === undefined ||
            state.countingK2Results === undefined ||
            state.countingK3Results === undefined ||
            state.countingK4Results === undefined)
        ) {
          redirect = false;
        }
        console.warn(redirect);
        if (redirect) navigate('/funding-recomendations');
      }
    }
  };

  return (
    <>
      <Tabs />
      <Container className="flex flex-col items-center">
        <PageHeading stretch title="Testauswahl" />
        <div className="max-w-6xl py-6 space-y-4 -mt-6">
          <p className="text-lg leading-8 text-gray-600">
            Bitte wählen Sie aus, welche Testverfahren Sie verwendet haben. Alternativ können Sie als Fachkraft auch selbst einschätzen wie die
            Fertigkeiten des Kindes auf Ebene der Kompetenzstufen ausgeprägt sind. Informationen zu den Kompetenzstufen finden Sie{' '}
            <a
              href="https://londi.de/ueber-londi/informationen-hilfssystem/"
              target="_blank"
              className="underline hover:text-mainBlue"
              rel="noreferrer"
            >
              hier
            </a>
            .
          </p>
        </div>
        <div className="max-w-6xl py-6 w-full grid grid-cols-1 gap-12 lg:gap-28">
          <div className="w-full space-y-4">
            <h3 className="text-base font-semibold">Angaben zum Kind</h3>
            <div>
              <Select
                label="Bildungsbereich"
                onToggle={() => dispatch({ type: 'educationOptions' })}
                selected={makeOption(getEducation)(state?.education)}
                setSelected={(o) => {
                  dispatch({ type: 'education', payload: o.id });
                  setEducationError(false);
                }}
                options={Object.values(education).map(makeOption(getEducation))}
              />
              {educationError && <p className="mt-2 text-sm text-red-600">Feld erforderlich</p>}
            </div>
          </div>
          <div className="w-full space-y-4">
            <div className="space-y-4">
              <Checkbox
                size="lg"
                name="condition"
                checked={checkMark}
                onChange={(e) => handleOnChange(e)}
                label="Ich habe keine Tests gemacht, aber ich bin eine Fachkraft, die in der Lage ist, die Fertigkeiten des Kindes im Lesen, Schreiben und/oder Rechnen selber einzuschätzen."
              />
            </div>
            <h3 className="text-base font-semibold">{selfAssessment ? 'Selbsteinschätzung' : 'Testverfahren'}</h3>
            {selfAssessment ? (
              <div className="flex flex-col gap-y-6">
                <SelfAssessmentGroup
                  colorClass="text-yellow-400"
                  name="Reading"
                  label="Lesen"
                  error={readingError}
                  disabled={disableReading}
                  checked={checkedReading}
                  setChecked={setCheckedReading}
                  setDisable={setDisableReading}
                  selectors={[
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Basiskompetenzen Lesen',
                      options: 'readingK1Options',
                      type: 'readingK1',
                      value: state?.readingK1Results,
                      levels: specifiedCompetenceLevels,
                    },
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Erstes Lesen',
                      options: 'readingK2Options',
                      type: 'readingK2',
                      value: state?.readingK2Results,
                      levels: competenceLevels,
                    },
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Lesen',
                      options: 'readingK3Options',
                      type: 'readingK3',
                      value: state?.readingK3Results,
                      levels: specifiedCompetenceLevels,
                    },
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Angewandtes und flexibles Lesen',
                      options: 'readingK4Options',
                      type: 'readingK4',
                      value: state?.readingK4Results,
                      levels: competenceLevels,
                    },
                  ]}
                />
                <SelfAssessmentGroup
                  colorClass="text-green-400"
                  name="Writing"
                  label="Schreiben"
                  error={writingError}
                  disabled={disableWriting}
                  checked={checkedWriting}
                  setChecked={setCheckedWriting}
                  setDisable={setDisableWriting}
                  selectors={[
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Basiskompetenzen Schreiben',
                      options: 'writingK1Options',
                      type: 'writingK1',
                      value: state?.writingK1Results,
                      levels: specifiedCompetenceLevels,
                    },
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Erstes Schreiben',
                      options: 'writingK2Options',
                      type: 'writingK2',
                      value: state?.writingK2Results,
                      levels: competenceLevels,
                    },
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Schreiben',
                      options: 'writingK3Options',
                      type: 'writingK3',
                      value: state?.writingK3Results,
                      levels: specifiedCompetenceLevels,
                    },
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Angewandtes und flexibles Schreiben',
                      options: 'writingK4Options',
                      type: 'writingK4',
                      value: state?.writingK4Results,
                      levels: competenceLevels,
                    },
                  ]}
                />
                <SelfAssessmentGroup
                  colorClass="text-blue-400"
                  name="Counting"
                  label="Rechnen"
                  error={countingError}
                  disabled={disableCounting}
                  checked={checkedCounting}
                  setChecked={setCheckedCounting}
                  setDisable={setDisableCounting}
                  selectors={[
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Basiskompetenzen Rechnen',
                      options: 'countingK1Options',
                      type: 'countingK1',
                      value: state?.countingK1Results,
                      levels: specifiedCompetenceLevels,
                    },
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Erstes Rechnen',
                      options: 'countingK2Options',
                      type: 'countingK2',
                      value: state?.countingK2Results,
                      levels: competenceLevels,
                    },
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Rechnen',
                      options: 'countingK3Options',
                      type: 'countingK3',
                      value: state?.countingK3Results,
                      levels: specifiedCompetenceLevels,
                    },
                    {
                      link: 'https://londi.de/ueber-londi/informationen-hilfssystem/#1675937178360-b8f1c636-fb15',
                      label: 'Angewandtes und flexibles Rechnen',
                      options: 'countingK4Options',
                      type: 'countingK4',
                      value: state?.countingK4Results,
                      levels: competenceLevels,
                    },
                  ]}
                />
              </div>
            ) : (
              <TestProcedure
                selectCountingGroup={selectCountingGroup}
                setSelectCountingGroup={setSelectCountingGroup}
                selectReadingGroup={selectReadingGroup}
                setSelectReadingGroup={setSelectReadingGroup}
                selectWritingGroup={selectWritingGroup}
                setSelectWritingGroup={setSelectWritingGroup}
                secondTestReadingError={secondTestReadingError}
                secondTestWritingError={secondTestWritingError}
                secondTestCountingError={secondTestCountingError}
                readingError={readingError}
                writingError={writingError}
                countingError={countingError}
                testProcedure={testProcedure}
                checkedReading={checkedReading}
                setCheckedReading={setCheckedReading}
                disableReading={disableReading}
                setDisableReading={setDisableReading}
                checkedWriting={checkedWriting}
                setCheckedWriting={setCheckedWriting}
                disableWriting={disableWriting}
                setDisableWriting={setDisableWriting}
                checkedCounting={checkedCounting}
                setCheckedCounting={setCheckedCounting}
                disableCounting={disableCounting}
                setDisableCounting={setDisableCounting}
                MandatoryError={MandatoryError}
              />
            )}
            {MandatoryError && <Alert type="error">Angabe in mindestens einem Bereich erforderlich</Alert>}
          </div>
        </div>
        <Button size="sm" onClick={onSubmit} className="my-12">
          Weiter
        </Button>
      </Container>
    </>
  );
}

function SelfAssessmentGroup({ colorClass, name, label, checked, setDisable, setChecked, disabled, error, selectors }) {
  const { dispatch } = useContext(DiagnosticContext);

  const handleOnChecked = (e) => {
    if (!e.target.checked) {
      setDisable(true);
      setChecked(false);
      dispatch({ type: `clear${name}` });
    } else {
      setDisable(false);
      setChecked(true);
    }
  };

  return (
    <div>
      <Checkbox size="sm" name={name} checked={checked} onChange={handleOnChecked} label={label} />
      {error && <Alert type="error">Alle Felder sind erforderlich</Alert>}
      <div className="grid grid-cols-1 xl:grid-cols-4 gap-1 xl:gap-4">
        {selectors.map(({ link, label, options, type, value, levels }) => (
          <div key={type} className={disabled ? 'pointer-events-none opacity-25' : ''}>
            <Select
              label={
                <a href={link} className={classNames(colorClass, 'underline')} target="_blank" rel="noreferrer">
                  {label}
                </a>
              }
              onToggle={() => dispatch({ type: options })}
              selected={makeOption(getCompetenceLevels)(value?.result)}
              setSelected={(o) => {
                dispatch({ type: type, payload: o.id });
                setChecked(true);
              }}
              options={Object.values(levels).map(makeOption(getCompetenceLevels))}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function makeOption(handler) {
  return (v) => {
    if (v === undefined) return undefined;
    return { id: v, name: handler(v) };
  };
}

export default PromotionTest;

import classNames from 'classnames';

function PageHeading({ title, subtitle, stretch, classes }) {
  return (
    <div className="bg-white md:px-3 py-12 sm:py-16 lg:px-4">
      <div className={classNames('mx-auto text-center', { 'max-w-2xl': !stretch })}>
        <h2 className={classNames(classes?.title, 'text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl')}>{title}</h2>
        {subtitle && <p className={classNames(classes?.subtitle, 'mt-6 text-lg leading-8 text-gray-600')}>{subtitle}</p>}
      </div>
    </div>
  );
}

export default PageHeading;

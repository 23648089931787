import { useContext, useMemo, useState } from 'react';
import { DiagnosticContext } from '../../helpers/functions';
import { categories } from '../../services/londi-constants';
import { getSuggestedTests } from '../../services/londi-suggestions';
import Questionnaire from '../Questionnaire';
import Tabs from '../Tabs';

import Accordion from '../Accordion';
import Recomendations from './Recomendations';
import DiagnostikRecomendationsFilter, { DEFAULT_FILTER } from '../promotion/DiagnostikRecomendationsFilter';
import PageHeading from '../../elements/page-heading';
import Container from '../../elements/container';
import Button from '../../elements/button';

const DiagnostikRecomendations = () => {
  const [openSegments, setOpenSegments] = useState([]);
  const [feedback, setFeedback] = useState(false);
  const { state } = useContext(DiagnosticContext);
  const suggestedTests = getSuggestedTests(state);
  const [filterQuery, setFilterQuery] = useState(DEFAULT_FILTER);

  const handleToggleTestSegment = (category) => {
    if (openSegments.includes(category)) {
      setOpenSegments(openSegments.filter((s) => s !== category));
    } else {
      setOpenSegments([...openSegments, category]);
    }
  };
  const proceedFeedback = () => {
    setOpenSegments([]);
    setFeedback(true);
  };
  return (
    <>
      <Tabs />
      <Container className="flex flex-col items-center">
        <PageHeading stretch title="Diagnostikempfehlung" />
        <div className="py-6 space-y-4 -mt-6">
          <p>Wir empfehlen Ihnen die folgenden Verfahren für eine tiefergehende Diagnostik.</p>
          <p>
            KS1, KS2, KS3, KS4 = Die Häkchen geben an, welche Kompetenzstufen (KS) mit Hilfe eines Testverfahrens überprüft werden können.
            Informationen über die Kompetenzstufen finden Sie{' '}
            <a href="https://londi.de/ueber-londi/informationen-hilfssystem/" target="_blank" className="link" rel="noreferrer">
              hier
            </a>
            .
          </p>
          <p>
            Das LONDI-Hilfsysstem verlangt die Durchführung von Diagnostikverfahren auf min. den Kompetenzstufen 1 und 3. Sollte ein
            Diagnostikverfahren nicht beide Kompetenzstufen abdecken muss noch ein zweites Verfahren ausgewählt werden.
          </p>
          <p>
            Die Reihenfolge in der die Tests dargestellt werden richtet sich hauptsächlich nach der Anzahl der Kompetenzstufen. Weitere
            Informationen zur Reihenfolge, der Darstellung der Größe der Normstichprobe und der wissenschaftlichen Güte des Testverfahrens finden
            Sie in der{' '}
            <a href="https://londi.de/ueber-londi/informationen-hilfssystem/" target="_blank" className="link" rel="noreferrer">
              Beschreibung der Funktionsweise
            </a>{' '}
            des LONDI-Hilfssystems.
          </p>
          <p className="text-md font-medium">Stand der Daten: Ende 2020</p>
        </div>
        <DiagnostikRecomendationsFilter filter={filterQuery} onChange={setFilterQuery} />
        <div className="text-lg flex flex-col gap-y-3 justify-center items-center w-full md:text-xl mt-8">
          {suggestedTests.map((test) => (
            <Accordion
              key={test.category}
              id={test.category}
              open={openSegments.includes(test.category)}
              toggle={handleToggleTestSegment}
              label={mapTestCategory2Label[test.category]}
            >
              <Recomendations selectedCategory={test.category} filterQuery={filterQuery} />
            </Accordion>
          ))}
        </div>
        <Button size="sm" onClick={() => proceedFeedback()} className="w-56 md:w-72 my-8">
          Weiter zur Übersicht
        </Button>
        {feedback && <Questionnaire />}
        <p className="text-lg md:text-xl mt-3 mb-10 text-center" style={{ marginBottom: feedback ? '300px' : '' }}>
          Auf der nächsten Seite können alle Ergebnisse als PDF ausgedruckt werden.
        </p>
      </Container>
    </>
  );
};

const mapTestCategory2Label = {
  [categories.Reading]: 'Diagnostikempfehlungen Lesen:',
  [categories.Writing]: 'Diagnostikempfehlungen Schreiben:',
  [categories.Math]: 'Diagnostikempfehlungen Rechnen:',
};

export default DiagnostikRecomendations;

const Footer = () => {
  const SHOW_FOOTER = !window.location.search.includes('hide_footer') ||
                      process.env.REACT_APP_SHOW_FOOTER === '1';


    return (
        SHOW_FOOTER ? <div className="py-4 w-full bg-mainBlue flex-col lg:flex-row justify-between items-center flex gap-y-4 lg:gap-0">
            <p className="text-sm text-center lg:text-start sm:text-lg ml-2 w-full sm:w-1/2 text-white">
                ©{new Date().getFullYear()} LONDI. All rights reserved.
            </p>
            <ul className="flex text-sm sm:text-lg w-full lg:w-3/5 text-white justify-around">
                <a href="https://londi.de/" target="_blank" rel="noreferrer">LONDI Startseite</a>
                <a href="https://londi.de/datenschutzerklarung/" target="_blank" rel="noreferrer">Datenschutzerklärung</a>
                <a href="https://londi.de/impressum/" target="_blank" rel="noreferrer">Impressum</a>
                <a href="https://londi.de/agb/" target="_blank" rel="noreferrer">AGB</a>
            </ul> </div> : null
    )
}
export default Footer

export const screeningResultsState = {
  schoolGrade: null,
  testTime: null,
  education: null,
  germanSecondaryLanguage: null,
  screenResultsEstimated: null,
  screeningResultReading: null,
  screeningResultWriting: null,
  screeningResultCounting: null,
  categoriesReadingOptions: null,
  categoriesWritingOptions: null,
  categoriesCountingOptions: null,
  options: '',
  age: '',
  value: '',
  readingK1Results: '',
  readingK2Results: '',
  readingK3Results: '',
  readingK4Results: '',
  writingK1Results: '',
  writingK2Results: '',
  writingK3Results: '',
  writingK4Results: '',
  countingK1Results: '',
  countingK2Results: '',
  countingK3Results: '',
  countingK4Results: '',
};

export const questionnaireState = {
  helping: null,
  complication: null,
  efficiency: null,
  simplicity: null,
  exciting: null,
  interest: null,
  originality: null,
  novelty: null,
  usability: null,
};

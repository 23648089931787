import { diagnosticResults, testTimes, categories } from './londi-constants.js';
import _ from 'lodash';

const idHeader = 'A'; //"ID";

const TAB_HEADER_TESTS = 'Test-Ebene';
const TAB_HEADER_SUBTESTS = 'Subtest-Ebene';

const TAB_HEADER_SUBTESTS_COMPETENCE_FILTER = 'AF'; // "Kompetenz-stufe auf Basis von Ebene 2";

/*
const diagnosticResults = {
  Blue: 1,
  Yellow: 2,
  Yellow_NoDiagnosticDesired: 3,
  Green: 4,
};

const testTimes = {
  SchoolYearBegin: 1,
  SchoolYearMiddle: 2,
  SchoolYearEnd: 3,
};

const categories = {
  Reading: 1,
  Writing: 2,
  Math: 3,
};
*/

export const londiData = require('../assets/LONDI Tests Dokumentation_2022 Jan 19.json');
export const londiSubtests = londiData[TAB_HEADER_SUBTESTS].filter((x) => x['Y'] === 'ja');

//update IDs - remove "a" after the ID (keep only numbers)
const updateIds = (tests) => {
  tests.forEach((test) => {
    if (typeof test[idHeader] === 'string' || test[idHeader] instanceof String) {
      test[idHeader] = parseInt(test[idHeader].replace('a', ''));
    }
  });
};

updateIds(londiData[TAB_HEADER_TESTS]);
updateIds(londiData[TAB_HEADER_SUBTESTS]);

//returns list of categories and suggested tests for each category
//expected inputs format:
/*const inputsExample = {
  schoolGrade: 1,
  testTime: 2, //see enum testTimes
  germanSecondaryLanguage: true,
  screenResultsEstimated: false, //false to use blue/yellow/green results, or true if using true/false results (1 or 0)
  screeningResults: [
    { category: categories.Reading, result: 1 }, //result value is either from diagnosticResults or (1 / 0)
    { category: categories.Writing, result: 2 },
    { category: categories.Math, result: 3 },
  ],
};*/

const getDAZFairness = (test) => {
  const fairnessHeader = 'AI'; //"DAZ-FAIRNESS";
  return test[fairnessHeader] ? parseInt(test[fairnessHeader]) : 0;
};

const getGradeHeaderFromNumber = (grade) => {
  if (grade === undefined) return null;

  switch (grade) {
    case 1:
      return 'C';
    case 2:
      return 'D';
    case 3:
      return 'E';
    case 4:
      return 'F';
    case 5:
      return 'G';
    case 6:
      return 'H';
    default:
      throw new Error('Invalid grade number');
  }
};

const getTimeOfTestHeaderFromNumber = (grade) => {
  if (grade === undefined) return null;

  switch (grade) {
    case 1:
      return 'I';
    case 2:
      return 'J';
    case 3:
      return 'K';
    case 4:
      return 'L';
    case 5:
      return 'M';
    case 6:
      return 'N';
    default:
      throw new Error('Invalid grade number');
  }
};

const getIntArray = (cell) => {
  if (cell) {
    if (typeof cell === 'string' || cell instanceof String) {
      return cell.split(',').map((item) => parseInt(item));
    } else {
      return cell
        .toString()
        .split('.')
        .map((item) => parseInt(item));
    }
  } else {
    return [];
  }
};

const getTestCategory = (test) => {
  const columnHeader = 'AD'; //"Ebenen 1";

  switch (test[columnHeader]) {
    case 'Basiskompetenzen Lesen und Rechtschreiben':
      return categories.ReadingOrWriting;

    case 'Lesen':
      return categories.Reading;

    case 'Rechtschreiben':
      return categories.Writing;

    case 'Mathematik':
    case 'Basiskompetenzen Rechnen':
      return categories.Math;

    default:
      return null;
  }
};

const filterTestsByCategory = (inputs, category) => {
  const tests = [];

  // Get result based on category
  let result;
  switch (category) {
    case categories.Reading:
      result = inputs.screeningResultReading?.result;
      break;
    case categories.Writing:
      result = inputs.screeningResultWriting?.result;
      break;
    case categories.Math:
      result = inputs.screeningResultCounting?.result;
      break;
    default:
      return []; // Return empty array if category is not recognized
  }

  // Skip adding tests if the condition to skip is true
  if (result === diagnosticResults.Yellow_NoDiagnosticDesired || result === diagnosticResults.Green) {
    return [];
  }

  // Add tests if the condition to suggest tests is true
  if (result === diagnosticResults.Blue || result === diagnosticResults.Yellow || !!result === true) {
    tests.push(...londiSubtests.filter((x) => {
      const testCategory = getTestCategory(x);
      return testCategory === category || (category !== categories.Math && testCategory === categories.ReadingOrWriting);
    }));
  }

  return tests;
};


const getTestType = (testItem) => {
  if (testItem) {
    const single = testItem['AU']; // "Einzeltestung";
    const group = testItem['AV']; // "Gruppentestung";

    if (single && group) {
      return 'Einzel- und Gruppentestung';
    } else if (single) {
      return 'Einzeltestung';
    } else if (group) {
      return 'Gruppentestung';
    }
  } else {
    return '';
  }
};

const getTestExecution = (testItem) => {
  if (testItem) {
    switch (testItem['BB']) {
      case 0:
        return 'ausschließlich Paper-Pencil-Verfahren';
      case 1:
        return 'digital und Paper-Pencil-Verfahren';
      case 2:
        return 'ausschließlich digital';
      default:
        return '';
    }
  } else {
    return '';
  }
};

export const getSuggestedTests = (
  inputs,
  filterByGrade = true,
  filterByTime = true,
  groupAllTests = false,
  testGroups = ['A', 'B', 'C', 'D', 'E']
) => {
  //convert the inputs to the expected format, until the UI is updated

  const inputCategories = [
    inputs.screeningResultCounting?.category,
    inputs.screeningResultReading?.category,
    inputs.screeningResultWriting?.category,
  ].filter((x) => x);
  /*
  if (
    inputCategories.includes(categories.Reading) ||
    inputCategories.includes(categories.Writing)
  ) {
    inputCategories.push(categories.ReadingOrWriting);
  }*/

  const gradeHeader = getGradeHeaderFromNumber(inputs.schoolGrade); // "Klasse " + inputs.schoolGrade;
  const testTimeFilter = getTimeOfTestHeaderFromNumber(inputs.schoolGrade); ////`Normen ${inputs.schoolGrade}. Klasse (1=Anfang; 2=Mitte; 3=Ende)`;
  const groupedResults = [];
  inputCategories.forEach((category) => {
    //STEP 1 - filter tests by category
    const suggestedTests = [];
    let filteredTests = filterTestsByCategory(inputs, category);

    //STEP 2 - filter by grade
    if (filterByGrade) {
      filteredTests = filteredTests.filter((x) => x[gradeHeader] === 'ja');
    }

    //STEP 3 - filter by test time in school year
    if (filterByTime) {
      const testsByExactTime = filteredTests.filter((test) => {
        const values = getIntArray(test[testTimeFilter]);
        return values && values.includes(inputs.testTime);
      });
      suggestedTests.push(...testsByExactTime);

      //If NO tests available -> show tests with next lower number
      if (inputs.testTime !== testTimes.SchoolYearBegin && testsByExactTime.length === 0) {
        //get the groups of the exact time tests
        const testGroupsExactTime = testsByExactTime.map((x) => x[idHeader]);

        //find tests that are in different groups that we might need to add too
        const testsByAlternativeTime = filteredTests
          .filter((test) => !testGroupsExactTime.includes(test[idHeader])) //all other groups
          .filter((test) => {
            return (
              (inputs.testTime === testTimes.SchoolYearMiddle && test[testTimeFilter]?.toString().includes('1')) || //2 or 1 - but 2 should have been already selected in testsByExactTime
              (inputs.testTime === testTimes.SchoolYearEnd && test[testTimeFilter]?.toString().includes('2'))
            ); //3 or 2 - but 3 should have been already selected in testsByExactTime
          });
        suggestedTests.push(...testsByAlternativeTime);
      }
    } else {
      suggestedTests.push(...filteredTests);
    }

    //STEP 4 - add the competence levels, if output has more than one test with same id (column A)
    const finalResults = [];
    suggestedTests.forEach((test) => {
      addTestToFinalResults(finalResults, test, inputs);
    });

    const categoryResults = {
      category,
      allTests: finalResults,
      testsA: [],
      testsB: [],
      testsC: [],
      testsD: [],
      testsE: [],
    };

    orderTestResultsByGroups(categoryResults, groupAllTests, testGroups);

    groupedResults.push(categoryResults);
  });

  return groupedResults;
};
const getSortedTestsList = (testsList) => {
  return [...testsList].sort((a, b) => a.name.localeCompare(b.name));
};

const addTestsToListAndSort = (testsList, testsToAdd) => {
  testsList.push(...getSortedTestsList(testsToAdd));
};

const addFilteredTests = (testsGroup, levelsFilter) => {
  //sort by reliability (0 = first)
  const catRealiableCounts = _.uniq(levelsFilter.map((x) => x.categoryReliableCount)).sort();

  //add all tests sorted by maxCatRealiableCount
  for (const count of catRealiableCounts) {
    addTestsToListAndSort(
      testsGroup,
      levelsFilter.filter((x) => x.categoryReliableCount === count)
    );
  }
};

const updateRemainingTests = (allTests, category) => {
  //remove by id
  const idsToRemove = [...category.testsA, ...category.testsB, ...category.testsC, ...category.testsD, ...category.testsE].map((x) => x.id);
  return allTests.filter((x) => !idsToRemove.includes(x.id));
};

export const getTestFields = (testId, category) => {
  console.log('getTestFields', testId);
  const categoriesToFilter = [category];
  if (category === categories.Reading || category === categories.Writing) {
    categoriesToFilter.push(categories.ReadingOrWriting);
  }
  const FIELD_TITLE_COLUMN = 'AG';
  const matchingSubtests = londiSubtests.filter((x) => x[idHeader] === testId);
  const matchingTestCategory = matchingSubtests.filter((x) => categoriesToFilter.includes(getTestCategory(x)));
  const fields = matchingTestCategory.map((x) => {
    return {
      competenceLevel: x[TAB_HEADER_SUBTESTS_COMPETENCE_FILTER],
      title: x[FIELD_TITLE_COLUMN],
    };
  });

  //  }`Kompetenzstufe ${x[TAB_HEADER_SUBTESTS_COMPETENCE_FILTER]} ${x[FIELD_TITLE_COLUMN]}`);

  return fields;
};

const orderTestResultsByGroups = (categoryResults, groupAllTests, testGroups) => {
  let allTests = categoryResults.allTests;

  //priority a (group 1)
  let levelsFilter = allTests.filter((x) => x.competenceLevels.length === 4);
  addFilteredTests(categoryResults.testsA, levelsFilter);
  allTests = updateRemainingTests(allTests, categoryResults);

  //priority b (group 1)
  levelsFilter = allTests.filter(
    (
      x //1,2,3 or 1,3,4
    ) =>
      x.competenceLevels.length === 3 &&
      ((x.competenceLevels.includes(1) && x.competenceLevels.includes(2) && x.competenceLevels.includes(3)) ||
        (x.competenceLevels.includes(1) && x.competenceLevels.includes(3) && x.competenceLevels.includes(4)))
  );
  addFilteredTests(categoryResults.testsA, levelsFilter);
  allTests = updateRemainingTests(allTests, categoryResults);

  //priority c (group 1)
  levelsFilter = allTests.filter((x) => x.competenceLevels.length === 2 && x.competenceLevels.includes(1) && x.competenceLevels.includes(3));
  addFilteredTests(categoryResults.testsA, levelsFilter);
  allTests = updateRemainingTests(allTests, categoryResults);

  //priority d1 (group 2) - 1,2,4
  levelsFilter = allTests.filter(
    (x) => x.competenceLevels.length === 3 && x.competenceLevels.includes(1) && x.competenceLevels.includes(2) && x.competenceLevels.includes(4)
  );
  categoryResults.testsB.push(...levelsFilter);
  allTests = updateRemainingTests(allTests, categoryResults);

  //priority d1 (group 2) - 2,3,4
  levelsFilter = allTests.filter(
    (x) => x.competenceLevels.length === 3 && x.competenceLevels.includes(2) && x.competenceLevels.includes(3) && x.competenceLevels.includes(4)
  );
  categoryResults.testsB.push(...levelsFilter);
  allTests = updateRemainingTests(allTests, categoryResults);

  //priority d2 (group 2)
  levelsFilter = allTests.filter((x) => x.competenceLevels.length === 2);
  //1,2
  addTestsToListAndSort(
    categoryResults.testsB,
    levelsFilter.filter((x) => x.competenceLevels.includes(1) && x.competenceLevels.includes(2))
  );
  //1,4
  addTestsToListAndSort(
    categoryResults.testsB,
    levelsFilter.filter((x) => x.competenceLevels.includes(1) && x.competenceLevels.includes(4))
  );
  //2,3
  addTestsToListAndSort(
    categoryResults.testsB,
    levelsFilter.filter((x) => x.competenceLevels.includes(2) && x.competenceLevels.includes(3))
  );
  //3,4
  addTestsToListAndSort(
    categoryResults.testsB,
    levelsFilter.filter((x) => x.competenceLevels.includes(3) && x.competenceLevels.includes(4))
  );
  allTests = updateRemainingTests(allTests, categoryResults);

  //priority d3 (group 2)
  levelsFilter = allTests.filter((x) => x.competenceLevels.length === 1);
  addTestsToListAndSort(
    categoryResults.testsB,
    levelsFilter.filter((x) => x.competenceLevels.includes(1))
  );
  addTestsToListAndSort(
    categoryResults.testsB,
    levelsFilter.filter((x) => x.competenceLevels.includes(3))
  );
  allTests = updateRemainingTests(allTests, categoryResults);
  //validate that we have at least KS 1 and 3 for the category, otherwise we should remove them from the results
  let ks1 = categoryResults.testsB.find((x) => x.competenceLevels.includes(1));
  let ks3 = categoryResults.testsB.find((x) => x.competenceLevels.includes(3));
  if (!(ks1 && ks3)) {
    //return group2 tests to allTests
    allTests.push(...categoryResults.testsB);
    //clear results
    categoryResults.testsB = [];
  }

  //priority e (group 3)
  levelsFilter = allTests.filter((x) => x.competenceLevels.length === 2);
  addTestsToListAndSort(
    categoryResults.testsC,
    levelsFilter.filter((x) => x.competenceLevels.includes(2) && x.competenceLevels.includes(3))
  );
  allTests = updateRemainingTests(allTests, categoryResults);

  //priority f1 (group 4)
  levelsFilter = allTests.filter(
    (x) => x.competenceLevels.length === 3 && x.competenceLevels.includes(1) && x.competenceLevels.includes(2) && x.competenceLevels.includes(4)
  );
  addTestsToListAndSort(categoryResults.testsD, levelsFilter);
  allTests = updateRemainingTests(allTests, categoryResults);

  //priority f2 (group 4)
  levelsFilter = allTests.filter((x) => x.competenceLevels.length === 2);
  addTestsToListAndSort(
    categoryResults.testsD,
    levelsFilter.filter((x) => x.competenceLevels.includes(1) && x.competenceLevels.includes(2))
  );
  addTestsToListAndSort(
    categoryResults.testsD,
    levelsFilter.filter((x) => x.competenceLevels.includes(2) && x.competenceLevels.includes(4))
  );
  addTestsToListAndSort(
    categoryResults.testsD,
    levelsFilter.filter((x) => x.competenceLevels.includes(3) && x.competenceLevels.includes(4))
  );
  allTests = updateRemainingTests(allTests, categoryResults);

  //priority f3 (group 4)
  levelsFilter = allTests.filter((x) => x.competenceLevels.length === 1);
  addTestsToListAndSort(
    categoryResults.testsD,
    levelsFilter.filter((x) => x.competenceLevels.includes(2) || x.competenceLevels.includes(3))
  );
  allTests = updateRemainingTests(allTests, categoryResults);

  //validate that we have at least KS 1 and 3 for the category, otherwise we should remove them from the results
  let ks2 = categoryResults.testsD.find((x) => x.competenceLevels.includes(2));
  ks3 = categoryResults.testsD.find((x) => x.competenceLevels.includes(3));
  if (!(ks2 && ks3)) {
    //return group4 tests to allTests
    allTests.push(...categoryResults.testsD);
    //clear results
    categoryResults.testsD = [];
  }

  //priority g (group 5)
  categoryResults.testsE.push(...allTests);
  //delete category.allTests;

  if (groupAllTests) {
    categoryResults.allTests = [];
    for (const group of testGroups) {
      // ["A", "B", "C", "D", "E"]) {
      categoryResults[`tests${group}`].forEach((x) => {
        x.group = group;
        categoryResults.allTests.push(x);
      });
    }
    categoryResults.allTests = categoryResults.allTests.sort((a, b) => a.shortName.localeCompare(b.shortName));
  }
};

function addTestToFinalResults(finalResults, test, inputs) {
  let existingTest = finalResults.find((x) => x.id === test[idHeader]);
  if (!existingTest) {
    //testItem is the parent of all subtests
    const testItem = londiData[TAB_HEADER_TESTS].find((x) => x[idHeader] === test[idHeader]);
    existingTest = {
      id: test[idHeader],
      name: testItem ? testItem['B'] : 'ERROR - Test not found',
      shortName: testItem ? testItem['C'] : test[idHeader],
      description: testItem ? testItem['H'] : '',
      author: testItem ? testItem['D'] : '',
      year: testItem ? testItem['E'] : '',
      publisher: testItem ? testItem['F'] : '',
      type: getTestType(testItem),
      processingTimeSingle: testItem ? testItem['AY'] : null,
      processingTimeGroup: testItem ? testItem['AZ'] : null,
      standardizationYear: testItem ? testItem['CB'] : '',
      execution: getTestExecution(testItem),
      categoryReliable: test['X'],
      competenceLevels: [],
      //categoryReliableCount: [],
      category: getTestCategory(test, inputs),
      categoryReliableCount: 0,
      sampleSize: testItem['CA'],
      quality: parseInt(testItem['CH']) + 1,
      testItem,
    };
    finalResults.push(existingTest);
  }

  existingTest.dazFairness = Math.max(existingTest.dazFairness || 0, getDAZFairness(test));
  if (test['X'] === 0 || test['X'] === 99) {
    existingTest.categoryReliableCount += 1;
  }

  if (test[TAB_HEADER_SUBTESTS_COMPETENCE_FILTER] && !existingTest.competenceLevels.includes(test[TAB_HEADER_SUBTESTS_COMPETENCE_FILTER])) {
    existingTest.competenceLevels.push(test[TAB_HEADER_SUBTESTS_COMPETENCE_FILTER]);
  }
}

import { useContext, useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DiagnosticContext, useOutsideClick, useSessionStorage } from '../../helpers/functions';
import { classes, diagnosticResults, getClass, getDiagnosticResultText, getTestTime, testTimes } from '../../services/londi-constants';
import Tabs from '../Tabs';
import Container from '../../elements/container';
import PageHeading from '../../elements/page-heading';
import Checkbox from '../../elements/checkbox';
import Select from '../select';

import Alert from '../../elements/alert';
import Button from '../../elements/button';
import Dialog from '../../elements/dialog';

function ScreeningResults() {
  const [selfAssessment, setSelfAssessment] = useSessionStorage('selfAssessment', false);
  const [checkMark, setCheckMark] = useSessionStorage('checkmark', false);
  const { state, dispatch } = useContext(DiagnosticContext);
  const [importantNote, setImportantNote] = useState(false);
  const [secondImportantNote, setSecondImportantNote] = useState(false);
  const [checkedReading, setCheckedReading] = useSessionStorage('checkboxesReading', false);
  const [disableReading, setDisableReading] = useSessionStorage('checkboxesDisableReading', false);
  const [checkedWriting, setCheckedWriting] = useSessionStorage('checkboxesWriting', false);
  const [disableWriting, setDisableWriting] = useSessionStorage('checkboxesDisableWriting', false);
  const [checkedCounting, setCheckedCounting] = useSessionStorage('checkboxesCounting', false);
  const [disableCounting, setDisableCounting] = useSessionStorage('checkboxesDisableCounting', false);
  const [gradeError, setGradeError] = useState(false);
  const [MandatoryError, setMandatoryError] = useState(false);
  const [germanLanguageError, setGermanLanguageError] = useState(false);
  const [testTimeError, setTestTimeError] = useState(false);
  const [readingError, setReadingError] = useState(false);
  const [writingError, setWritingError] = useState(false);
  const [countingError, setCountingError] = useState(false);
  const navigate = useNavigate();

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);
  const handleOnChange = (e) => {
    if (e.target.checked) {
      dispatch({ type: 'screenResultsEstimated', payload: true });
      setCheckMark(true);
      setSelfAssessment(true);
      setImportantNote(false);
      setSecondImportantNote(false);
    } else {
      dispatch({ type: 'screenResultsEstimated', payload: false });
      setSelfAssessment(false);
      setCheckMark(false);
    }
  };
  const onSubmit = () => {
    if (state.schoolGrade === undefined) {
      setGradeError(true);
    }
    if (state.testTime === undefined) {
      setTestTimeError(true);
    }
    if (state.germanSecondaryLanguage === undefined) {
      setGermanLanguageError(true);
    }
    if (state.screeningResultReading === undefined && !disableReading && checkedReading) {
      setReadingError(true);
    }
    if (state.screeningResultWriting === undefined && !disableWriting && checkedWriting) {
      setWritingError(true);
    }
    if (state.screeningResultCounting === undefined && !disableCounting && checkedCounting) {
      setCountingError(true);
    }

    if (
      state.screeningResultReading !== undefined ||
      state.screeningResultWriting !== undefined ||
      state.screeningResultCounting !== undefined
    ) {
      setCountingError(false);
      setWritingError(false);
      setReadingError(false);
    }
    if (!checkedReading && !checkedWriting && !checkedCounting) {
      setMandatoryError(true);
      return;
    } else {
      setMandatoryError(false);
    }
    if (
      state.schoolGrade !== undefined &&
      state.testTime !== undefined &&
      state.germanSecondaryLanguage !== undefined &&
      (state.screeningResultReading !== undefined || state.screeningResultWriting !== undefined || state.screeningResultCounting !== undefined)
    ) {
      navigate('/diagnostics-recomendations');
    }
  };

  const handleToggleReading = (e) => {
    if (!e.target.checked) {
      setDisableReading(true);
      setCheckedReading(false);
      dispatch({ type: 'clearReading' });
      if (selfAssessment) {
        dispatch({ type: 'reading', payload: false });
      }
    } else {
      setMandatoryError(false);
      setCheckedReading(true);
      setDisableReading(false);
      if (selfAssessment) {
        dispatch({ type: 'reading', payload: true });
        if (state?.germanSecondaryLanguage) setImportantNote(true);
      }
    }
  };

  const handleSelectReadingOption = (o) => {
    setMandatoryError(false);
    dispatch({ type: 'reading', payload: o.id });
    setCheckedReading(true);
    setReadingError(false);
    if (
      state?.germanSecondaryLanguage &&
      (getDiagnosticResultText(o.id) === getDiagnosticResultText(diagnosticResults.Blue) ||
        getDiagnosticResultText(o.id) === getDiagnosticResultText(diagnosticResults.Yellow))
    ) {
      setImportantNote(true);
    }
  };

  const handleToggleWriting = (e) => {
    if (!e.target.checked) {
      setDisableWriting(true);
      setCheckedWriting(false);
      dispatch({ type: 'clearWriting' });

      if (selfAssessment) dispatch({ type: 'writing', payload: false });
    } else {
      setMandatoryError(false);
      setCheckedWriting(true);
      setDisableWriting(false);

      if (selfAssessment) {
        dispatch({ type: 'writing', payload: true });
        if (state?.germanSecondaryLanguage) setImportantNote(true);
      }
    }
  };

  const handleSelectWritingOption = (o) => {
    setMandatoryError(false);
    dispatch({ type: 'writing', payload: o.id });
    setCheckedWriting(true);
    setWritingError(false);
    if (
      state?.germanSecondaryLanguage &&
      (getDiagnosticResultText(o.id) === getDiagnosticResultText(diagnosticResults.Blue) ||
        getDiagnosticResultText(o.id) === getDiagnosticResultText(diagnosticResults.Yellow))
    ) {
      setImportantNote(true);
    }
  };

  const handleToggleCounting = (e) => {
    if (!e.target.checked) {
      setDisableCounting(true);
      setCheckedCounting(false);
      dispatch({ type: 'clearCounting' });

      if (selfAssessment) dispatch({ type: 'counting', payload: false });
    } else {
      setMandatoryError(false);
      setCheckedCounting(true);
      setDisableCounting(false);

      if (selfAssessment) {
        dispatch({ type: 'counting', payload: true });
        if (state?.germanSecondaryLanguage) setSecondImportantNote(true);
      }
    }
  };

  const handleSelectCountingOption = (o) => {
    setMandatoryError(false);
    dispatch({ type: 'counting', payload: o.id });
    setCheckedCounting(true);
    setCountingError(false);
    if (
      state?.germanSecondaryLanguage &&
      (getDiagnosticResultText(o.id) === getDiagnosticResultText(diagnosticResults.Blue) ||
        getDiagnosticResultText(o.id) === getDiagnosticResultText(diagnosticResults.Yellow))
    ) {
      setImportantNote(true);
    }
  };

  return (
    <>
      <Tabs />
      <Container className="flex flex-col items-center">
        <PageHeading stretch title="Screeningergebnisse" />
        <div className="max-w-6xl py-6 space-y-4 -mt-6">
          <p className="text-lg leading-8 text-gray-600">
            Bitte wählen Sie aus, für welche Bereiche Sie eine Diagnostikempfehlung wünschen und geben Sie die Ergebnisse des LONDI-Sreenings
            ein. Hat das Kind im Screening ein leicht unterdurchschnittliches Ergebnis erzielt (gelb), können Sie unter Hinzunahme weiterer
            Überlegungen entscheiden, ob Sie eine weiterführende Diagnostik wünschen. Alternativ können Sie als Fachkraft auch selbst einschätzen
            in welchen Bereichen Sie Lernschwierigkeiten vermuten.
          </p>
        </div>
        <div className="max-w-6xl py-6 w-full grid grid-cols-1 gap-12 lg:gap-28">
          <div className="w-full space-y-4">
            <h3 className="text-base font-semibold">Angaben zum Kind</h3>
            <div>
              <Select
                label="Schulklasse"
                onToggle={() => dispatch({ type: 'classOptions' })}
                selected={makeOption(getClass)(state?.schoolGrade)}
                setSelected={(o) => {
                  dispatch({ type: 'schoolGrade', payload: o.id });
                  setGradeError(false);
                }}
                options={Object.values(classes).map(makeOption(getClass))}
              />
              {gradeError && <p className="mt-2 text-sm text-red-600">Feld erforderlich</p>}
            </div>
            <div>
              <Select
                label="Testzeitpunkt im Schuljahr"
                onToggle={() => dispatch({ type: 'testTimeOptions' })}
                selected={makeOption(getTestTime)(state?.testTime)}
                setSelected={(o) => {
                  dispatch({ type: 'testTime', payload: o.id });
                  setTestTimeError(false);
                }}
                options={Object.values(testTimes).map(makeOption(getTestTime))}
              />
              {testTimeError && <p className="mt-2 text-sm text-red-600">Feld erforderlich</p>}
            </div>
            <div>
              <Select
                label="Hat das Kind Deutsch erst nach dem 3. Geburtstag gelernt?"
                onToggle={() => dispatch({ type: 'germanNativeOptions' })}
                selected={
                  Object.hasOwn(state, 'germanSecondaryLanguage')
                    ? {
                        id: Number(state.germanSecondaryLanguage),
                        name: state.germanSecondaryLanguage ? 'Ja' : 'Nein',
                      }
                    : undefined
                }
                setSelected={(o) => {
                  dispatch({ type: 'germanSecondaryLanguage', payload: Boolean(o.id) });
                  setGermanLanguageError(false);
                }}
                options={[
                  { id: 0, name: 'Nein' },
                  { id: 1, name: 'Ja' },
                ]}
              />
              {germanLanguageError && <p className="mt-2 text-sm text-red-600">Feld erforderlich</p>}
            </div>
          </div>
          <div className="w-full space-y-4">
            <div className="space-y-4">
              <Checkbox
                size="lg"
                name="condition"
                checked={checkMark}
                onChange={(e) => handleOnChange(e)}
                label="Ich habe keine Screeningergebnisse, aber ich bin eine Fachkraft, die in der Lage ist, die Lernschwierigkeiten des Kindes auch ohne Screening selbst einzuschätzen."
              />
              {selfAssessment && (
                <Alert type="info">Bitte geben Sie an, ob Sie in den folgenden Bereichen besondere Lernschwierigkeiten vermuten</Alert>
              )}
            </div>
            <h3 className="text-base font-semibold">{selfAssessment ? 'Selbsteinschätzung' : 'Screeningergebnisse'}</h3>
            {MandatoryError && <Alert type="error">Angabe in mindestens einem Bereich erforderlich</Alert>}
            <div>
              <Checkbox size="sm" name="reading" checked={checkedReading} onChange={handleToggleReading} label="Lesen" />
              {!selfAssessment && (
                <div className={disableReading ? 'pointer-events-none opacity-25' : ''}>
                  <Select
                    onToggle={() => dispatch({ type: 'readingOptions' })}
                    selected={convertResToOption(state?.screeningResultReading?.result, (v) => getDiagnosticResultText(v).split(' - '))}
                    setSelected={handleSelectReadingOption}
                    options={Object.values(diagnosticResults).map((v) => convertResToOption(v, (v) => getDiagnosticResultText(v).split(' - ')))}
                  />
                  {readingError && <p className="mt-2 text-sm text-red-600">Feld erforderlich</p>}
                </div>
              )}
            </div>
            <div>
              <Checkbox size="sm" name="writing" checked={checkedWriting} onChange={handleToggleWriting} label="Schreiben" />
              {!selfAssessment && (
                <div className={disableWriting ? 'pointer-events-none opacity-25' : ''}>
                  <Select
                    onToggle={() => dispatch({ type: 'writingOptions' })}
                    selected={convertResToOption(state?.screeningResultWriting?.result, (v) => getDiagnosticResultText(v).split(' - '))}
                    setSelected={handleSelectWritingOption}
                    options={Object.values(diagnosticResults).map((v) => convertResToOption(v, (v) => getDiagnosticResultText(v).split(' - ')))}
                  />
                  {writingError && <p className="mt-2 text-sm text-red-600">Feld erforderlich</p>}
                </div>
              )}
            </div>
            <div>
              <Checkbox size="sm" name="counting" checked={checkedCounting} onChange={handleToggleCounting} label="Rechnen" />
              {!selfAssessment && (
                <div className={disableCounting ? 'pointer-events-none opacity-25' : ''}>
                  <Select
                    onClick={() => dispatch({ type: 'countingOptions' })}
                    selected={convertResToOption(state?.screeningResultCounting?.result, (v) => getDiagnosticResultText(v).split(' - '))}
                    setSelected={handleSelectCountingOption}
                    options={Object.values(diagnosticResults).map((v) => convertResToOption(v, (v) => getDiagnosticResultText(v).split(' - ')))}
                  />
                  {countingError && <p className="mt-2 text-sm text-red-600">Feld erforderlich</p>}
                </div>
              )}
            </div>
            <Dialog
              title="Wichtiger Hinweis"
              type="error"
              open={importantNote}
              setOpen={setImportantNote}
              description="Dieses Kind hat Deutsch erst nach dem 3. Geburtstag gelernt und zeigt Auffälligkeiten im Lesen und/oder Rechtschreiben. Die Schwierigkeiten können sowohl auf möglicherweise bestehende Sprachprobleme im Deutschen zurückzuführen sein, als auch auf eine Lese- und/oder Rechtschreibstörung. Zusätzlich zu einer ausführlichen Diagnostik der Lese- und Rechtschreibfähigkeiten ist eine Diagnostik der Sprachfähigkeiten durch eine Fachperson erforderlich, um eine adäquate Förderung bzw. Therapie einleiten zu können."
            >
              <div className="mt-5 sm:mt-4 md:hidden">
                <Button size="sm" className="w-full" onClick={() => setImportantNote(false)}>
                  verstanden
                </Button>
              </div>
            </Dialog>
            <Dialog
              title="Wichtiger Hinweis"
              type="error"
              open={secondImportantNote}
              setOpen={setSecondImportantNote}
              description="Dieses Kind hat Deutsch erst nach dem 3. Geburtstag gelernt und zeigt Auffälligkeiten im Bereich Mathematik. Wenn die Auffälligkeiten nur im Bereich der Mathematik vorliegen, ist eine Diagnostik der Sprachfähigkeiten für die weitere Diagnostik und Förderung der Mathematikschwierigkeiten nicht notwendig."
            >
              <div className="mt-5 sm:mt-4 md:hidden">
                <Button size="sm" className="w-full" onClick={() => setSecondImportantNote(false)}>
                  verstanden
                </Button>
              </div>
            </Dialog>
          </div>
        </div>
        <Button size="sm" onClick={onSubmit} className="my-12">
          Weiter
        </Button>
      </Container>
    </>
  );
}

function makeOption(handler) {
  return (v) => {
    if (v === undefined) return undefined;
    return { id: v, name: handler(v) };
  };
}

function convertResToOption(result, handler) {
  if (result === undefined) return undefined;
  const [name, description] = handler(result);
  return { id: result, name, description };
}

export default ScreeningResults;

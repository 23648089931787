import OpeningPage from './components/OpeningPage';
import './index.css';

const App = () => {
  return (
    <div className="font-montserrat m-auto">
      <OpeningPage />
    </div>
  );
};

export default App;

import { useState } from 'react';
import FundingRecommendationDetailView from '../FundingRecommendationDetailView';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

function FundingRecommendationsDetails({ noPopup, res, index }) {
  const [showPopup, setShowPopup] = useState(false);
  const linkShort = res.link.substring(0, 8);
  const link = linkShort === 'https://' ? res?.link.slice(8) : res.link;

  return (
    <>
      <div className="py-4 px-2 gap-2 text-sm flex flex-col border border-gray-400 bg-mainBlue bg-opacity-5 rounded">
        <div className="flex justify-between items-start gap-6 text-mainBlue">
          <p className="font-bold self-center text-pretty">
            <strong>{index}</strong>: {res.title}
          </p>
          {!noPopup && (
            <button className="p-1" onClick={() => setShowPopup(true)}>
              <ExclamationCircleIcon className="w-6 h-6" />
            </button>
          )}
        </div>
        <div className="mr-8 gap-1 flex flex-col font-light text-mainBlue">
          {res.subtitle && <div>{res?.subtitle}</div>}
          <a href={res.link} target="_blank" className="break-words underline hover:text-opacity-80" rel="noreferrer">
            {link}
          </a>
        </div>
        <div className="mr-8 flex flex-col gap-1">
          {res.authors && (
            <ul className="flex items-center flex-wrap gap-1 font-medium">
              {res.authors.map((el) => (
                <li>{el},</li>
              ))}
            </ul>
          )}
          {res.year && <p>{res?.year},</p>}
          {res.publisher && <p>{res?.publisher}</p>}
        </div>
        <div className="mr-8 text-xs flex flex-col gap-1">
          {!!res.classes && !!res.classes.length && (
            <div className="flex">
              Dieses Förderprogramm ist laut Herausgeber für folgende Jahrgangsstufen geeignet:
              {res.classes.map((el, i) => (
                <> {i === res.classes.length - 1 ? el : `${el},`}</>
              ))}
            </div>
          )}
          {!!res.classes && !res.classes.length && <p>Der Herausgeber macht keine genaueren Angaben zu den empfohlenden Jahrgangsstufen.</p>}
        </div>
      </div>
      <FundingRecommendationDetailView item={res} open={showPopup} setOpen={setShowPopup} />
    </>
  );
}

export default FundingRecommendationsDetails;

import { categories, education } from './londi-constants.js';
import _ from 'lodash';

export const fundingData = require('../assets/LONDI Förderalgorithmus aktuell.json').Foerderalgorithmus_MC_korrigier;

const COLUMN_HEADER_ID = 'C';
const NO_VALUE = -99; //Number.MIN_SAFE_INTEGER;
const WORST_RANKING = 100; //Number.MAX_SAFE_INTEGER;
/*
this method calculates the child inputs + test results and returns a list of funding options
*/
// eslint-disable-next-line no-unused-vars
const dummy = {
  schoolGrade: 1,
  testTime: 2, //see enum testTimes
  germanSecondaryLanguage: true,
  screenResultsEstimated: false, //false to use blue/yellow/green results, or true if using true/false results (1 or 0)
  screeningResults: [
    { category: categories.Reading, result: 1 }, //result value is either from diagnosticResults or (1 / 0)
    { category: categories.Writing, result: 2 },
    { category: categories.Math, result: 3 },
  ],
  selectedTests: [
    {
      category: categories.Reading,
      testId: 61,
      results: [
        { competenceLevel: 1, title: 'Mengenerfassung', result: 20 },
        { competenceLevel: 1, title: 'Zahlerfassung ', result: 20 },
        { competenceLevel: 1, title: 'Mengenerfassung', result: 20 },
        { competenceLevel: 3, title: 'Addition/Subtraktion', result: 20 },
      ],
    },
    {
      category: categories.Math,
      testId: 29,
      results: [{ competenceLevel: 1, title: 'Summe DEMAT 5+', result: 20 }],
    },
  ],
};

export const getFundingMatching = (data, filterQuery) => {
  console.log('getFundingMatching', data);
  filterQuery = _.omitBy(filterQuery, (v) => v === null);
  filterQuery = _.mapValues(filterQuery, (v) => ['nein', 'ja'][v]);
  const filteredFundingData = _.filter(fundingData, filterQuery);
  console.log({ filteredFundingData, fundingData, filterQuery });
  const categoriesResult = [];
  const results = [];
  //we don't required test id - in case the results are without specific tests
  if (data.screeningResults.find((x) => x.results?.length > 0 && x.category === categories.Reading)) {
    categoriesResult.push(categories.Reading);
  }
  if (data.screeningResults.find((x) => x.results?.length > 0 && x.category === categories.Writing)) {
    categoriesResult.push(categories.Writing);
  }
  if (data.screeningResults.find((x) => x.results?.length > 0 && x.category === categories.Math)) {
    categoriesResult.push(categories.Math);
  }

  //convert values to int
  const competenceLevelPerCategory = {};
  data.screeningResults.forEach((x) => {
    x.results.forEach((y) => {
      if (!competenceLevelPerCategory[x.category]) {
        competenceLevelPerCategory[x.category] = [];
      }
      if (competenceLevelPerCategory[x.category].indexOf(y.competenceLevel) < 0) {
        competenceLevelPerCategory[x.category].push(y.competenceLevel);
      }
      y.result = parseInt(y.result);

      //fix category for selected tests
      if (!y.category && x.category) {
        y.category = x.category;
      }
    });
  });

  //if some KS are missing, add them with -1 value
  Object.keys(competenceLevelPerCategory).forEach((category) => {
    const missingCompetenceLevels = _.difference([1, 2, 3, 4], competenceLevelPerCategory[category]);

    missingCompetenceLevels.forEach((level) => {
      data.screeningResults.push({
        category: parseInt(category),
        results: [
          {
            category,
            competenceLevel: level,
            result: -1,
          },
        ],
      });
    });
  });

  categoriesResult.forEach((category) => {
    //calculate the profile for each category
    //const fundingProfiles = getFundingProfiles(data.selectedTests);

    //filter the funding data
    let filterEducationColumn = '';
    switch (data.education) {
      case education.Elementary:
        filterEducationColumn = 'CN';
        break;
      case education.Middle:
        filterEducationColumn = 'CO';
        break;
      case education.High:
        filterEducationColumn = 'CP';
        break;
      default:
        throw new Error('invalid education area');
    }

    //filter by areas
    const categoryFilterColumn = getFilterColumnPerCategory(category);

    const educationFilter = filteredFundingData.filter((x) => x[filterEducationColumn] === 'ja');

    const filteredFundingDataPerCategory = [];
    filteredFundingDataPerCategory.push(
      ...educationFilter.filter(
        (x) => x[categoryFilterColumn] === 'ja' && !_.some(filteredFundingDataPerCategory, (y) => y[COLUMN_HEADER_ID] === x[COLUMN_HEADER_ID]) //don't add duplicates
      )
    );

    //rank the data by profile calculation
    const rankedFundingDataPerCategory = rankResultsByAbsoluteDiff(data, filteredFundingDataPerCategory, category);

    //format the results
    const catResults = rankedFundingDataPerCategory.map((x) => {
      return {
        title: x['G'],
        subtitle: x['H'],
        publisher: x['X'],
        year: x['V'],
        authors: getAuthors(x),
        link: x['Z'],
        classes: getTargetClassesForFunding(x),
        absoluteDiff: x.absoluteDiff,
        rankingElements: x.rankingElements,
        rankingAverage: x.rankingAverage,
        rankingScore: Object.values(x.rankingScore).join(','),
        finalScore: x.finalScore,
        averageRankingScore: x.averageRankingScore,
        profiles: x.profiles,
        shortName: x['D'],
        testItem: x,
      };
    });

    results.push({ category, results: catResults });
  });

  return results;
};

const parseFloatGlobal = (value) => {
  return parseFloat(value.replace(',', '.'));
};

const getFilterColumnPerCategory = (category) => {
  switch (category) {
    case categories.Reading:
      return 'BZ';
    case categories.Writing:
      return 'CA';
    case categories.Math:
      return 'CB';
    default:
      throw new Error('invalid category');
  }
};

const getTargetClassesForFunding = (x) => {
  let classes = null;
  if (x['CO'] === 'ja' || x['CP'] === 'ja') {
    classes = [];
    if (x['CR'] === 'ja') {
      classes.push('1');
    }
    if (x['CS'] === 'ja') {
      classes.push('2');
    }
    if (x['CT'] === 'ja') {
      classes.push('3');
    }
    if (x['CU'] === 'ja') {
      classes.push('4');
    }
    if (x['CV'] === 'ja') {
      classes.push('5');
    }
    if (x['CW'] === 'ja') {
      classes.push('6');
    }
    if (x['CX'] === 'ja') {
      classes.push('>6');
    }
  }
  return classes;
};

const getAuthors = (x) => {
  const authors = [];
  for (let idx = 'I'; idx < 'U'; idx = stepOneLetter(stepOneLetter(idx))) {
    if (x[idx]?.trim().length > 0) {
      authors.push(`${x[idx]} ${x[stepOneLetter(idx)]}`);
    }
  }
  return authors;
};

const stepOneLetter = (letter) => {
  return String.fromCharCode(letter.charCodeAt(0) + 1);
};

const levelWeight = (level) => {
  switch (level) {
    case 1:
      return 4;
    case 2:
      return 3;
    case 3:
      return 2;
    case 4:
      return 1;
    default:
      throw new Error('invalid level');
  }
};

//input all tests with PRs, output: for each category,
const getFundingProfiles = (selectedTests) => {
  const fundingProfiles = {};
  selectedTests.forEach((test) => {
    const category = test.category;
    if (test.results.some((x) => x.result)) {
      if (!fundingProfiles[category]) {
        fundingProfiles[category] = { 1: {}, 2: {}, 3: {}, 4: {} };
      }
      //get the funding profile for the test, first get recodedPR
      for (let level = 1; level <= 4; level++) {
        const resultsPerLevel = test.results.filter((x) => x.competenceLevel === level && x.result > NO_VALUE);
        if (resultsPerLevel.length > 0) {
          const resultsAverage = _.meanBy(resultsPerLevel, 'result'); //find the average result for this level
          const recodedPR = recodePR(resultsAverage);
          fundingProfiles[category][level].recodedPR = recodedPR;
        }
      }
      //calculate for level 2 if missing values
      if (!fundingProfiles[category][2] || fundingProfiles[category][2]?.recodedPR === NO_VALUE) {
        const pr1and3 = fundingProfiles[category][1]?.recodedPR + fundingProfiles[category][3]?.recodedPR;
        if (pr1and3 > 0) {
          fundingProfiles[category][2].recodedPR = pr1and3 / 2;
        }
      }

      //calculate the weight
      let allWeightedPR = 0;
      for (let level = 1; level <= 4; level++) {
        const item = fundingProfiles[category][level];
        if (item.recodedPR > NO_VALUE) {
          const weight = levelWeight(level); //Gewicht Förderstufe
          const weightedPR = item.recodedPR * weight; //calc table example: F3 * C3

          item.weightedPR = weightedPR;
          allWeightedPR += weightedPR;
        } else {
          item.weightedPR = NO_VALUE;
        }
      }

      //calculate the final profile
      for (let level = 1; level <= 4; level++) {
        const item = fundingProfiles[category][level];
        item.profileScore =
          item.weightedPR > NO_VALUE
            ? item.weightedPR / allWeightedPR //calc table example: G3 / (G3+G4+G5+G6)
            : NO_VALUE;

        //if the score is -1, there should be abs diff and no ranking
      }
    }
  });
  return fundingProfiles; //{category.level.profileScore}
};

const recodePR = (pr) => {
  if (pr > 30) {
    return 0;
  }
  if (pr > 20) {
    return 1;
  }
  if (pr > 10) {
    return 2;
  }
  if (pr > 0) {
    return 3;
  } else {
    return NO_VALUE; //nicht vorhanden / no data
  }
};

const getColumnForDiff = (category, level) => {
  switch (category) {
    case categories.Reading:
      switch (level) {
        case 1:
          return 'DK';
        case 2:
          return 'DL';
        case 3:
          return 'DM';
        case 4:
          return 'DO';
        default:
          throw new Error('invalid level');
      }
    case categories.Writing:
      switch (level) {
        case 1:
          return 'DK';
        case 2:
          return 'DL';
        case 3:
          return 'DN';
        case 4:
          return 'DP';
        default:
          throw new Error('invalid level');
      }
    case categories.Math:
      switch (level) {
        case 1:
          return 'DQ';
        case 2:
          return 'DR';
        case 3:
          return 'DS';
        case 4:
          return 'DT';
        default:
          throw new Error('invalid level');
      }
    default:
      throw new Error('invalid category');
  }
};

//“Absolute Abweichung
const rankResultsByAbsoluteDiff = (inputData, filteredFundingDataPerCategory, category) => {
  const selectedTests = inputData.screeningResults.filter((x) => x.results?.length > 0 && x.results.some((y) => y.result));
  const fundingProfiles = getFundingProfiles(selectedTests);

  //add absoluteDiff for all levels to each funding
  filteredFundingDataPerCategory.forEach((funding) => {
    funding.profiles = fundingProfiles;
    if (!funding.absoluteDiff) {
      funding.absoluteDiff = { 1: 0, 2: 0, 3: 0, 4: 0 };
    }
  });

  selectedTests
    .filter((x) => x.category === category)
    .forEach((test) => {
      for (let level = 1; level <= 4; level++) {
        const resultsPerLevel = test.results.filter((x) => x.competenceLevel === level);
        if (resultsPerLevel.length > 0 && resultsPerLevel.some((x) => x.result)) {
          //} > -1)) {
          const profileScore = fundingProfiles[category][level].profileScore;

          //find the funding matching for this category and level
          const diffColumn = getColumnForDiff(category, level);
          filteredFundingDataPerCategory.forEach((funding) => {
            const fundingDiffPoints = parseFloatGlobal(funding[diffColumn]);
            if (profileScore > NO_VALUE) {
              if (fundingDiffPoints !== 0) {
                const diff = Math.abs(profileScore - fundingDiffPoints); //for example (H3 from calc table) - (DK from funding table)
                funding.absoluteDiff[level] = diff;
              } else {
                funding.absoluteDiff[level] = NO_VALUE; // WORST_RANKING;
              }
            } else {
              funding.absoluteDiff[level] = NO_VALUE;
            }
          });
        }
      }
    });

  for (let level = 1; level <= 5; level++) {
    //5 is the special ranking level
    calculateRankingScore(level, category, filteredFundingDataPerCategory, fundingProfiles);
  }

  filteredFundingDataPerCategory.forEach((fundingItem) => {
    const mainScore = parseFloatGlobal(fundingItem['DI']);
    const rankingPerKS = Object.values(fundingItem.rankingScore).filter((x) => x > NO_VALUE);

    //Mittelwert (Ranking Average)
    fundingItem.averageRankingScore = _.sum(rankingPerKS) / rankingPerKS.length; //if all KS are more than -1, we should have 5 items

    //Gesamtscore (Ranking Score) - get value and two digits
    fundingItem.finalScore = fundingItem.averageRankingScore / (mainScore + 1);
  });

  //sort by sum of ranking score, the smallest the first
  return filteredFundingDataPerCategory.sort((a, b) => a.finalScore - b.finalScore);
};

const getValueForRanking = (fundingItem, level) => {
  if (level < 5) {
    if (fundingItem.absoluteDiff[level] === NO_VALUE) {
      return WORST_RANKING;
    } else {
      return fundingItem.absoluteDiff[level];
    }
  } else {
    //special ranking
    return parseFloatGlobal(fundingItem['DU']);
  }
};

const calculateRankingScore = (level, category, allFundingItems, fundingProfiles) => {
  //for each KS, calculate the ranking for each fundingItem
  allFundingItems.forEach((funding) => {
    if (!funding.rankingScore) {
      funding.rankingScore = {};
    }
    if (level === 5 || (fundingProfiles[category] && fundingProfiles[category][level].profileScore > 0)) {
      const value = getValueForRanking(funding, level);
      if (value === WORST_RANKING || value === NO_VALUE) {
        //worst ranking
        funding.rankingScore[level] = allFundingItems.length;
        //funding.rankingScore[level] = value; //no value
      } else if (value > -1) {
        //sort by absoluteDiff of this level
        const sortedFunding = [...allFundingItems].sort((a, b) => getValueForRanking(a, level) - getValueForRanking(b, level)); //.absoluteDiff[level] - b.absoluteDiff[level]

        //check if similar values exist for other programs
        const fundingWithSimilarValues = allFundingItems.filter((x) => getValueForRanking(x, level) === getValueForRanking(funding, level)); //x.absoluteDiff[level] === funding.absoluteDiff[level]);
        if (fundingWithSimilarValues.length > 1) {
          //sum up all positions of similar items

          const allPositions = _.sum(
            fundingWithSimilarValues.map((x) => sortedFunding.findIndex((y) => y[COLUMN_HEADER_ID] === x[COLUMN_HEADER_ID]) + 1)
          );
          const score = allPositions / fundingWithSimilarValues.length;
          funding.rankingScore[level] = score;
        } else {
          //if values are not identical, calculate the ranking based on best position

          const score = sortedFunding.findIndex((x) => x[COLUMN_HEADER_ID] === funding[COLUMN_HEADER_ID]) + 1;

          funding.rankingScore[level] = score;
        }
      } else {
        console.log();
      }
    }
  });
};

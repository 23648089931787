import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import DiagnosticOverview from './components/diagnostics/DiagnosticOverview';
import { DiagnosticProvider } from './components/diagnostics/DiagnosticProvider';
import DiagnostikRecomendations from './components/diagnostics/DiagnosticRecomendations';
import InformationPage from './components/InformationPage';
import ScreeningResults from './components/diagnostics/ScreeningResults';
import PromotionTest from './components/promotion/PromotionTest';
import TestResults from './components/promotion/TestResults';
import FundingRecomendations from './components/promotion/FundingRecomendations';
import Choice from './components/Choice';
import HeaderLogo from './components/HeaderLogo';
import FundingOverview from './components/promotion/FundingOverview';
import Footer from './components/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <header className="flex sticky z-50 top-0 bg-white">
      <HeaderLogo />
    </header>
    <div className="grow w-full">
      <DiagnosticProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/information" element={<InformationPage />} />
            <Route path="/choice" element={<Choice />} />
            <Route path="/diagnostics-results" element={<ScreeningResults />} />
            <Route path="/diagnostics-recomendations" element={<DiagnostikRecomendations />} />
            <Route path="/funding-recomendations" element={<FundingRecomendations />} />
            <Route path="/diagnostics-overview" element={<DiagnosticOverview />} />
            <Route path="/funding-overview" element={<FundingOverview />} />
            <Route path="/promotion-test" element={<PromotionTest />} />
            <Route path="/test-results" element={<TestResults />} />
          </Routes>
        </BrowserRouter>
      </DiagnosticProvider>
    </div>
    <footer>
      <Footer />
    </footer>
  </React.StrictMode>
);

import React, { useContext, useState } from 'react';
import { DiagnosticContext } from '../../helpers/functions';
import { categories, getDiagnosticResultText, getTestTime } from '../../services/londi-constants';
import { getSuggestedTests } from '../../services/londi-suggestions';
import { DiagnosticRecomendationDetails } from './DiagnosticRecomendationDetails';

export const PrintData = ({ props }) => {
  const { state } = useContext(DiagnosticContext);
  const suggestedTests = getSuggestedTests(state);
  const [noteForTestsD, setNoteForTestsD] = useState(false);

  const selectTests = (testsA, testsB, testsC, testsD, testsE) => {
    if (testsA.length === 0 && testsB.length === 0 && testsC.length === 0 && testsD.length === 0) {
      return testsE;
    }
    if (testsA.length === 0 && testsB.length === 0 && testsC.length === 0) {
      setNoteForTestsD(true);
      return testsD;
    }
    if (testsA.length === 0 && testsB.length === 0) {
      return testsC;
    }
  };
  const selfAssessementResults = (res) => (res ? 'Ja' : 'Nein');

  return (
    <>
      <h3 className="text-md sm:text-xl font-semibold">Daten des Kindes:</h3>
      <ul className="diagnostic-overview">
        {state.schoolGrade && <li>Schulklasse: {state.schoolGrade}</li>}
        {state?.testTime && <li>Testzeitpunkt im Schuljahr: {getTestTime(state?.testTime)}</li>}
        {state?.germanSecondaryLanguage && (
          <li>Hat das Kind Deustch erst nach dem 3. Geburtstag gelernt? {state?.germanSecondaryLanguage ? 'Ja' : 'Nein'}</li>
        )}
      </ul>
      <h3 className="text-md sm:text-xl mt-6 font-semibold">{state.screenResultsEstimated ? 'Selbsteinschätzung' : 'Screeningergebnisse '}</h3>
      {state.screenResultsEstimated && <p>In den folgenden Bereichen vermuten Sie besondere Lernschwierigkeiten</p>}
      <ul className="diagnostic-overview">
        {state.screeningResultReading && (
          <li className={`${state.screenResultsEstimated && 'flex'}`}>
            <p className="font-bold mr-2">Lesen:</p>{' '}
            {state.screenResultsEstimated
              ? selfAssessementResults(state.screeningResultReading.result)
              : getDiagnosticResultText(state.screeningResultReading.result)}
          </li>
        )}
        {state.screeningResultWriting && (
          <li className={`${state.screenResultsEstimated && 'flex'}`}>
            <p className="font-bold mr-2">Schreiben:</p>{' '}
            {state.screenResultsEstimated
              ? selfAssessementResults(state.screeningResultWriting.result)
              : getDiagnosticResultText(state.screeningResultWriting.result)}
          </li>
        )}
        {state.screeningResultCounting && (
          <li className={`${state.screenResultsEstimated && 'flex'}`}>
            <p className="font-bold mr-2">Rechnen:</p>{' '}
            {state.screenResultsEstimated
              ? selfAssessementResults(state.screeningResultCounting.result)
              : getDiagnosticResultText(state.screeningResultCounting.result)}
          </li>
        )}
      </ul>
      <h3 className="text-md sm:text-xl mt-6 font-semibold">Diagnostikempfehlungen:</h3>
      <p className="my-2 text-base">
        Für eine aussagekräftige Diagnostik und optimale Nutzung des Hilfssystems empfehlen wir, dass Sie mit den durchgeführten Tests mindestens
        Informationen auf Ebene von Kompetenzstufe 1 und 3 erheben.
      </p>
      <ul className="diagnostic-overview">
        <div className="break-after-avoid-page">
          {state.screeningResultReading && <li className="font-bold mt-5">Lesen:</li>}
          {suggestedTests.map(({ category, testsA }) => {
            return (
              category === categories.Reading &&
              testsA.map((res) => {
                return (
                  <div className="border-2 border-mainBlue my-2 break-inside-avoid">
                    <div className="text-lg pl-2 pt-1 font-bold">{res.name}</div>
                    <DiagnosticRecomendationDetails res={res} />
                  </div>
                );
              })
            );
          })}
          {suggestedTests.map(({ category, testsB }) => {
            return (
              category === categories.Reading &&
              testsB.length !== 0 &&
              testsB.map((res) => {
                return (
                  <div className="border-2 border-mainBlue my-2 break-inside-avoid">
                    <div className="text-lg pl-2 pt-1 font-bold">{res.name}</div>
                    <DiagnosticRecomendationDetails res={res} />
                  </div>
                );
              })
            );
          })}
          {suggestedTests.map(({ category, testsA, testsB, testsC, testsD, testsE }) => {
            return (
              category === categories.Reading &&
              testsA === 0 &&
              testsB === 0 &&
              (testsC.length !== 0 || testsD.length !== 0 || testsE.length !== 0) &&
              selectTests(testsA, testsB, testsC, testsD, testsE).map((res) => {
                return (
                  <div className="border-2 border-mainBlue my-2 break-inside-avoid">
                    <div className="text-lg pl-2 pt-1 font-bold">{res.name}</div>
                    <DiagnosticRecomendationDetails res={res} />
                  </div>
                );
              })
            );
          })}
        </div>
        <div className="break-after-avoid-page">
          {state.screeningResultWriting && <li className="font-bold mt-5">Schreiben:</li>}
          {suggestedTests.map(({ category, testsA }) => {
            return (
              category === categories.Writing &&
              testsA.length !== 0 &&
              testsA.map((res) => {
                return (
                  <div className="border-2 border-mainBlue my-2 break-inside-avoid">
                    <div className="text-lg pl-2 pt-1 font-bold">{res.name}</div>
                    <DiagnosticRecomendationDetails res={res} />
                  </div>
                );
              })
            );
          })}
          {suggestedTests.map(({ category, testsB }) => {
            return (
              category === categories.Writing &&
              testsB.length !== 0 &&
              testsB.map((res) => {
                return (
                  <div className="border-2 border-mainBlue my-2 break-inside-avoid">
                    <div className="text-lg pl-2 pt-1 font-bold">{res.name}</div>
                    <DiagnosticRecomendationDetails res={res} />
                  </div>
                );
              })
            );
          })}
          {suggestedTests.map(({ category, testsA, testsB, testsC, testsD, testsE }) => {
            return (
              category === categories.Writing &&
              testsA === 0 &&
              testsB === 0 &&
              (testsC.length !== 0 || testsD.length !== 0 || testsE.length !== 0) &&
              selectTests(testsA, testsB, testsC, testsD, testsE).map((res) => {
                return (
                  <div className="border-2 border-mainBlue my-2 break-inside-avoid">
                    <div className="text-lg pl-2 pt-1 font-bold">{res.name}</div>
                    <DiagnosticRecomendationDetails res={res} />
                  </div>
                );
              })
            );
          })}
        </div>
        {state.screeningResultCounting && <li className="font-bold">Rechnen:</li>}
        {suggestedTests.map(({ category, testsA }) => {
          return (
            category === categories.Math &&
            testsA.length !== 0 &&
            testsA.map((res) => {
              return (
                <div className="border-2 border-mainBlue my-2 break-inside-avoid">
                  <div className="text-lg pl-2 pt-1 font-bold">{res.name}</div>
                  <DiagnosticRecomendationDetails res={res} />
                </div>
              );
            })
          );
        })}
        {suggestedTests.map(({ category, testsB }) => {
          return (
            category === categories.Math &&
            testsB.length !== 0 &&
            testsB.map((res) => {
              return (
                <div className="border-2 border-mainBlue my-2 break-inside-avoid">
                  <div className="text-lg pl-2 pt-1 font-bold">{res.name}</div>
                  <DiagnosticRecomendationDetails res={res} />
                </div>
              );
            })
          );
        })}
        {suggestedTests.map(({ category, testsA, testsB, testsC, testsD, testsE }) => {
          return (
            category === categories.Counting &&
            testsA === 0 &&
            testsB === 0 &&
            (testsC.length !== 0 || testsD.length !== 0 || testsE.length !== 0) &&
            selectTests(testsA, testsB, testsC, testsD, testsE).map((res) => {
              return (
                <div className="border-2 border-mainBlue my-2 break-inside-avoid">
                  <div className="text-lg pl-2 pt-1 font-bold">{res.name}</div>
                  <DiagnosticRecomendationDetails res={res} />
                </div>
              );
            })
          );
        })}
      </ul>
    </>
  );
};

const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <div className="flex w-full items-center p-5">
        <button>
          <img className="w-36 h-24 mx-2 lg:mx-5" alt="graphic" src="/images/LogoMakeOverFinal.png" />
        </button>
      </div>
      <div className="w-11/12 lg:w-6/12 p-5 ">
        <PrintData props={props} />
      </div>
    </div>
  );
});
export default ComponentToPrint;

import Tabs from './Tabs';
import Container from '../elements/container';
import PageHeading from '../elements/page-heading';
import Button from '../elements/button';
import { useNavigate } from 'react-router-dom';

function InformationPage() {
  const navigate = useNavigate();

  return (
    <>
      <Tabs />
      <Container className="flex flex-col items-center">
        <PageHeading title="Funktionsweise des LONDI-Hilfssystems" />
        <div className="md:px-3 py-12 sm:py-16 lg:px-4 space-y-4">
          <h6 className="text-base lg:text-lg">
            Das LONDI-Hilfssystem unterstützt Sie in vier Schritten, geeignete Diagnostik- und Förderwerkzeuge für Kinder mit Lernstörungen zu
            finden. Weitere Informationen zur Bedienung und Funktion des LONDI-Hilfssystems finden Sie{' '}
            <a
              href="https://londi.de/ueber-londi/informationen-hilfssystem/"
              target="_blank"
              className="underline hover:text-mainBlue"
              rel="noreferrer"
            >
              hier
            </a>
            .
          </h6>
          <ol className="space-y-2 text-sm lg:text-base list-decimal mx-6 text-gray-800">
            <li className="mt-8">
              Zunächst werden die Fertigkeiten des Kindes in den Bereichen Lesen, Schreiben und Rechnen eingeschätzt. Dies geschieht durch die
              LONDI Screening App.
            </li>
            <li>
              Die ermittelten Ergebnisse geben Sie auf den folgenden Seiten ein und erhalten dann auf dieses Kind zugeschnittene
              Diagnostikempfehlungen.
            </li>
            <li>Führen Sie mit dem Kind die empfohlene Diagnostik durch und geben Sie auch diese Ergebnisse ein.</li>
            <li>Sie erhalten als Ergebnis eine Auswahl zugeschnittener Förderempfehlungen für das Kind.</li>
          </ol>
        </div>
        <Button size="sm" onClick={() => navigate('/choice')} className="mb-12 lg:mb-16">
          Weiter
        </Button>
      </Container>
    </>
  );
}
export default InformationPage;

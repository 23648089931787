import React, { useEffect, useReducer } from 'react';
import { reduceReducers, initializer, DiagnosticContext } from '../../helpers/functions';
import { competenceReducer } from '../../reducers/competence';
import { screeningResultsReducer } from '../../reducers/screeningResults';

//The context is needed to provide the app state and action dispatcher to the rest of the app, as well as persist the state to sessionStorage when the state updates using an effect.
export const DiagnosticProvider = ({ children }) => {
  const combinedReducers = reduceReducers(screeningResultsReducer, competenceReducer);
  const [state, dispatch] = useReducer(combinedReducers, [], initializer);

  useEffect(() => {
    console.log('Information added to session storage', state);
    sessionStorage.setItem('kidInfo', JSON.stringify(state));
  }, [state]);

  return (
    <DiagnosticContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </DiagnosticContext.Provider>
  );
};

import classNames from 'classnames';

function Button({ children, className, size = 'lg', ...props }) {
  return (
    <button
      {...props}
      className={classNames(
        className,
        'inline-grid place-items-center rounded-sm shadow-sm bg-mainBlue text-white hover:bg-opacity-95 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mainBlue',
        {
          'min-h-[3.5rem] min-w-[10rem] text-xl': size === 'sm',
          'min-h-[3.5rem] md:min-h-[6rem] min-w-[10rem] md:min-w-[15rem] text-xl md:text-3xl': size === 'lg',
        }
      )}
    >
      {children}
    </button>
  );
}

export default Button;

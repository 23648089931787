import { InformationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

const MapTypeToIcon = {
  info: InformationCircleIcon,
  error: XCircleIcon,
};

function Alert({ children, type }) {
  const IconComponent = MapTypeToIcon[type];

  return (
    <div
      className={classNames('rounded-md p-4', {
        'bg-blue-50': type === 'info',
        'bg-red-50': type === 'error',
      })}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <IconComponent
            className={classNames('h-5 w-5', { 'text-blue-400': type === 'info', 'text-red-400': type === 'error' })}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className={classNames('text-sm', { 'text-blue-700': type === 'info', 'text-red-700': type === 'error' })}>{children}</p>
        </div>
      </div>
    </div>
  );
}

export default Alert;

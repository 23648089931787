import React from 'react';
import { PieChart as RePieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { useMediaQuery } from 'react-responsive';

function PieChart({ data, colors }) {
  const isLg = useMediaQuery({ query: `(min-width: 1024px)` });
  return (
    <ResponsiveContainer key={`${isLg}`} width="100%" height="100%" minHeight={200} className="!h-96 lg:!h-full">
      <RePieChart width={400} height={400}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          label={({ percent }) => `${(percent * 100).toFixed(3)}%`}
          outerRadius={70}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        {isLg ? <Legend align="left" verticalAlign="top" layout="vertical" /> : <Legend verticalAlign="bottom" layout="vertical" />}
      </RePieChart>
    </ResponsiveContainer>
  );
}

export default PieChart;

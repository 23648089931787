import React, { useContext } from 'react';
import { DiagnosticContext, showFundingRecommendations } from '../../helpers/functions';
import { categories, getCompetenceLevels, getEducation } from '../../services/londi-constants';
import FundingRecommendationsDetails from './FundingRecommendationsDetails';

export const PrintData = ({ props }) => {
  const { state } = useContext(DiagnosticContext);
  console.log({ state });
  return (
    <>
      <div className="flex items-center">
        <p className="text-md sm:text-xl font-semibold">Bildungsbereich:</p>
        <p className="text-xl ml-2">{getEducation(state.education)}</p>
      </div>
      <h3 className="text-md sm:text-xl mt-6 font-semibold">{state.screenResultsEstimated ? 'Selbsteinschätzung' : 'Testverfahren'}</h3>
      <>
        <ul className="diagnostic-overview">
          {(state?.readingResults || state?.readingK1Results) && (
            <li>
              Lesen:{' '}
              {!state?.screenResultsEstimated &&
                [state?.readingResults, state?.readingSecondTestResults, state?.readingThirdTestResults].filter(Boolean).join(', ')}
              {state?.screenResultsEstimated && (
                <div className="flex flex-col">
                  <p>{state.readingK1Results && `Basiskompetenzen Lesen: ${getCompetenceLevels(state?.readingK1Results?.result)}`}</p>
                  <p>{state.readingK2Results && `Erstes Lesen: ${getCompetenceLevels(state?.readingK2Results?.result)}`}</p>
                  <p>{state.readingK3Results && `Lesen: ${getCompetenceLevels(state?.readingK3Results?.result)}`}</p>
                  <p>{state.readingK4Results && `Angewandtes und flexibles Lesen: ${getCompetenceLevels(state?.readingK4Results?.result)}`}</p>
                </div>
              )}
            </li>
          )}
          {(state?.writingResults || state?.writingK1Results) && (
            <li>
              Schreiben:{' '}
              {!state?.screenResultsEstimated &&
                [state?.writingResults, state?.writingSecondTestResults, state?.writingThirdTestResults].filter(Boolean).join(', ')}
              {state?.screenResultsEstimated && (
                <div className="flex flex-col">
                  <p>{state.writingK1Results && `Basiskompetenzen Schreiben: ${getCompetenceLevels(state?.writingK1Results?.result)}`}</p>
                  <p>{state.writingK2Results && `Erstes Schreiben: ${getCompetenceLevels(state?.writingK2Results?.result)}`}</p>
                  <p>{state.writingK3Results && `Schreiben: ${getCompetenceLevels(state?.writingK3Results?.result)}`}</p>
                  <p>
                    {state.writingK4Results && `Angewandtes und flexibles Schreiben: ${getCompetenceLevels(state?.writingK4Results?.result)}`}
                  </p>
                </div>
              )}
            </li>
          )}
          {(state?.countingResults || state?.countingK1Results) && (
            <li>
              Rechnen:{' '}
              {!state?.screenResultsEstimated &&
                [state?.countingResults, state?.countingSecondTestResults, state?.countingThirdTestResults].filter(Boolean).join(', ')}
              {state?.screenResultsEstimated && (
                <div className="flex flex-col">
                  <p>{state.countingK1Results && `Basiskompetenzen Rechnen: ${getCompetenceLevels(state?.countingK1Results?.result)}`}</p>
                  <p>{state.countingK2Results && `Erstes Rechnen: ${getCompetenceLevels(state?.countingK2Results?.result)}`}</p>
                  <p>{state.countingK3Results && `Rechnen: ${getCompetenceLevels(state?.countingK3Results?.result)}`}</p>
                  <p>
                    {state.countingK4Results && `Angewandtes und flexibles Rechnen: ${getCompetenceLevels(state?.countingK4Results?.result)}`}
                  </p>
                </div>
              )}
            </li>
          )}
        </ul>

        {(state?.readingResults ||
          state?.readingK1Results ||
          state?.writingResults ||
          state?.writingK1Results ||
          state?.countingResults ||
          state?.countingK1Results) && <h3 className="text-md sm:text-xl mt-8 font-semibold">Förderempfehlungen:</h3>}

        <ul className="diagnostic-overview">
          {(state?.readingResults || state?.readingK1Results) && (
            <div className="break-after-avoid-page space-y-2">
              <li className="font-bold mt-5">Lesen:</li>
              {showFundingRecommendations(state).map((el) => {
                return (
                  el.category === categories.Reading &&
                  el.results.map((res, index) => <FundingRecommendationsDetails noPopup res={res} index={index + 1} />)
                );
              })}
            </div>
          )}
          {(state?.writingResults || state?.writingK1Results) && (
            <div className="break-after-avoid-page space-y-2">
              <li className="font-bold mt-5">Schreiben:</li>
              {showFundingRecommendations(state).map((el) => {
                return (
                  el.category === categories.Writing &&
                  el.results.map((res, index) => <FundingRecommendationsDetails noPopup res={res} index={index + 1} />)
                );
              })}
            </div>
          )}
          {(state?.countingResults || state?.countingK1Results) && (
            <div className="break-after-avoid-page space-y-2">
              <li className="font-bold mt-5">Rechnen:</li>
              {showFundingRecommendations(state).map((el) => {
                return (
                  el.category === categories.Math &&
                  el.results.map((res, index) => <FundingRecommendationsDetails noPopup res={res} index={index + 1} />)
                );
              })}
            </div>
          )}
        </ul>
      </>
    </>
  );
};

const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <div className="flex w-full items-center p-5">
        <button>
          <img className="w-36 h-24 mx-2 lg:mx-5" alt="graphic" src="/images/LogoMakeOverFinal.png" />
        </button>
        {/* <img className="w-20  h-20 mx-2 lg:mx-5" alt="graphic" src="/images/LondiKreis.png" />
        <img className="w-20  h-20 mx-2 lg:mx-5" alt="graphic" src="/images/LondiKreis.png" />
        <img className="w-20  h-20 mx-2 lg:mx-5" alt="graphic" src="/images/LondiKreis.png" />
        <img className="w-20  h-20 mx-2 lg:mx-5" alt="graphic" src="/images/LondiKreis.png" />
        <img className="w-20  h-20 mx-2 lg:mx-5" alt="graphic" src="/images/LondiKreis.png" />
        <img className="w-20  h-20 mx-2 lg:mx-5" alt="graphic" src="/images/LondiKreis.png" /> */}
      </div>
      <div className="w-11/12 lg:w-6/12 p-5 ">
        <PrintData props={props} />
      </div>
    </div>
  );
});
export default ComponentToPrint;

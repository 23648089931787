import { initializer } from '../helpers/functions';
import { categories } from '../services/londi-constants';

export const competenceReducer = (state = initializer, action) => {
  switch (action.type) {
    case 'readingK1Options':
      return { ...state, options: 'readingK1Options' };
    case 'readingK1':
      return {
        ...state,
        readingK1Results: {
          category: categories.Reading,
          result: action.payload,
        },
        options: '',
      };
    case 'readingK2Options':
      return { ...state, options: 'readingK2Options' };
    case 'readingK2':
      return {
        ...state,
        readingK2Results: {
          category: categories.Reading,
          result: action.payload,
        },
        options: '',
      };
    case 'readingK3Options':
      return { ...state, options: 'readingK3Options' };
    case 'readingK3':
      return {
        ...state,
        readingK3Results: {
          category: categories.Reading,
          result: action.payload,
        },
        options: '',
      };
    case 'readingK4Options':
      return { ...state, options: 'readingK4Options' };
    case 'readingK4':
      return {
        ...state,
        readingK4Results: {
          category: categories.Reading,
          result: action.payload,
        },
        options: '',
      };
    case 'writingK1Options':
      return { ...state, options: 'writingK1Options' };
    case 'writingK1':
      return {
        ...state,
        writingK1Results: {
          category: categories.Writing,
          result: action.payload,
        },
        options: '',
      };
    case 'writingK2Options':
      return { ...state, options: 'writingK2Options' };
    case 'writingK2':
      return {
        ...state,
        writingK2Results: {
          category: categories.Writing,
          result: action.payload,
        },
        options: '',
      };
    case 'writingK3Options':
      return { ...state, options: 'writingK3Options' };
    case 'writingK3':
      return {
        ...state,
        writingK3Results: {
          category: categories.Writing,
          result: action.payload,
        },
        options: '',
      };
    case 'writingK4Options':
      return { ...state, options: 'writingK4Options' };
    case 'writingK4':
      return {
        ...state,
        writingK4Results: {
          category: categories.Writing,
          result: action.payload,
        },
        options: '',
      };
    case 'countingK1Options':
      return { ...state, options: 'countingK1Options' };
    case 'countingK1':
      return {
        ...state,
        countingK1Results: {
          category: categories.Math,
          result: action.payload,
        },
        options: '',
      };
    case 'countingK2Options':
      return { ...state, options: 'countingK2Options' };
    case 'countingK2':
      return {
        ...state,
        countingK2Results: {
          category: categories.Math,
          result: action.payload,
        },
        options: '',
      };
    case 'countingK3Options':
      return { ...state, options: 'countingK3Options' };
    case 'countingK3':
      return {
        ...state,
        countingK3Results: {
          category: categories.Math,
          result: action.payload,
        },
        options: '',
      };
    case 'countingK4Options':
      return { ...state, options: 'countingK4Options' };
    case 'countingK4':
      return {
        ...state,
        countingK4Results: {
          category: categories.Math,
          result: action.payload,
        },
        options: '',
      };
    default:
      return state;
  }
};

import { ReactComponent as MoreIcon } from '../assets/svg/icons/plus-circle.svg';
import { ReactComponent as LessIcon } from '../assets/svg/icons/minus-circle.svg';

const Accordion = ({ open, toggle, id, label, children }) => {
  return (
    <div className="w-full space-y-3">
      <button
        type="button"
        onClick={() => toggle(id)}
        className="w-full flex flex-nowrap items-center justify-between gap-x-2 py-3 px-6 text-mainBlue border border-transparent hover:border-mainBlue bg-mainBlue/5 hover:bg-mainBlue/10 rounded-sm transition-colors"
      >
        <p className="font-semibold">{label}</p>
        {open ? <LessIcon className="w-8 h-8" /> : <MoreIcon className="w-8 h-8" />}
      </button>
      {open && children}
    </div>
  );
};

export default Accordion;

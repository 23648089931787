import { useRef } from 'react';
import Tabs from '../Tabs';
import { useReactToPrint } from 'react-to-print';
import ComponentToPrint, { PrintData } from './ComponentToPrint';

const DiagnosticOverview = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
        @page {
            margin: 20px;
        }
    }`,
    content: () => componentRef.current,
  });
  return (
    <>
      <Tabs />

      <div className="flex font-montserrat items-center flex-col justify-center m-auto">
        <h1 className="mainTitle mt-10">Diagnostikempfehlungen Übersicht</h1>
        <div className="flex flex-col w-4/5 mt-6 text-base sm:text-md lg:text-xl space-y-1">
          <p>
            Auf dieser Seite erhalten sie eine Übersicht aller Informationen die Sie in das LONDI-Hilfssystem eingegeben haben und die
            dazugehörigen Diagnostikempfehlungen. Wir empfehlen Ihnen diese auf Ihrem Computer zu speichern.
          </p>
          <p className="text-red-600 font-bold">Nach Beendigung dieser Sitzung werden alle eingegebenen Daten gelöscht.</p>
          <p>
            Sollten Sie Eingaben korrigieren wollen, dann gelangen Sie über das Menü oben zurück zu den Screeningergebnissen bzw. zu Ihren
            Einschätzungen.
          </p>
        </div>

        <button
          onClick={handlePrint}
          className="text-white font-semibold bg-mainBlue py-4 px-8 flex items-center h-10 text-lg
                md:text-2xl md:h-14 my-8"
        >
          Übersicht als PDF speichern
        </button>
        <div className={`w-11/12 h-1/2 overflow-y-auto  lg:w-6/12 p-5 border-2 border-mainBlue`}>
          <div>
            <PrintData props={false} />
          </div>
          <p className="text-md font-bold mt-2 w-full text-right">Stand der Daten: Ende 2020</p>
        </div>
        <div className="hidden">
          <ComponentToPrint props={true} ref={componentRef} />
        </div>
        <a href="/choice">
          <button
            className="text-white font-semibold bg-mainBlue py-4 px-8 flex items-center h-14 sm:h-10 text-md sm:text-lg
                 md:text-2xl md:h-14 my-8"
          >
            Förderempfehlungen oder Diagnostik neu starten
          </button>
        </a>
      </div>
    </>
  );
};

export default DiagnosticOverview;
